import React, { useState } from 'react';
import logo from '../assets/AcadSpaceLogo.png';
import userIcon from '../assets/username.png';
import pinIcon from '../assets/key.png';
import firstnameIcon from '../assets/sign_first_name.png';
import lastnameIcon from '../assets/sign_last_name.png';
import bg_book_now from '../assets/bg_book_now.png';
import phone_icon from '../assets/phone_icon.png';
import bg_book_now2 from '../assets/bg_book_now2.png';
import { Link } from 'react-router-dom';

const Booking = () => {
    const [isStudent, setIsStudent] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [isTnc, setIsTnc] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        username: '',
        pin: '',
        type: 'School',
        schoolname: 'AcadSpaceAI'
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleLogin = () => {
        setIsLogin(!isLogin);
    };

    const handleExplore = () => {
        window.location.href = 'https://acadspace.org';
    };

    const handleCheckboxChange = () => {
        setIsStudent(!isStudent);
    };

    const handleTncCheckboxChange = () => {
        setIsTnc(!isTnc);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = 'Full name is required';
        }
        // if (!formData.lastname) {
        //     newErrors.lastname = 'Last Name is required';
        // }
        if (!formData.email) {
            newErrors.email = 'Email is required';
        }
        if (!formData.mobileNo) {
            newErrors.mobileNo = 'Mobile is required';
        } else if (formData.mobileNo.length !== 10) {
            newErrors.mobileNo = 'mobile must be 10 digits';
        }
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        setLoading(true);

        fetch('http://testenv.acadspace.org/api/auth/booknow', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setIsLogin(false);
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                console.error('Error:', error);
            });
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-[100vh] bg-[#e6e6e6]">
                <img src={'https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Floading_book_now.gif?alt=media&token=13c3a4aa-846c-4fd6-bad7-f213fec2436e'}
                    alt="Loading..."
                    className="h-36 md:h-48" />
            </div>
        );
    }

    return (
        <div className="relative flex items-center h-screen max-h-screen select-none overflow-clip">

            <div className={`absolute z-10 flex flex-col-reverse lg:flex-row  ${isLogin ? '' : 'md:flex-row-reverse'} items-center md:justify-start w-full min-h-screen`}>

                <div className='flex w-full max-w-lg items-center justify-center h-[65vh] md:h-screen bg-[#FFFFFF]'>
                    <div className="flex-col max-w-md p-8 pb-16 md:pb-16 rounded-xl">
                        <div className='flex items-center justify-center'>
                            <Link to="/">
                                <img src={logo} alt="AcadSpace Logo" className="object-cover h-32 w-80" />
                            </Link>
                        </div>
                        {isLogin && (
                            <form className='font-space-grotesk' onSubmit={handleSubmit}>
                                <div className="relative mb-4 font-semibold">
                                    <img src={firstnameIcon} alt="User Icon" className="absolute w-6 h-6 p-[2px] top-[9px] left-3 mr-1" />
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        className="w-full p-2 pl-10 mt-1 text-sm border rounded-lg"
                                        placeholder="Full Name"
                                    />
                                    {errors.fullname && <span className="text-sm text-red-500">{errors.fullname}</span>}
                                </div>
                                {/* <div className="relative mb-4 font-semibold">
                                    <img src={lastnameIcon} alt="User Icon" className="absolute w-6 h-6 p-[2px] top-[9px] left-3 mr-1" />
                                    <input
                                        type="text"
                                        id="lastname"
                                        name="lastname"
                                        value={formData.lastname}
                                        onChange={handleInputChange}
                                        className="w-full p-2 pl-10 mt-1 text-sm border rounded-lg"
                                        placeholder="Last Name"
                                    />
                                    {errors.lastname && <span className="text-sm text-red-500">{errors.lastname}</span>}
                                </div> */}
                                <div className="relative mb-4 font-semibold">
                                    <img src={userIcon} alt="User Icon" className="absolute w-6 h-6 p-[2px] top-[9px] left-3 mr-1" />
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="w-full p-2 pl-10 mt-1 text-sm border rounded-lg"
                                        placeholder="Email"
                                    />
                                    {errors.email && <span className="text-sm text-red-500">{errors.email}</span>}
                                </div>
                                <div className="relative mb-6 font-semibold">
                                    <img src={phone_icon} alt="Pin Icon" className="absolute w-6 h-6 p-[2px] top-[9px] left-3 mr-1" />
                                    <input
                                        type="number"
                                        id="mobileNo"
                                        name="mobileNo"
                                        value={formData.mobileNo}
                                        onChange={handleInputChange}
                                        className="w-full p-2 pl-10 mt-1 text-sm border rounded-lg"
                                        placeholder="Mobile"
                                    />
                                    {errors.mobile && <span className="text-sm text-red-500">{errors.mobile}</span>}
                                </div>

                                <div className='relative flex items-center justify-center -top-1'>
                                    <button
                                        type="submit"
                                        className='relative w-full text-center rounded-md md:rounded-xl text-sm shadow-sm bg-[#FAFF01] md:text-xl px-6 md:px-8 py-2 md:py-2 font-goldman text-[#1D1050] cursor-pointer z-10'
                                    >
                                        {isLogin ? "Book Now !" : "Explore More"}
                                    </button>
                                    <div className='absolute w-full h-full rounded-md md:rounded-xl top-1 bg-[#FAFF01] shadow-custom-login-1'></div>
                                </div>
                            </form>
                        )}

                        {!isLogin && (
                            <div className='pt-8 pb-16 text-xl text-center text-black font-space-grotesk'>
                                Thank you for Booking your Slot, Explore more about us by visiting below
                            </div>
                        )}

                        {!isLogin && (
                            <div className='relative flex items-center justify-center -top-1'>
                                <div
                                    onClick={handleExplore}
                                    className='relative w-full text-center rounded-md md:rounded-xl text-sm shadow-lg bg-[#FAFF01] md:text-xl px-6 md:px-8 py-2 md:py-2 font-goldman text-[#1D1050] cursor-pointer z-10'
                                >
                                    Explore More
                                </div>
                                <div className='absolute w-full h-full rounded-md md:rounded-xl top-1 bg-[#FAFF01] shadow-custom-login-1'></div>
                            </div>
                        )}

                    </div>
                </div>

            </div>

            <div className={`relative hidden md:flex items-end ${isLogin ? 'justify-end' : 'justify-start'} w-full min-h-screen`}>
                <img
                    src={isLogin ? bg_book_now : bg_book_now2}
                    alt="book cover"
                    className="object-cover object-right h-screen"
                />
            </div>

            <div className={`relative md:hidden flex items-start ${isLogin ? 'justify-end' : 'justify-start'} w-full min-h-screen`}>
                <img
                    src={isLogin ? bg_book_now : bg_book_now2}
                    alt="book cover"
                    className="object-fill object-right h-[35vh] w-full"
                />
            </div>
        </div>
    );
};

export default Booking;
