import React, { useState } from 'react';
import contact_phone from '../assets/contact_phone-call.png';
import contact_mail from '../assets/contact_sharp-email.png';

const ContactUs = () => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        subject: [],
        message: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prevFormData) => {
                if (checked) {
                    return { ...prevFormData, [name]: [...prevFormData[name], value] };
                } else {
                    return { ...prevFormData, [name]: prevFormData[name].filter((subject) => subject !== value) };
                }
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log('Form data submitted:', formData);
    };

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] space-y-8 md:space-y-16 transform-gpu select-none max-w-full overflow-clip pb-16'>

            <div className='flex-col items-center justify-center py-4 text-center md:py-8 sm:mx-auto'>

                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/10 to-[#2D1E6B]/10 text-3xl sm:text-6xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]"> Contact Us </div>

                <div className='py-5 text-sm text-white font-space-grotesk md:text-xl'> Any question or remarks? Just write us a message! </div>

                <div className='px-8'>
                    <div className='flex flex-col p-4 bg-white rounded-md md:flex-row'>

                        <div className='bg-[#DEE279] rounded-l-md pb-24 text-left space-y-4 p-4 md:p-8 font-space-grotesk font-semibold'>
                            
                            <div className='mb-4 text-lg font-bold md:text-xl'>Contact Information</div>

                            <div className='flex font-light gap-x-3 text-sm font-space-grotesk text-[#1D104F]'>
                                <img src={contact_phone} alt="Phone" className="w-6 h-6" /> +91 9967788463
                            </div>
                            <div className='flex font-light text-sm gap-x-3 font-space-grotesk text-[#1D104F]'>
                                <img src={contact_mail} alt="Email" className="w-6 h-6" /> support@acadspace.org
                            </div>
                            <div className='flex font-light text-sm gap-x-3 font-space-grotesk text-[#1D104F]'>
                                <img src={contact_phone} alt="Address" className="w-6 h-6" /> Kormangala, Near Forum Mall, 560010
                            </div>
                        </div>

                        <form className="w-full max-w-lg p-4 mt-4 space-y-6 md:max-w-full font-space-grotesk" onSubmit={handleSubmit}>
                            <div className='flex flex-col w-full md:space-x-8 md:flex-row'>
                                <div className="w-full mb-4">
                                    <label className="block mb-2 text-sm font-semibold text-left text-gray-700" htmlFor="firstName">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        className="w-full p-3 border-b border-[#8D8D8D]"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="w-full mb-4">
                                    <label className="block mb-2 text-sm font-semibold text-left text-gray-700" htmlFor="lastName">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        className="w-full p-3 border-b border-[#8D8D8D]"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            
                            <div className='flex flex-col w-full md:space-x-8 md:flex-row'>
                                <div className="w-full mb-4">
                                    <label className="block mb-2 text-sm font-semibold text-left text-gray-700" htmlFor="email">
                                        Email ID
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="w-full p-3 border-b border-[#8D8D8D]"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="w-full mb-4">
                                    <label className="block mb-2 text-sm font-semibold text-left text-gray-700" htmlFor="phone">
                                        Phone Number
                                    </label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        className="w-full p-3 border-b border-[#8D8D8D]"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            
                            <div className="my-4">
                                <label className="block my-2 text-base font-semibold text-left text-black">
                                    Select Subject?
                                </label>
                                <div className="flex flex-wrap gap-4 mt-4 text-xs">
                                    <div>
                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                name="subject"
                                                value="general"
                                                className="form-checkbox"
                                                checked={formData.subject.includes('general')}
                                                onChange={handleChange}
                                            />
                                            <span className="ml-2">General Inquiry</span>
                                        </label>
                                    </div>
                                    <div>
                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                name="subject"
                                                value="careers"
                                                className="form-checkbox"
                                                checked={formData.subject.includes('careers')}
                                                onChange={handleChange}
                                            />
                                            <span className="ml-2">About Careers</span>
                                        </label>
                                    </div>
                                    <div>
                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                name="subject"
                                                value="webinars"
                                                className="form-checkbox"
                                                checked={formData.subject.includes('webinars')}
                                                onChange={handleChange}
                                            />
                                            <span className="ml-2">About Webinars</span>
                                        </label>
                                    </div>
                                    <div>
                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                name="subject"
                                                value="courses"
                                                className="form-checkbox"
                                                checked={formData.subject.includes('courses')}
                                                onChange={handleChange}
                                            />
                                            <span className="ml-2">About Courses</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2 text-sm font-semibold text-left text-gray-700" htmlFor="message">
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    className="w-full p-3 border-b border-[#8D8D8D] text-xs"
                                    rows="1"
                                    placeholder="Write your message.."
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                            </div>
                            <div className="flex items-center justify-center">
                                <div className='relative'>
                                    <button
                                        type="submit"
                                        className="relative z-10 px-6 py-2 w-full font-semibold text-[#1D104F] text-xl shadow-button-1 bg-[#FAFF01] rounded-lg hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                                    >
                                        Send Message
                                    </button>
                                    <div className='absolute top-[2px] w-full h-full rounded-lg shadow-button-1 bg-[#FAFF01]'></div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default ContactUs;
