import { useEffect } from "react";
import blog_profile from '../../assets/blog/blog_user.png';
import blog_linked from '../../assets/blog-linkedin.png';
import neet_exam_banner from '../../assets/blog/Neet for Healthcare Students Banner (1).jpeg';
import neet_exam_body from '../../assets/blog/neet-syllabus body 2 (1).jpg';
import { useNavigate } from "react-router-dom";
import internship_acadspace from '../../assets/blog/internship_acadspace.png';
import TimeManagement12thexam from '../../assets/blog/Time-management Banner Surbi (1).jpg';
import ssc_chsl_exam from '../../assets/blog/SSC exam Banner (1).png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NEETBlog = () => {

    useEffect(() => {
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // className: "center",
        // centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const navigate = useNavigate();

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] space-y-8 md:space-y-16 transform-gpu max-w-full overflow-clip '>

            <div className='flex-col items-center justify-center py-4 text-center md:py-8 sm:mx-auto'>

                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 py-4 mb-8 md:py-8 md:mb-16 text-xl md:text-2xl sm:text-4xl font-bold leading-snug font-goldman text-[#FAFF01]"> NEET: Medical Exam for Students </div>

                <div className="flex flex-col md:flex-row md:px-16 gap-x-16">

                    <div className="flex-col px-8 space-y-8 text-center md:space-y-12 md:w-2/3 md:px-0">

                        <div className="flex items-center justify-center w-full">
                            <div className="relative flex items-center justify-center w-full rounded-xl">
                                <div className="relative z-10 w-full border-2 border-white rounded-xl overflow-clip">
                                    <img src={neet_exam_banner}
                                        alt="" className="w-full" />
                                    <div className="absolute "></div>
                                </div>
                                <div className="absolute w-full h-full border-2 rounded-xl top-2 -right-2 md:-top-3 md:-right-3"></div>
                                <div className="absolute bottom-0 z-20 w-full overflow-clip">
                                    <div className="w-full h-full bg-[#000]/50 border-b-2 border-l-2 space-y-1 border-r-2 rounded-b-xl backdrop-blur-md overflow-clip p-3">
                                        <div className="bg-[#F1ECFF] w-fit flex items-center jus text-[#6C40FF] text-sm rounded-xl py-1 px-2 font-space-grotesk">
                                            <div className="w-3 h-3 bg-[#6C40FF] rounded-full text-xs mr-1"></div>Competitive Exams
                                        </div>
                                        <div className="mt-1 text-xs font-semibold text-left text-white md:text-base font-space-grotesk">NEET: Medical Exam for Students
                                        </div>
                                        <div className="text-sm text-left text-white font-space-grotesk">Aug 6 . 12 min read</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Introduction
                            </div>
                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                The NEET (National Eligibility cum Entrance Test) is an Indian nationwide entrance examination conducted by the National Testing Agency (NTA) for admission to undergraduate medical programs. It is mandatory for all students who want to pursue medical programs in India. NEET is known as the largest exam in terms of the number of applicants. It serves as the entrance examination for undergraduate medical courses (MBBS), dental courses (BDS), and other related fields in both government and private institutions in India.
                            </p>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Background
                            </div>
                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                Before 2012, the All India Pre-medical Test (AIPMT) was conducted by the Central Board Of Secondary Education (CBSE). In 2013, NEET-UG was introduced by CBSE, replacing AIPMT. Due to legal challenges, NEET was temporarily replaced by AIPMT in 2014 and 2015. In 2016, NEET was reintroduced and conducted by CBSE. Since 2019, the National Testing Agency (NTA) has been responsible for conducting the NEET exam. The NMC Act 2019 made NEET-UG the sole entrance test for admissions to medical colleges in India, including AIIMS and JIPMER, which previously conducted separate exams.
                            </p>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Eligibility Criteria
                            </div>
                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                <span className="text-[#E4E84E]">Nationality:</span> Indian Nationals, NRIs, Overseas Citizen of India (OCI), Persons with Indian Origin (PIO), and Foreign Nationals are eligible.<br />
                                <span className="text-[#E4E84E]">Age Limit:</span> Candidates must be at least 17 years old at the time of admission. The upper age limit is 25 years for general category candidates and 30 years for reserved categories.<br />
                                <span className="text-[#E4E84E]">Educational Qualification:</span> Candidates must have passed 10+2 or equivalent with Physics, Chemistry, Biology/Biotechnology, and English as core subjects, securing a minimum aggregate of 50% marks (40% for reserved categories).
                            </p>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Exam Pattern
                            </div>
                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                <span className="text-[#E4E84E]">Mode of Examination:</span> Pen and paper-based (offline).<br />
                                <span className="text-[#E4E84E]">Duration:</span> 3 hours.<br />
                                <span className="text-[#E4E84E]">Subjects:</span> Physics, Chemistry, and Biology (Botany & Zoology).<br />
                                <span className="text-[#E4E84E]">Number of Questions:</span> 180 multiple-choice questions.<br />
                                <span className="text-[#E4E84E]">Marking Scheme:</span> Each correct answer: +4 marks. Each incorrect answer: -1 mark. Unanswered questions: No marks.<br />
                                <span className="text-[#E4E84E]">Physics:</span> 45 questions.<br />
                                <span className="text-[#E4E84E]">Chemistry:</span> 45 questions.<br />
                                <span className="text-[#E4E84E]">Biology:</span> 90 questions (Botany and Zoology).
                            </p>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Syllabus
                            </div>
                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                The NEET syllabus is based on the NCERT curriculum for Classes 11 and 12. It covers topics from Physics, Chemistry, and Biology.
                            </p>
                        </div>

                        <div className="">
                            <img src={neet_exam_body} alt="" className="w-full" />
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Application Process
                            </div>
                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                <span className="text-[#E4E84E]">Online Application:</span> Candidates need to apply online through the official NTA NEET website.<br />
                                <span className="text-[#E4E84E]">Application Fee:</span> The fee varies by category and can be paid online.
                            </p>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Preparation Tips
                            </div>
                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                <span className="text-[#E4E84E]">Understand the Syllabus:</span> Thoroughly review the NEET syllabus and exam pattern.<br />
                                <span className="text-[#E4E84E]">Create a Study Schedule:</span> Allocate time for each subject and stick to the plan.<br />
                                <span className="text-[#E4E84E]">Practice with Mock Tests:</span> Regularly take mock tests and solve previous years' question papers.<br />
                                <span className="text-[#E4E84E]">Focus on Weak Areas:</span> Identify and work on areas where you need improvement.<br />
                                <span className="text-[#E4E84E]">Stay Healthy:</span> Maintain a balanced diet and get adequate sleep.
                            </p>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Future Trends
                            </div>
                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                With the continuous evolution of the Indian education system and advancements in technology, the NEET exam is expected to see various changes in the future. This includes potential digitalization, changes in the syllabus, and the incorporation of more advanced testing methods to better assess students' understanding and capabilities.
                            </p>
                        </div>

                    </div>

                    <div className="flex-col w-full border-t-2 rounded-md border-t-[#1D1D52] md:w-1/3 md:mt-0 md:space-y-8 bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] md:border-0">

                        <div className="w-full max-w-md shrink-0">
                            <div className="w-full bg-[#2D1E6B] p-4 md:p-8 rounded-xl border-white border-[1px]">
                                <div className="space-y-2">
                                    <div className="flex items-end justify-start overflow-clip gap-x-4">
                                        <img src={blog_profile} alt="" className="w-16 rounded-lg md:w-32" />
                                        <img src={blog_linked} alt="" className="w-6 h-6 rounded-lg md:h-8 md:w-8" />
                                    </div>
                                    <div className="text-base font-semibold text-left text-white md:text-xl font-space-grotesk"> SUDHEER SHARMA </div>
                                    <div className="w-full h-[1px] bg-white"></div>
                                    <div className="text-sm text-left text-white font-space-grotesk">
                                        dedicated student with a master’s degree in economics and is currently pursuing an MBA online through Amity University.
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <div className="flex flex-col mt-8 space-y-2 text-left text-white md:space-y-4">
                            <div className="text-xl font-semibold md:text-2xl">Related Blogs</div>
                            <div className="flex flex-col space-y-2">
                                <a href="/ssc-cgl-exam" className="text-base font-light md:text-lg">SSC CGL: Comprehensive Guide</a>
                                <a href="/jee-main-exam" className="text-base font-light md:text-lg">JEE Main: Everything You Need to Know</a>
                                <a href="/upsc-exam" className="text-base font-light md:text-lg">UPSC: Tips for Success</a>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>

            <div className="flex-col w-full">

                <div className="text-3xl md:text-4xl text-[#FAFF01] mb-4 w-full font-semibold font-goldman text-center">
                    Recommended Blogs
                </div>

                <div className='relative flex items-center justify-center px-8 space-x-12 overflow-scroll scrollbar-hide h-[500px]'>

                    <div className="absolute top-0 w-[470px] md:w-[1340px] h-full">

                        <Slider {...settings} className="px-4">

                            <div key={1} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 rounded-xl w-80 h-96'>
                                <img src={'https://acadspace.org/img/updates/MyCoolQ.png'} alt="blog" className="object-contain object-center rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    First Large Scale Partnership in East India
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    AcadSpace inaugurates Personality Coaching & Career Counseling for 1600+ proud parents. We are motivated by such a positive turn-up & feedback…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/partner-acadspace')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={2} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 rounded-xl w-80 h-96'>
                                <img src={internship_acadspace} alt="blog" className="object-contain object-center rounded-xl" />
                                <div className='font-bold text-left text-white '>
                                    <div className="bg-[#F1ECFF] w-fit flex items-center justify-center text-[#6C40FF] text-sm font-normal rounded-xl py-1 px-2 mt-2 font-space-grotesk">
                                        <div className="w-3 h-3 bg-[#6C40FF] rounded-full font-light text-[8px] mr-1"></div>Internships
                                    </div>
                                    <div className="flex items-center justify-between mt-2">
                                        <div className="flex font-medium text-[#97989F] text-base">
                                            <img src={blog_profile} alt="blog" className="w-4 mr-1 rounded-lg md:w-6" /> Manish Shivangi
                                        </div>
                                        <div className="flex font-medium text-[#97989F] text-sm">
                                            July 31st 2024
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        Unlocking Career Success: The Crucial Role of Internships in Career Development
                                    </div>
                                </div>
                                <div className='flex items-center justify-end w-full -mt-2 cursor-pointer' onClick={() => navigate('/blog/internship')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={3} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                                <img src={ssc_chsl_exam} alt="blog" className="object-contain object-center rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    What is the SSC CGL Exam? Everything You Need to Know
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    The SSC CGL (Staff Selection Commission Combined Higher Secondary Level) exam is a crucial opportunity for those looking to secure government jobs…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/ssc-cgl')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={4} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                                <img src={TimeManagement12thexam} alt="blog" className="object-cover object-center min-h-48 rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    How To Manage Time During Class 12th Exams?
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    Learn how to do time management for Class 12th exams with tips on creating a study plan, balancing study and rest, and reducing stress…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/12th')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={5} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                                <img src={neet_exam_banner} alt="blog" className="object-cover object-center min-h-48 rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    NEET: Medical Exam for Students
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    The NEET (National Eligibility cum Entrance Test) is an Indian nationwide entrance examination conducted by the National Testing Agency (NTA) for admission to undergraduate medical programs…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/neet')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                        </Slider>

                    </div>

                </div>


            </div>

        </div>
    )
}

export default NEETBlog;
