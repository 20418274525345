import React, { useEffect, useRef, useState } from 'react';
import student from '../assets/student.png';
import Bhaskaracharya from '../assets/institutes/Bhaskaracharya.svg';
import Daulatram from '../assets/institutes/daulatram.svg';
import CMRU_logo from '../assets/institutes/CMRU_logo 2.svg';
import Dypatil from '../assets/institutes/dypatil.svg';
import CVMULogo2 from '../assets/institutes/CVMULogo2.svg';
import G38 from '../assets/institutes/g38.svg';
import IndianInstituteOfSpaceScienceAndTechnologyLogo from '../assets/institutes/Indian_Institute_of_Space_Science_and_Technology_Logo 2.svg';
import Gitam from '../assets/institutes/gitam.svg';
import NVP_logo from '../assets/institutes/NVP_logo 2.svg';
import Greenwood from '../assets/institutes/greenwood.svg';
import Uvce_logo from '../assets/institutes/Uvce_logo (2) 2.svg';
import Kasiga from '../assets/institutes/kasiga.svg';
import Accurate from '../assets/institutes/accurate.svg';
import Pictures_1_logo from '../assets/institutes/pictures_1_logo 2.svg';
import Anna from '../assets/institutes/anna.svg';
import Podar from '../assets/institutes/podar.svg';
import Rustamjee from '../assets/institutes/rustamjee.svg';
import Ascent from '../assets/institutes/ascent.svg';
import army from '../assets/institutes/army-public-school-logo 2.svg';
import Saptagirir from '../assets/institutes/saptagirir.svg';
// import star from '../assets/starIcon.png';
import avatars from '../assets/avatars.png';
import trophy from '../assets/trophy.png';
import sample1 from '../assets/samples/sample1.png';
import arrow from '../assets/arrow.png';
import student2 from '../assets/student2.png';
import student3 from '../assets/student3.png';
import student4 from '../assets/student4.png';
import star from '../assets/star.png';
import send from '../assets/Send.png';
import live from '../assets/live.png';
import { useLocation, useNavigate } from 'react-router-dom';
import delhi from '../assets/delhi-university.png';
import mumbai from '../assets/mumbai-university.png';
import amity from '../assets/amity.png';
import jgi from '../assets/jgi.png';
import graphics from '../assets/pen-nib-duotone 1.png';
import software from '../assets/megaphone-simple-duotone 1.png';
import digital from '../assets/code-duotone 1.png';
import bgmath from '../assets/bg-hero-works.png';
import hero2 from '../assets/bg-hero-4.png';
import hero3 from '../assets/bg-hero-3.png';
import works1 from '../assets/works-1.png';
import works2 from '../assets/works-2.png';
import works3 from '../assets/works-3.png';
import bgarrow from '../assets/bg-arrow.png';
import bgstar from '../assets/svg/star.svg';
import student6 from '../assets/student6.png';
import student7 from '../assets/student7.png';
import student8 from '../assets/student8.png';
import student9 from '../assets/student9.png';
import student10 from '../assets/student10.png';
import student11 from '../assets/student11.png';
import testinomial2 from '../assets/testinomial2.png';
import career_library from '../assets/login-bg-2.png';
import discover from '../assets/discover.png';
import ai from '../assets/ai.png';
import mentorImage from '../assets/samples/webinar_sample1.png';
import course_ui_ux from '../assets/course_ui-ux.png';
import course_product_management from '../assets/course_product_management.png';
import internship_acadspace from '../assets/blog/internship_acadspace.png';
import courses_fsd from '../assets/courses_fsd.png';
import courses_dataScience from '../assets/courses_dataScience.png';
import courses_digital_marketing from '../assets/courses_digital_marketing.png';
import blog_profile from '../assets/blog/blog_user.png';
import ssc_chsl_exam from '../assets/blog/SSC exam Banner (1).png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TimeManagement12thexam from '../assets/blog/Time-management Banner Surbi (1).jpg';
import neet_exam_banner from '../assets/blog/Neet for Healthcare Students Banner (1).jpeg';
import pin from '../assets/testinomial/Group 12 (1).png';
import pin2 from '../assets/testinomial/Vector 1.png';
import StartSlider from '../components/WhereToStart';

const Home = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const suggestions = [
    "Will pursuing Data Science guarantee me a job?",
    "How to prepare for a career in AI?",
    "Which universities are best for computer science?",
    "How to write a strong resume?",
    "What are the career prospects in digital marketing?",
    "How to balance work and studies?"
  ];
  const studentImages = [student, student2, student3, student4, student6, student7, student8, student9, student10, student11];
  const location = useLocation();
  const sliderRef = useRef(null);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const shuffleArray = (array) => {
    let uniqueArray = [...new Set(array)];
    let shuffledArray = uniqueArray.slice();

    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }

    if (shuffledArray.length < 3) {
      throw new Error("Not enough unique elements to return 3 items.");
    }

    return shuffledArray.slice(0, 3);
  };
  const shuffledImages = shuffleArray(studentImages);
  const navigate = useNavigate();

  const handleSuggestionClick = (suggestion) => {
    console.log('clicked : ', suggestion);
    setInputValue(suggestion);
    setIsFocused(false);
  };

  const useIntersectionObserver = (ref, options) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }, options);

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref, options]);

    return isIntersecting;
  };

  const handleGetStarted = () => {
    navigate('/login');
  };

  const ref = useRef();
  const isVisible = useIntersectionObserver(ref, {
    threshold: 0.1,
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // className: "center",
    // centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };

  const testinomial_settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    className: "center",
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024, // For screens less than or equal to 1024px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable center mode on smaller screens
        },
      },
      {
        breakpoint: 768, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable center mode on smaller screens
        },
      },
      {
        breakpoint: 480, // For screens less than or equal to 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable center mode on smaller screens
        },
      },
    ],
  };

  // Handlers for mouse enter and leave
  const handleMouseEnter = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause(); // Pause the slider
    }
  };

  const handleMouseLeave = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay(); // Resume the slider
    }
  };

  const slides = [
    {
      image: "https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fassets%2FCareer-Oppor-1-370x245%205.png?alt=media&token=5397b93e-0843-4e4d-971c-ab9e832e8a3f",
      description: "Read about different Career Options"
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fassets%2FCareer-Oppor-1-370x245%205.png?alt=media&token=5397b93e-0843-4e4d-971c-ab9e832e8a3f",
      description: "Watch and Learn from Experts"
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fassets%2FCareer-Oppor-1-370x245%205.png?alt=media&token=5397b93e-0843-4e4d-971c-ab9e832e8a3f",
      description: "Description for Slide 3"
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fassets%2FCareer-Oppor-1-370x245%205.png?alt=media&token=5397b93e-0843-4e4d-971c-ab9e832e8a3f",
      description: "Description for Slide 4"
    }
  ];


  const start_sliderRef = React.useRef(null);

  const start_settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const handleNext = () => {
    start_sliderRef.current.slickNext();
  };

  const handleSkip = () => {
    start_sliderRef.current.slickGoTo(slides.length - 1);
  };

  useEffect(() => {
  }, []);

  return (
    <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] space-y-6 transform-gpu select-none max-w-full overflow-clip'>

      {/* Hero Section */}
      <div className='flex items-center justify-center'>
        <div className='relative md:container h-[350px] md:h-[570px] mx-auto'>
          <div className="relative z-10 flex items-center justify-center h-full px-4">
            <div className=''>
              <div className='relative '>
                <div className='relative z-10 text-center'>
                  <div className='relative flex items-center justify-center'>
                    <h1 className="box-content absolute z-10 mx-auto mb-4 text-base font-bold leading-snug text-center text-white md:leading-normal md:text-6xl font-goldman ">
                      Get all your <span className='text-[#FAFF01] md:shadow-white'>Career</span> Questions Answered <br />
                      with <span className='text-[#FAFF01] md:shadow-white'>AcadSpace AI</span>
                    </h1>
                    <h1 className="box-content mb-4 mx-auto text-base font-bold leading-snug md:leading-normal text-white md:shadow-xl bg-gradient-to-r blur-sm md:blur-xl from-[#FFFFFF] via-[#FFFFFF] to-white bg-clip-text md:text-6xl font-goldman ">
                      Get all your <span className='text-[#FAFF01] md:shadow-white'>Career</span> Questions Answered <br />
                      with <span className='text-[#FAFF01] md:shadow-white'>AcadSpace AI</span>
                    </h1>
                    <h1 className="box-content absolute top-0 mb-4 mx-auto text-base font-bold leading-snug md:leading-normal text-white md:shadow-xl bg-gradient-to-r md:blur-xl from-[#FFFFFF] via-[#FFFFFF] to-white bg-clip-text md:text-6xl font-goldman ">
                      Get all your <span className='text-[#FAFF01] md:shadow-white'>Career</span> Questions Answered <br />
                      with <span className='text-[#FAFF01] md:shadow-white'>AcadSpace AI</span>
                    </h1>
                  </div>
                  <p className="mb-8 text-xs text-white md:text-2xl font-overlock-sc">
                    Answers tailored to your needs, curated by top 1% professional’s across the Country
                  </p>
                </div>
                {/* <div className='absolute w-full h-1 px-12 bg-gradient-to-r from-[#fff]/10 via-[#fff]/20 to-white/10 top-1/2 shadow-hero-multi'></div> */}
              </div>
              <div className='relative'>
                <div className='relative z-10 flex items-center justify-center'>
                  <div className='flex justify-between w-full px-2 py-1 bg-white rounded-lg max-w-[90%] sm:max-w-7xl pb-2'>
                    <input
                      placeholder='Have a Question?'
                      className='w-full px-2 mr-2 overflow-x-auto text-xs md:text-base placeholder-black/60 font-space-mono whitespace-nowrap'
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <div className='relative'>
                      <div onClick={() => handleGetStarted()} className='bg-[#FAFF01] px-4 md:px-8 py-1 md:py-2 shadow-box-shadow-black rounded-md md:rounded-xl cursor-pointer flex justify-center items-center relative z-10'>
                        <img src={send} alt="AcadSpace Logo" className="h-3 md:h-6" />
                        <div className='ml-1 md:ml-2 text-sm text-[#2D1E6B] md:text-xl font-goldman'>Send</div>
                      </div>
                      <div className='absolute w-full bg-[#FAFF01] shadow-box-shadow-black top-[2px] h-full rounded-xl'></div>
                    </div>
                  </div>
                </div>
                <div
                  id='suggestions'
                  className={`absolute flex items-center justify-center top-5 right-0 rounded-lg border-[1px] bg-[#FAFF01]/5 border-white w-full h-44 lg:h-56 overflow-scroll md:h-fit pt-8 md:pt-12 lg:pb-12 transform transition-all duration-500 ease-in-out ${isFocused ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}
                >
                  <div className='relative w-full h-full'>
                    <div className='grid grid-cols-1 gap-4 px-4 pb-4 md:grid-cols-3 md:gap-y-6 md:pt-4'>
                      {suggestions.map((suggestion, index) => (
                        <div key={index} className='relative'>
                          <div
                            className='bg-[#372972] z-10 relative text-xs md:text-sm text-white cursor-pointer p-2 md:p-4 rounded-lg border-[1px] border-white hover:translate-y-1 md:hover:translate-y-2 hover:-translate-x-1 md:hover:-translate-x-2 transition duration-500 ease-in-out'
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion}
                          </div>
                          <div className='absolute top-1 md:top-2 right-1 md:right-2 w-full rounded-lg h-full bg-gradient-to-r from-[#FAFF01]/10 to-[#FFFFFF]/1 border-[1px] border-white'></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className='absolute top-0 flex items-center justify-center w-full h-full px-8 md:px-0 md:top-0'>
            <div className='flex space-x-3'>
              {/* First 2 columns */}
              {/* {Array.from({ length: 2 }, (_, i) => ( */}
              <div className='flex-col w-full h-full space-y-3'>
                <img src={student2} alt={'students'} className="" />
                <img src={student3} alt={'students'} className="" />
                <img src={student4} alt={'students'} className="" />
              </div>
              <div className='flex-col w-full h-full space-y-3'>
                <img src={student11} alt={'students'} className="" />
                <img src={student7} alt={'students'} className="" />
                <img src={student8} alt={'students'} className="" />
              </div>

              {/* Next 6 columns for larger screens */}
              {/* {Array.from({ length: 6 }, (_, i) => (
                            <div className='flex-col hidden w-full space-y-3 md:block' key={i}>
                                {shuffleArray(studentImages).map((src, index) => (
                                    <React.Fragment className="flex-col" key={index}>
                                        <img src={src} alt={`student ${index + 1}`} key={index} className={` ${index % 2 !== 0 ? 'opacity-0' : ''} `} />
                                    </React.Fragment>
                                ))}
                            </div>
                        ))} */}


              <div className='flex-col hidden w-full space-y-3 md:block'>
                <React.Fragment className="flex-col" key={0}>
                  <img src={student2} alt={`student ${0 + 1}`} key={1} className={` ${0 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
                <React.Fragment className="flex-col" key={0}>
                  <img src={student8} alt={`student ${0 + 1}`} key={1} className={` ${1 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
                <React.Fragment className="flex-col" key={0}>
                  <img src={student3} alt={`student ${0 + 1}`} key={1} className={` ${0 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
              </div>

              <div className='flex-col hidden w-full space-y-3 md:block'>
                <React.Fragment className="flex-col" key={1}>
                  <img src={student11} alt={`student ${1 + 1}`} key={2} className={` ${2 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
                <React.Fragment className="flex-col" key={1}>
                  <img src={student2} alt={`student ${1 + 1}`} key={2} className={` ${1 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
                <React.Fragment className="flex-col" key={1}>
                  <img src={student6} alt={`student ${1 + 1}`} key={2} className={` ${2 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
              </div>


              <div className='flex-col hidden w-full space-y-3 md:block'>
                <React.Fragment className="flex-col" key={0}>
                  <img src={student2} alt={`student ${0 + 1}`} key={1} className={` ${0 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
                <React.Fragment className="flex-col" key={0}>
                  <img src={student8} alt={`student ${0 + 1}`} key={1} className={` ${1 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
                <React.Fragment className="flex-col" key={0}>
                  <img src={student3} alt={`student ${0 + 1}`} key={1} className={` ${0 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
              </div>

              <div className='flex-col hidden w-full space-y-3 md:block'>
                <React.Fragment className="flex-col" key={1}>
                  <img src={student8} alt={`student ${1 + 1}`} key={2} className={` ${2 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
                <React.Fragment className="flex-col" key={1}>
                  <img src={student9} alt={`student ${1 + 1}`} key={2} className={` ${1 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
                <React.Fragment className="flex-col" key={1}>
                  <img src={student4} alt={`student ${1 + 1}`} key={2} className={` ${2 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
              </div>

              <div className='flex-col hidden w-full space-y-3 md:block'>
                <React.Fragment className="flex-col" key={1}>
                  <img src={student11} alt={`student ${1 + 1}`} key={2} className={` ${2 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
                <React.Fragment className="flex-col" key={1}>
                  <img src={student2} alt={`student ${1 + 1}`} key={2} className={` ${1 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
                <React.Fragment className="flex-col" key={1}>
                  <img src={student6} alt={`student ${1 + 1}`} key={2} className={` ${2 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
              </div>

              <div className='flex-col hidden w-full space-y-3 md:block'>
                <React.Fragment className="flex-col" key={0}>
                  <img src={student2} alt={`student ${0 + 1}`} key={1} className={` ${0 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
                <React.Fragment className="flex-col" key={0}>
                  <img src={student8} alt={`student ${0 + 1}`} key={1} className={` ${1 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
                <React.Fragment className="flex-col" key={0}>
                  <img src={student3} alt={`student ${0 + 1}`} key={1} className={` ${0 % 2 !== 0 ? 'opacity-0' : ''} `} />
                </React.Fragment>
              </div>

              {/* Last 2 columns */}
              <div className='flex-col w-full h-full space-y-3'>
                <img src={student3} alt={'students'} className="" />
                <img src={student2} alt={'students'} className="" />
                <img src={student11} alt={'students'} className="" />
              </div>
              <div className='flex-col w-full h-full space-y-3'>
                <img src={student4} alt={'students'} className="" />
                <img src={student8} alt={'students'} className="" />
                <img src={student9} alt={'students'} className="bg-white bg-opacity-5" />
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* Where to start */}
      <div className={`relative md:container flex-col px-3 py-4 text-center md:py-12 sm:mx-auto mt-0`}>
        <p className={`relative z-10 mb-8 md:mb-16 text-base font-bold leading-snug text-white md:text-4xl font-goldman ${isFocused ? 'mt-16 md:mt-28 lg:mt-16' : ''}`}>
          Dont know where to start? Prebook a slot with <span className='text-[#FAFF01]'> AcadSpace AI</span>
        </p>

        <div className='relative z-10 flex items-center justify-center h-[220px] md:h-[460px] lg:h-[500px] w-full'>

          <StartSlider className="" />

        </div>

        {/* <div className='flex items-center justify-center w-full max-w-6xl px-4'>
            <div className='border-[1px] border-white rounded-xl p-2 md:p-6 md:w-[80%]'>

              <div className='flex flex-col border-[#FF6B00] border-[1px] items-center justify-center p-3 space-y-2 bg-white md:p-8 rounded-xl'>

                <div className='w-full text-left font-space-grotesk text-xs md:text-lg text-[#1D1050] font-semibold'>Hey There!</div>

                <div className='leading-tight text-[10px] md:text-lg text-left font-space-grotesk text-[#1D1050] ' >Thanks for Visiting AcadSpace AI, your Career Pathfinder, Please Prebook your slot below to get exclusive access for <span className='font-semibold' > “Answers tailored to your needs, curated by top 1% professional’s across the Country”</span></div>

                <div className='flex items-center justify-start w-full text-[8px] text-left md:text-sm font-space-grotesk'> Also get access to </div>

                <div className='grid w-full grid-cols-3 gap-1 text-pretty md:grid-cols-3'>
                  <div>
                    <div className='flex items-center justify-center'>
                      <img src={career_library} alt="career_library" className='object-cover w-16 border-[1px] border-black rounded-lg overflow-clip h-12 md:h-24 md:w-36' />
                    </div>
                    <div className='text-[6px] md:text-xs mt-1'>
                      Explore about different Career Paths, Read in detail about top colleges, schools and universities, etc.
                    </div>
                  </div>
                  <div>
                    <div className='flex items-center justify-center'>
                      <img src={discover} alt="career_library" className='object-cover w-16 border-[1px] border-black rounded-lg overflow-clip h-12 md:h-24 md:w-36' />
                    </div>
                    <div className='text-[6px] md:text-xs mt-1'>
                      Discover live webinars, Courses, Scholarships, top institutes and Many more
                    </div>
                  </div>
                  <div>
                    <div className='flex items-center justify-center'>
                      <img src={ai} alt="career_library" className='object-cover w-16 border-[1px] border-black rounded-lg overflow-clip h-12 md:h-24 md:w-36' />
                    </div>
                    <div className='text-[6px] md:text-xs mt-1'>
                      Acadspace AI uses your data from advance psychological and Scientific Quizzes here to Determine your Career Path.
                    </div>
                  </div>
                </div>

                <div className='flex items-end justify-end w-full pt-1 md:pt-4'>
                  <div className='relative' onClick={handleGetStarted}>
                    <div className='bg-[#FAFF01] relative z-10 text-xs md:text-xl font-goldman px-4 py-1 rounded-md shadow-md text-[#1D1050]'>Book Now!</div>
                    <div className='absolute top-[1px] right-0 w-full h-full bg-[#FAFF01] rounded-md shadow-md'></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


        <img
          src="https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fbg_math.png?alt=media&token=1133783e-165d-499f-9a19-619348431941"
          alt=""
          className={`absolute w-16 h-16 top-20 left-2 opacity-30 lg:opacity-100 md:top-40 lg:top-28 lg:left-24 md:w-[200px] md:h-[200px] ${isFocused ? 'md:top-52 lg:top-40 lg:left-32' : ''}`}
        />

        <img
          src="https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fbg_enginerr.png?alt=media&token=1e1d2309-87f4-47a7-8486-ec65189c750f"
          alt=""
          className={`absolute w-24 h-24 opacity-30 lg:opacity-100 -bottom-0 -left-6 md:-bottom-16 md:p-28 md:-left-28 md:w-[500px] md:h-[450px] ${isFocused ? 'md:-bottom-24 md:-left-36' : ''}`}
        />

        <img
          src="https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fbg_star.png?alt=media&token=7675ec3e-476e-48a5-80d8-45be92380976"
          alt=""
          className={`absolute w-28 h-32 opacity-30 lg:opacity-100 md:top-52 md:right-10 top-40 -right-6 md:w-[320px] md:h-[320px] ${isFocused ? 'md:top-64 md:right-16' : ''}`}
        />

        <img
          src="https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fbg_math.png?alt=media&token=1133783e-165d-499f-9a19-619348431941"
          alt=""
          className={`absolute hidden md:block w-32 h-32 top-20 -left-6 blur-3xl md:top-36 lg:top-28 lg:left-24 md:w-[200px] md:h-[200px] ${isFocused ? 'md:top-48 lg:top-36 lg:left-32' : ''}`}
        />

        <img
          src="https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fbg_enginerr.png?alt=media&token=1e1d2309-87f4-47a7-8486-ec65189c750f"
          alt=""
          className={`absolute hidden md:block w-24 h-24 -bottom-0 blur-xl md:blur-3xl -left-8 md:-bottom-16 md:p-28 md:-left-28 md:w-[500px] md:h-[450px] ${isFocused ? 'md:-bottom-24 md:-left-36' : ''}`}
        />

        <img
          src="https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fbg_star.png?alt=media&token=7675ec3e-476e-48a5-80d8-45be92380976"
          alt=""
          className={`absolute hidden md:block w-48 h-56 md:top-52 md:right-10 top-40 blur-3xl -right-6 md:w-[320px] md:h-[320px] ${isFocused ? 'md:top-64 md:right-16' : ''}`}
        />


      </div>

      {/* How it works */}
      <div className='flex-col items-center justify-center py-4 text-center md:pt-12 sm:mx-auto'>
        <div className={`w-full ${isFocused ? 'mt-28 md:mt-8' : ''} bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10  mb-8 text-3xl sm:text-6xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]`}> How it Works </div>

        <div className='container relative mx-auto md:pt-8'>

          <div className='relative z-10 flex flex-wrap items-center justify-center w-full font-semibold gap-y-8 sm:space-x-8 font-space-grotesk'>

            <div className='w-[300px]'>
              <p className='mb-4 text-2xl font-semibold text-white font-goldman'>Step 1</p>
              <div className='relative rounded-3xl bg-[#FCE4EC] flex text-left px-5 py-5 min-h-80 overflow-clip'>
                <div className='flex-col '>
                  <div className='flex items-center justify-center'>
                    <div className='ml-20 text-lg font-bold leading-tight'>Login to the AcadSpace AI portal</div>
                  </div>
                  <div className='w-full h-[1px] my-8 bg-black' />
                  <div className=''>
                    <ul className="mt-2 space-y-3 text-sm leading-tight text-black font-space-grotesk">
                      <li className=''>Login to the Portal through Login button on the left corner</li>
                      <li className=''>If you are from a Partner Institute, select the Partner Box in the Login Section</li>
                    </ul>
                  </div>
                </div>
                <img src={works1} alt="" className="absolute object-cover mr-2 scale-150 left-8 rounded-3xl top-11 h-44" />
              </div>
            </div>

            <div className='w-[300px]'>
              <p className='mb-4 text-2xl font-semibold text-white font-goldman'>Step 2</p>
              <div className='relative rounded-3xl bg-[#FFF0CB] flex text-left px-5 py-5 min-h-80 overflow-clip'>
                <div className='flex-col'>
                  <div className='flex items-center justify-center'>
                    <div className='ml-20 text-lg font-bold leading-tight'>Ask your Career Questions</div>
                  </div>
                  <div className='w-full h-[1px] my-8 bg-black' />
                  <div className=''>
                    <ul className="mt-2 space-y-3 text-sm leading-tight text-black font-space-grotesk">
                      <li className='pl-2'>Ask your Career Related Queries to AcadSpace AI Engine</li>
                      <li className='pl-2'>Take Psychometric and Scientific Adaptive Quiz of 12 questions.</li>
                    </ul>
                  </div>
                </div>
                <img src={works2} alt="" className="absolute object-cover mr-2 scale-150 left-8 rounded-3xl top-11 h-44" />
              </div>
            </div>

            <div className='w-[300px]'>
              <p className='mb-4 text-2xl font-semibold text-white font-goldman'>Step 3</p>
              <div className='relative rounded-3xl bg-[#EBF2FF] flex text-left px-5 py-5 min-h-80 overflow-clip'>
                <div className='flex-col'>
                  <div className='flex items-center justify-center'>
                    <div className='ml-20 text-lg leading-tight font-space-mono'>Get Personalized Recommendations</div>
                  </div>
                  <div className='w-full h-[1px] my-8 bg-black' />
                  <div className='text-[#28529F]'>
                    <ul className="mt-2 space-y-3 text-sm leading-tight font-space-grotesk">
                      <li className='pl-2'>AcadSpace AI uses your data from advance psychological and Scientific Quizzes to Determine your Career Path with the help of AI</li>
                      <li className='pl-2'>Get a Detailed and Personalized Career Pathway to Explore, Discover Things that help you boost your skillsets and Many more.</li>
                    </ul>
                  </div>
                </div>
                <img src={works3} alt="" className="absolute object-cover mr-2 scale-150 left-8 rounded-3xl top-11 h-44" />
              </div>
            </div>

          </div>

          <div className='absolute flex invisible w-full h-full -top-8 md:visible'>
            <img src={bgarrow} alt="" className="w-full h-full" />
          </div>
        </div>

      </div>

      {/* Institutes partners  */}
      {/* <div className='flex items-center justify-center'> */}
      <div className='relative py-8 text-center bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/0 sm:flex-row overflow-clip md:py-12'>

        <div className='container relative flex flex-col lg:flex-row items-center justify-around lg:h-[400px] mx-auto'>

          <div className='relative flex w-full h-full'>

            <div className='relative z-10 flex items-center justify-center w-full h-[100%] grow' >
              <p className="w-full mb-8 text-base font-bold leading-normal text-white md:text-3xl font-goldman">1.5 Lakh students across 900 institutes
                trust <span className='text-[#FAFF01]'>Acadspace AI</span> for their <span className='text-[#FAFF01]'>Career Questions</span></p>
            </div>

            <div>
              <img src={bgstar} alt="" className="absolute bottom-0 left-0 z-10 hidden object-cover object-bottom lg:block w-28" />
              <img src={bgstar} alt="" className="absolute z-10 hidden object-cover object-bottom w-[70px] lg:block left-[10%] top-0" />
              <img src={bgstar} alt="" className="absolute z-10 hidden lg:block object-cover object-bottom w-12 right-[30%] top-[10%]" />
              <img src={bgstar} alt="" className="absolute bottom-[10%] z-10 hidden object-cover object-bottom w-16 right-[20%] lg:block" />

              <img src={bgstar} alt="" className="absolute bottom-0 left-0 z-10 hidden object-cover object-bottom blur-xl lg:block w-28" />
              <img src={bgstar} alt="" className="absolute z-10 hidden blur-xl object-cover object-bottom w-[70px] lg:block left-[10%] top-0" />
              <img src={bgstar} alt="" className="absolute z-10 hidden lg:block blur-xl object-cover object-bottom w-12 right-[30%] top-[10%]" />
              <img src={bgstar} alt="" className="absolute bottom-[10%] z-10 hidden blur-xl object-cover object-bottom w-16 right-[20%] lg:block" />
            </div>
          </div>

          <div className='flex'>

            <div ref={ref} className='relative z-20 flex flex-col space-y-2 grow md:px-8'>

              <div className='flex space-x-2'>
                <div className='w-9 h-9 md:w-16 md:h-16'></div>
                {/* First Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip' : ''}`}>
                    <img src={CVMULogo2} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip' : ''}`}></div>
                </div>
                <div className='w-9 h-9 md:w-16 md:h-16'></div>
                {/* Second Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip' : ''}`}>
                    <img src={Bhaskaracharya} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip' : ''}`}></div>
                </div>
                <div className='w-9 h-9 md:w-16 md:h-16'></div>
                {/* Third Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip' : ''}`}>
                    <img src={Dypatil} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip' : ''}`}></div>
                </div>
                <div className='w-9 h-9 md:w-16 md:h-16'></div>
                {/* Fourth Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip' : ''}`}>
                    <img src={G38} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip' : ''}`}></div>
                </div>
              </div>

              <div className='flex space-x-2'>
                {/* First Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-2' : ''}`}>
                    <img src={Daulatram} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[1500ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-2' : ''}`}></div>
                </div>

                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* Second Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-2' : ''}`}>
                    <img src={IndianInstituteOfSpaceScienceAndTechnologyLogo} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[1500ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-2' : ''}`}></div>
                </div>

                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* Third Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-2' : ''}`}>
                    <img src={Gitam} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[1500ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-2' : ''}`}></div>
                </div>

                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* Fourth Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-2' : ''}`}>
                    <img src={NVP_logo} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[1500ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-2' : ''}`}></div>
                </div>
              </div>

              <div className='flex space-x-2'>
                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* First Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-3' : ''}`}>
                    <img src={Greenwood} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[2000ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-3' : ''}`}></div>
                </div>

                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* Second Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-3' : ''}`}>
                    <img src={Uvce_logo} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[2000ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-3' : ''}`}></div>
                </div>

                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* Third Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-3' : ''}`}>
                    <img src={Kasiga} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[2000ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-3' : ''}`}></div>
                </div>

                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* Fourth Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-3' : ''}`}>
                    <img src={Accurate} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[2000ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-3' : ''}`}></div>
                </div>
              </div>

              <div className='flex space-x-2'>
                {/* First Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-4' : ''}`}>
                    <img src={Pictures_1_logo} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[2500ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-4' : ''}`}></div>
                </div>

                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* Second Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-4' : ''}`}>
                    <img src={Anna} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[2500ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-4' : ''}`}></div>
                </div>

                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* Third Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-4' : ''}`}>
                    <img src={Podar} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[2500ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-4' : ''}`}></div>
                </div>

                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* Fourth Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-4' : ''}`}>
                    <img src={CMRU_logo} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[2500ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-4' : ''}`}></div>
                </div>
              </div>

              <div className='flex space-x-2'>
                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* First Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-5' : ''}`}>
                    <img src={Rustamjee} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[3000ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-5' : ''}`}></div>
                </div>

                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* Second Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-5' : ''}`}>
                    <img src={Ascent} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[3000ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-5' : ''}`}></div>
                </div>

                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* Third Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-5' : ''}`}>
                    <img src={Saptagirir} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[3000ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-5' : ''}`}></div>
                </div>

                {/* Empty space */}
                <div className='w-9 h-9 md:w-16 md:h-16'></div>

                {/* Fourth Logo */}
                <div className='relative'>
                  <div className={`relative z-10 rounded-lg bg-[#FAFF01] shadow-lg shadow-black/60 w-9 h-9 md:w-16 md:h-16 ${isVisible ? 'animate-diagonal-flip-5' : ''}`}>
                    <img src={army} alt="partner" className={`p-1 aspect-square w-full transition-opacity duration-500 delay-[3000ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`} />
                  </div>
                  <div className={`absolute top-1 w-full h-full rounded-lg bg-[#FAFF01] shadow-black/60 shadow-lg ${isVisible ? 'animate-diagonal-flip-5' : ''}`}></div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
      {/* </div> */}

      {/* Discover */}
      <div className='flex items-center justify-center'>

        <div className='flex-col items-center justify-center w-full pt-4 pb-4 text-center md:pt-0 md:pb-8 sm:mx-auto'>

          <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 mb-4 md:mb-16 text-3xl sm:text-6xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]"> Discover </div>

          <div className='flex items-start justify-around px-12 py-8 space-x-6 overflow-x-scroll md:px-8 scrollbar-hide'>

            <div className='flex-col cursor-pointer shrink-0 w-[245px]' onClick={() => navigate('/discover/webinar')}>
              <div className='relative'>
                <div className='absolute w-full shadow-discover top-3 right-3 border-[1px] p-4 rounded-xl h-[390px] bg-gradient-to-r from-[#FAFF01]/10 to-[#FFFFFF]/10'></div>
                <div className='relative z-10 border-[1px] shadow-discover-custom-1 p-4 rounded-xl h-[390px] bg-[#2D1E6B]'>
                  <div className='flex items-center justify-center mb-4'>
                    <img src={live} alt="webinar" className="object-cover w-12 h-6" />
                    <div className=' text-[#D9D9D9] ml-1'>Broadcasting Now</div>
                  </div>
                  <img src={'https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/webinar%2FWhatsApp%20Image%202024-05-31%20at%2011.59.57%20AM.jpeg?alt=media&'} alt="webinar" className="w-full rounded-xl border-[1px] border-white" />
                  <div className='flex justify-between mt-4 text-[10px] text-white'>
                    <div className='flex items-center justify-center'>
                      <img src={star} className="w-4 h-4 " />POPULAR
                    </div>
                    <div className='flex items-center justify-center font-space-mono'>
                      <img src={avatars} className="w-6 h-4 mr-1" />10K+ Enrolled
                    </div>
                  </div>
                  <div className='flex items-center justify-center mt-4 text-xs leading-tight text-left'>
                    <img src={mentorImage} className="w-12 h-12 mr-2 rounded-full" />
                    <div className=' font-space-mono'>
                      <div className='text-white '>Ms. Veena Dewani</div>
                      <div className='text-[#7A7A7A] leading-none mt-1'>Growth Mindset Coach</div>
                    </div>
                  </div>
                  <div className='mt-4 text-xs leading-tight text-left text-white'>
                    Join us for an inspiring webinar on "How to Develop a Growth Mindset"...
                  </div>
                </div>
              </div>
              <div className='text-[#FAFF01] font-space-mono text-xl font-semibold mt-8'>Live Webinars</div>
            </div>

            <div className='flex-col cursor-pointer  shrink-0 w-[245px]' onClick={() => navigate('/discover/courses')}>
              <div className='relative'>
                <div className='absolute shadow-discover w-full top-3 right-3 border-[1px] p-4 rounded-xl h-[390px] bg-gradient-to-r from-[#FAFF01]/10 to-[#FFFFFF]/10'></div>
                <div className='relative shadow-discover-custom-1 z-10 border-[1px] p-4 rounded-xl h-[390px] bg-[#2D1E6B] flex - items-center justify-center'>
                  <div className='flex-col items-center justify-center space-y-6'>
                    <div className='flex p-3 border-2 border-white rounded-lg'>
                      <div className='flex items-center justify-center'>
                        <img
                          src={courses_fsd}
                          alt="fsd"
                          className="object-cover w-20 h-12 border-white border-[1px] rounded-lg" /></div>
                      <div className='ml-1'>
                        <div className='text-sm font-semibold text-white font-space-grotesk'>Full Stack Development</div>
                        {/* <div className='whitespace-nowrap font-space-grotesk text-[#5E6670] font-normal text-sm'>Most Popular</div> */}
                      </div>
                    </div>
                    <div className='flex p-2 border-2 border-white rounded-lg'>
                      <div className='flex items-center justify-center'>
                        <img
                          src={courses_dataScience}
                          alt=""
                          className="object-cover w-24 shrink-0 h-12 border-white border-[1px] rounded-lg" />
                      </div>
                      <div className='ml-1'>
                        <div className='text-sm font-semibold text-white font-space-grotesk'>Data-Science Fundamentals</div>
                        {/* <div className='whitespace-nowrap font-space-grotesk text-[#5E6670] font-normal text-sm'>Trending</div> */}
                      </div>
                    </div>
                    <div className='flex p-2 border-2 border-white rounded-lg'>
                      <div className='flex items-center justify-center '>
                        <img src={courses_digital_marketing} alt="" className="object-cover w-16 h-12 border-white border-[1px] rounded-lg" />
                      </div>
                      <div className='ml-1'>
                        <div className='text-sm font-semibold text-white font-space-grotesk'>Digital Marketing <br />- Get Started</div>
                        {/* <div className='whitespace-nowrap font-space-grotesk text-[#5E6670] font-normal text-sm'>Top Courses</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-[#FAFF01] font-space-mono text-xl font-semibold mt-8'>Top Courses</div>
            </div>

            <div className='relative pl-8'>

              <div className='relative flex space-x-6 shrink-0'>

                <div className='flex-col opacity-10 shrink-0 w-[223px]'>
                  <div className='relative'>
                    <div className='absolute shadow-discover w-full top-3 right-3 border-[1px] p-4 rounded-xl h-[390px] bg-gradient-to-r from-[#FAFF01]/10 to-[#FFFFFF]/10'></div>
                    <div className='relative shadow-discover-custom-1 z-10 border-[1px] p-4 rounded-xl h-[390px] bg-[#2D1E6B] flex items-center justify-center'>
                      <div className="grid grid-cols-2 gap-5 ">
                        <div>
                          <img src={delhi} alt="Image 1" className="w-full h-auto" />
                          <div className='text-[10px] font-semibold leading-tight text-white font-space-mono shrink-0 mt-2'>Delhi University</div>
                        </div>
                        <div>
                          <img src={jgi} alt="Image 1" className="w-full h-auto" />
                          <div className='text-[10px] font-semibold leading-tight text-white font-space-mono shrink-0 mt-2'>Jain University</div>
                        </div>
                        <div>
                          <img src={mumbai} alt="Image 1" className="w-full h-auto" />
                          <div className='text-[10px] font-semibold leading-tight text-white font-space-mono shrink-0 mt-2'>Mumbai University</div>
                        </div>
                        <div>
                          <img src={amity} alt="Image 1" className="w-full h-auto" />
                          <div className='text-[10px] font-semibold leading-tight text-white font-space-mono shrink-0 mt-2'>Amity University</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-[#FAFF01] font-space-mono text-xl font-semibold mt-8'>Top Institutes</div>
                </div>

                <div className='flex-col opacity-10 shrink-0 w-[223px]'>
                  <div className='relative'>
                    <div className='absolute shadow-discover w-full top-3 right-3 border-[1px] p-4 rounded-xl h-[390px] bg-gradient-to-r from-[#FAFF01]/10 to-[#FFFFFF]/10'></div>
                    <div className='relative z-10 shadow-discover-custom-1 border-[1px] p-3 rounded-xl h-[390px] bg-[#2D1E6B] flex items-center justify-center'>
                      <div className='flex-col items-center justify-center space-y-6'>
                        <div className='flex p-2 px-2 py-2 border-2 border-white rounded-lg'>
                          <img src={graphics} alt="graphics" className="object-cover w-10 h-10" />
                          <div className='ml-1'>
                            <div className='text-sm font-semibold text-white font-space-grotesk'>Graphics & Design</div>
                            <div className='whitespace-nowrap font-space-grotesk text-[#5E6670] font-normal text-sm'>357 Open position</div>
                          </div>
                        </div>
                        <div className='flex p-2 px-2 py-2 border-2 border-white rounded-lg'>
                          <img src={software} alt="graphics" className="object-cover w-10 h-10" />
                          <div className='ml-1'>
                            <div className='text-sm font-semibold text-white font-space-grotesk'>Software Engineer</div>
                            <div className='whitespace-nowrap font-space-grotesk text-[#5E6670] font-normal text-sm'>831 Open position</div>
                          </div>
                        </div>
                        <div className='flex p-2 px-2 py-2 border-2 border-white rounded-lg'>
                          <img src={digital} alt="graphics" className="object-cover w-10 h-10" />
                          <div className='ml-1'>
                            <div className='text-sm font-semibold text-white font-space-grotesk'>Digital Marketing</div>
                            <div className='whitespace-nowrap font-space-grotesk text-[#5E6670] font-normal text-sm'>293 Open position</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-[#FAFF01] font-space-mono text-xl font-semibold mt-8'>Trending Jobs</div>
                </div>

                <div className='flex-col opacity-10 shrink-0 w-[223px]'>
                  <div className='relative'>
                    <div className='absolute shadow-discover w-full top-3 right-3 border-[1px] p-4 rounded-xl h-[390px] bg-gradient-to-r from-[#FAFF01]/10 to-[#FFFFFF]/10'></div>
                    <div className='relative shadow-discover-custom-1 z-10 border-[1px] p-4 rounded-xl h-[390px] bg-[#2D1E6B] flex items-center justify-center'>
                      <div className="grid grid-cols-2 gap-5 ">
                        <div>
                          <img src={delhi} alt="Image 1" className="w-full h-auto" />
                          <div className='text-[10px] font-semibold leading-tight text-white font-space-mono shrink-0 mt-2'>Delhi University</div>
                        </div>
                        <div>
                          <img src={jgi} alt="Image 1" className="w-full h-auto" />
                          <div className='text-[10px] font-semibold leading-tight text-white font-space-mono shrink-0 mt-2'>Jain University</div>
                        </div>
                        <div>
                          <img src={mumbai} alt="Image 1" className="w-full h-auto" />
                          <div className='text-[10px] font-semibold leading-tight text-white font-space-mono shrink-0 mt-2'>Mumbai University</div>
                        </div>
                        <div>
                          <img src={amity} alt="Image 1" className="w-full h-auto" />
                          <div className='text-[10px] font-semibold leading-tight text-white font-space-mono shrink-0 mt-2'>Amity University</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-[#FAFF01] font-space-mono text-xl font-semibold mt-8'>Schlorships</div>
                </div>

              </div>

              <div className='absolute flex items-center justify-center w-full h-full -top-10'>

                <div className='flex-col max-w-xl md:max-w-7xl'>

                  <div className='relative z-10 flex items-center justify-center w-full mt-6'>
                    <div className='flex items-center justify-center w-full max-w-6xl px-4 md:max-w-7xl'>
                      <div className='border-[1px] border-white rounded-xl p-2 md:p-6 md:w-[90%]'>

                        <div className='flex flex-col border-[#FF6B00] border-[1px] items-center justify-center px-3 py-8 space-y-2 bg-white md:p-8 rounded-xl'>

                          <div className='relative z-10 px-4 font-light text-center md:text-base font-goldman'>
                            Get access to top Institutes and jobs using <span className='font-bold' >AcadSpace AI</span>, Prebook your slot to get exclusive content
                          </div>

                          <div className='flex items-center justify-start w-full text-[#1D1050]/50 text-[10px] text-left md:text-xs font-bold font-space-grotesk'> Also get access to </div>

                          <div className='grid w-full grid-cols-3 gap-1 text-pretty md:grid-cols-3'>
                            <div>
                              <div className='flex items-center justify-center'>
                                <img src={career_library} alt="career_library" className='object-contain w-24 rounded-lg md:w-36 overflow-clip h-14 md:h-20' />
                              </div>
                              <div className='mt-1 text-[8px] md:text-[10px] text-[#1D1050] font-light'>
                                Explore about different Career Paths, Read in detail about top colleges, schools and universities, etc.
                              </div>
                            </div>
                            <div>
                              <div className='flex items-center justify-center'>
                                <img src={discover} alt="career_library" className='object-contain w-24 rounded-lg md:w-36 overflow-clip h-14 md:h-20' />
                              </div>
                              <div className='mt-1 text-[8px] md:text-[10px] text-[#1D1050] font-light'>
                                Discover live webinars, Courses, Scholarships, top institutes and Many more
                              </div>
                            </div>
                            <div>
                              <div className='flex items-center justify-center'>
                                <img src={ai} alt="career_library" className='object-contain w-24 rounded-lg md:w-36 overflow-clip h-14 md:h-20' />
                              </div>
                              <div className='mt-1 text-[8px] md:text-[10px] text-[#1D1050] font-light'>
                                Acadspace AI uses your data from advance psychological and Scientific Quizzes here to Determine your Career Path.
                              </div>
                            </div>
                          </div>

                          <div className='flex items-end justify-end w-full pt-1'>
                            <div className='relative' onClick={handleGetStarted}>
                              <div className='bg-[#FAFF01] relative cursor-pointer z-10 text-xs md:text-base font-goldman px-5 py-2 rounded-md shadow-md text-[#1D1050]'>Book Slot!</div>
                              <div className='absolute top-[1px] right-0 w-full h-full bg-[#FAFF01] rounded-md shadow-md'></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>

          <div className='flex md:mt-4 items-center w-full justify-end text-[#FAFF01]'>
            <div className='flex items-center mr-6 text-sm font-semibold cursor-pointer md:text-base font-space-mono' onClick={() => navigate('/discover')}>
              Explore More
              <img src={arrow} alt="arrow" className="w-12 h-12 ml-2" />
            </div>
          </div>

        </div>

      </div>

      {/* Testimonials */}
      <div id='testimonials' className='relative flex-col items-center justify-center py-0 text-center md:py-6 sm:mx-auto'>

        <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10  mb-8 text-3xl sm:text-6xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]"> Testimonials </div>

        <div className='w-[80%] relative z-10 flex-col items-center justify-center max-w-6xl mx-auto space-y-4 overflow-hidden md:container'>

          <div className="mt-6 md:mt-12 rounded-t-[200%] overflow-hidden w-[200%] -left-[50%] md:w-[200%] md:-left-[50%]  h-full absolute top-5 md:top-0 bg-[#291D68]"></div>

          <div className='relative'>

            <img src={trophy} alt="trophy" className="object-contain object-center w-full h-12 md:h-20" />
            <div className='mt-2 md:mt-4 text-[11px] font-semibold text-white md:text-lg font-space-grotesk'>People who have found their Dream <br /> Career through us.</div>
            <div className='text-[#FAFF01] font-space-mono font-semibold text-sm md:text-3xl py-3 md:py-4'>Don’t Just Take Our Word For It</div>

            <div className='relative z-10 flex-col items-center justify-center w-full max-w-6xl mx-auto space-y-4 overflow-hidden'>
              <Slider {...testinomial_settings} className='p-4 mySwiper md:p-8' ref={sliderRef}>

                {/* Card 1 */}
                <div className='w-fit'>
                  <div className='relative p-4'>
                    <div className='flex w-[250px] md:w-[400px] h-40 md:h-64 border-dashed border-2 rounded-xl rotate-[1.5deg] absolute right-3'></div>
                    <div className='bg-[#FCE4EC] rounded-xl flex w-[240px] md:w-[400px] h-40 md:h-64 p-3 md:p-4 -rotate-[1.5deg] relative z-10'>
                      <div className='relative'>
                        <div className='relative z-10'>
                          <div className='-mt-2 -mb-10 text-3xl font-extrabold text-left md:text-7xl'>“</div>
                          <div className='px-4 text-[10px] md:pt-0 pt-5 font-semibold text-center leading-snug opacity-85 md:text-base font-space-mono pb-1 md:pb-2'>Rustomjee International School</div>
                          <div className='px-4 text-[9px] leading-snug opacity-75 md:text-sm font-space-mono'>It provided us with valuable insights into the JEE exam and greatly expanded our understanding of engineering prospects. It felt like I was watching a movie!</div>
                          <div className='px-4 text-[10px] font-semibold text-right leading-snug opacity-75 mt-1 md:text-sm font-space-mono'>-Danish Piroozmand</div>
                          <div className='mr-8 -mt-4 text-3xl font-extrabold text-left rotate-180 md:-mt-8 md:text-7xl'>“</div>
                        </div>
                        <div className='absolute top-0 flex items-center justify-center w-full h-full'>
                          <img src={Rustamjee} alt="Rustamjee" className="h-16 grayscale w-13 md:h-36 opacity-30 md:w-26" />
                        </div>
                      </div>
                    </div>
                    <img src={pin} alt="arrow" className="absolute z-20 w-7 top-4 right-5" />
                    <img src={pin2} alt="arrow" className="absolute z-20 w-4 top-2 right-14 " />
                  </div>
                </div>

                {/* Card 2 */}
                <div className='relative w-fit'>
                  <div className='relative p-4'>
                    <div className='flex w-[250px] md:w-[400px] h-40 md:h-64 border-dashed border-2 rounded-xl rotate-[1.5deg] absolute right-3'></div>
                    <div className='bg-[#FCE4EC] rounded-xl flex w-[240px] md:w-[400px] h-40 md:h-64 p-3 md:p-4 -rotate-[1.5deg] relative z-10'>
                      <div className='relative'>
                        <div className='relative z-10'>
                          <div className='absolute -mt-2 -mb-10 text-3xl font-extrabold text-left md:text-7xl'>“</div>
                          <div className='px-8 md:pt-2 text-[10px] font-semibold text-center leading-snug opacity-75 md:text-base font-space-mono pb-2'>Bhaskaracharya College of Applied Sciences</div>
                          <div className='px-4 text-[10px] leading-snug opacity-85 md:text-sm font-space-mono'>Hi, I am Chahat. The session is truly valuable, and I enjoyed it a lot. She is very humble and addresses everyone's questions. I am grateful that I attended it.</div>
                          <div className='px-4 text-[10px] font-semibold text-right leading-snug opacity-75 mt-1 md:text-sm font-space-mono mr-12'>-Chahat</div>
                          <div className='mr-4 -mt-6 text-3xl font-extrabold text-left rotate-180 md:-mt-8 md:text-7xl'>“</div>
                        </div>
                        <div className='absolute top-0 flex items-center justify-center w-full h-full'>
                          <img src={Bhaskaracharya} alt="Bhaskaracharya" className="h-16 grayscale w-13 md:h-36 opacity-40 md:w-26" />
                        </div>
                      </div>
                    </div>

                    <img src={pin} alt="arrow" className="absolute z-20 w-7 top-4 right-5" />
                    <img src={pin2} alt="arrow" className="absolute z-20 w-4 top-2 right-14 " />
                  </div>
                </div>

                {/* Card 3 */}
                <div className='relative w-fit'>
                  <div className='relative p-4'>
                    <div className='flex w-[250px] md:w-[400px] h-40 md:h-64 border-dashed border-2 rounded-xl rotate-[1.5deg] absolute right-3'></div>
                    <div className='bg-[#FCE4EC] rounded-xl flex w-[240px] md:w-[400px] h-40 md:h-64 p-3 md:p-4 -rotate-[1.5deg] relative z-10'>
                      <div className='relative'>
                        <div className='relative z-10'>
                          <div className='absolute -mt-2 -mb-10 text-3xl font-extrabold text-left md:text-7xl'>“</div>
                          <div className='px-4 text-[10px] md:pt-0 md:pl-10 font-semibold text-center leading-snug opacity-85 md:text-base font-space-mono pb-1 md:pb-2'>Dayananda Sagar University- DSU</div>
                          <div className='px-4 text-[8px] leading-snug opacity-75 md:text-sm font-space-mono'>I recently completed a full-stack development session, and I couldn't be more pleased with the experience. The comprehensive coverage of both front-end and back-end technologies was exactly what I needed to advance my skills and understanding of full-stack development.</div>
                          <div className='px-4 text-[10px] font-semibold text-center leading-snug opacity-75 mt-1 md:text-xs font-space-mono'>-KARTHIK R V.</div>
                          <div className='absolute right-0 -mt-4 text-3xl font-extrabold text-left rotate-180 md:mr-8 -bottom-2 md:-mt-8 md:text-7xl'>“</div>
                        </div>
                        <div className='absolute top-0 flex items-center justify-center w-full h-full'>
                          <img src={'https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Finstitute%2FDSCE.jpeg?alt=media&token=09621524-d6e9-45fd-81a4-de920c9f5339'} alt="DSU" className="h-16 grayscale w-13 md:h-36 opacity-30 md:w-26" />
                        </div>
                      </div>
                    </div>

                    <img src={pin} alt="arrow" className="absolute z-20 w-7 top-3 right-5" />
                    <img src={pin2} alt="arrow" className="absolute z-20 w-4 left-4 top-3 " />
                  </div>
                </div>

                {/* Card 4 */}
                <div className='relative w-fit'>
                  <div className='relative p-4'>
                    <div className='flex w-[250px] md:w-[400px] h-40 md:h-64 border-dashed border-2 rounded-xl rotate-[1.5deg] absolute right-3'></div>
                    <div className='bg-[#FCE4EC] rounded-xl flex w-[240px] md:w-[400px] h-40 md:h-64 p-3 md:p-4 -rotate-[1.5deg] relative z-10'>
                      <div className='relative'>
                        <div className='relative z-10'>
                          <div className='absolute -mt-2 -mb-10 text-3xl font-extrabold text-left md:text-7xl'>“</div>
                          <div className='px-4 text-[10px] pt-2 md:pl-10 font-semibold text-center leading-snug opacity-85 md:text-base font-space-mono pb-1 md:pb-2'>Anna University</div>
                          <div className='px-4 text-[8px] leading-snug opacity-75 md:text-sm font-space-mono'>I recently completed a data science session, and it was an incredibly enriching experience. The depth and breadth of the topics covered provided a solid foundation in data science and advanced my skills significantly.</div>
                          <div className='px-4 text-[10px] font-semibold text-right leading-snug opacity-75 mr-12 mt-1 md:text-sm font-space-mono'>-Priya</div>
                          <div className='absolute right-0 -mt-4 text-3xl font-extrabold text-left rotate-180 md:mr-8 -bottom-12 md:-mt-8 md:text-7xl'>“</div>
                        </div>
                        <div className='absolute top-0 flex items-center justify-center w-full h-full'>
                          <img src={Anna} alt="Anna University" className="h-16 grayscale w-13 md:h-36 opacity-30 md:w-26" />
                        </div>
                      </div>

                      <img src={pin} alt="arrow" className="absolute right-0 z-20 w-5 md:w-7 top-2" />
                      <img src={pin2} alt="arrow" className="absolute z-20 w-3 md:w-4 -top-2 right-7 " />
                    </div>
                  </div>
                </div>

                <div className='w-fit'>
                  <div className='relative p-4'>
                    <div className='flex w-[250px] md:w-[400px] h-40 md:h-64 border-dashed border-2 rounded-xl rotate-[1.5deg] absolute right-3'></div>
                    <div className='bg-[#FCE4EC] rounded-xl flex w-[240px] md:w-[400px] h-40 md:h-64 p-3 md:p-4 -rotate-[1.5deg] relative z-10'>
                      <div className='relative'>
                        <div className='relative z-10'>
                          <div className='-mt-2 -mb-10 text-3xl font-extrabold text-left md:text-7xl'>“</div>
                          <div className='px-4 text-[10px] md:pt-0 pt-5 font-semibold text-center leading-snug opacity-85 md:text-base font-space-mono pb-1 md:pb-2'>Rustomjee International School</div>
                          <div className='px-4 text-[9px] leading-snug opacity-75 md:text-sm font-space-mono'>It provided us with valuable insights into the JEE exam and greatly expanded our understanding of engineering prospects. It felt like I was experiencing a movie!</div>
                          <div className='px-4 text-[10px] font-semibold text-right leading-snug opacity-75 mt-1 md:text-sm font-space-mono'>-Aiden</div>
                          <div className='mr-8 -mt-4 text-3xl font-extrabold text-left rotate-180 md:-mt-8 md:text-7xl'>“</div>
                        </div>
                        <div className='absolute top-0 flex items-center justify-center w-full h-full'>
                          <img src={Rustamjee} alt="Rustamjee" className="h-16 grayscale w-13 md:h-36 opacity-30 md:w-26" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </Slider>
            </div>


          </div>
        </div>

        <div className='absolute left-0 top-0 md:top-[220px] flex items-center justify-center w-full h-full '>
          <div className='absolute container w-[70%] bg-white h-[230px] top-[205px] md:top-10 md:h-[420px] shadow-testimonials'></div>
        </div>

      </div>

      {/* Blogs */}
      <div className='flex-col items-center justify-center py-4 text-center md:py-8 sm:mx-auto'>

        <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10  mb-8 text-3xl sm:text-6xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]"> Blogs </div>

        <div className='relative flex items-center justify-center px-8 space-x-12 overflow-scroll scrollbar-hide h-[500px]'>

          <div className="absolute top-0 w-[470px] md:w-[600px] lg:w-[1340px] h-full">

            <Slider {...settings} className="px-4">

              <div key={1} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 rounded-xl w-80 h-96'>
                <img src={'https://acadspace.org/img/updates/MyCoolQ.png'} alt="blog" className="object-contain object-center rounded-xl" />
                <div className='mt-4 font-bold text-left text-white'>
                  First Large Scale Partnership in East India
                </div>
                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                  AcadSpace inaugurates Personality Coaching & Career Counseling for 1600+ proud parents. We are motivated by such a positive turn-up & feedback…
                </div>
                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/partner-acadspace')}>
                  <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                </div>
              </div>

              <div key={2} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 rounded-xl w-80 h-96'>
                <img src={internship_acadspace} alt="blog" className="object-contain object-center rounded-xl" />
                <div className='font-bold text-left text-white '>
                  <div className="bg-[#F1ECFF] w-fit flex items-center justify-center text-[#6C40FF] text-sm font-normal rounded-xl py-1 px-2 mt-2 font-space-grotesk">
                    <div className="w-3 h-3 bg-[#6C40FF] rounded-full font-light text-[8px] mr-1"></div>Internships
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <div className="flex font-medium text-[#97989F] text-base">
                      <img src={blog_profile} alt="blog" className="w-4 mr-1 rounded-lg md:w-6" /> Manish Shivangi
                    </div>
                    <div className="flex font-medium text-[#97989F] text-sm">
                      July 31st 2024
                    </div>
                  </div>
                  <div className="mt-2">
                    Unlocking Career Success: The Crucial Role of Internships in Career Development
                  </div>
                </div>
                <div className='flex items-center justify-end w-full -mt-2 cursor-pointer' onClick={() => navigate('/blog/internship')}>
                  <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                </div>
              </div>

              <div key={3} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                <img src={ssc_chsl_exam} alt="blog" className="object-contain object-center rounded-xl" />
                <div className='mt-4 font-bold text-left text-white'>
                  What is the SSC CGL Exam? Everything You Need to Know
                </div>
                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                  The SSC CGL (Staff Selection Commission Combined Higher Secondary Level) exam is a crucial opportunity for those looking to secure government jobs…
                </div>
                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/ssc-cgl')}>
                  <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                </div>
              </div>

              <div key={4} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                <img src={TimeManagement12thexam} alt="blog" className="object-cover object-center min-h-48 rounded-xl" />
                <div className='mt-4 font-bold text-left text-white'>
                  How To Manage Time During Class 12th Exams?
                </div>
                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                  Learn how to do time management for Class 12th exams with tips on creating a study plan, balancing study and rest, and reducing stress…
                </div>
                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/12th')}>
                  <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                </div>
              </div>

              <div key={5} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                <img src={neet_exam_banner} alt="blog" className="object-cover object-center min-h-48 rounded-xl" />
                <div className='mt-4 font-bold text-left text-white'>
                  NEET: Medical Exam for Students
                </div>
                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                  The NEET (National Eligibility cum Entrance Test) is an Indian nationwide entrance examination conducted by the National Testing Agency (NTA) for admission to undergraduate medical programs…
                </div>
                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/neet')}>
                  <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                </div>
              </div>

            </Slider>

          </div>

        </div>

        {/* <div className='flex items-center w-full justify-end text-[#FAFF01]'>
                    <div className='flex items-center mr-6 font-semibold cursor-pointer font-space-mono' onClick={() => navigate('/blog')}>
                        Read More
                        <img src={arrow} alt="arrow" className="w-12 h-12 ml-2" />
                    </div>
                </div> */}

      </div>

    </div>
  );
};

export default Home;
