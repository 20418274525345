import React, { useState, useEffect } from 'react';
import config from '../config.json';
import logo from '../assets/AcadSpaceLogo.png';

const generateUsername = (lastName) => lastName.toLowerCase();
const generatePin = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let pin = '';
  for (let i = 0; i < 4; i++) {
    pin += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return pin;
};

const LoginPopup = ({ onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [pin, setPin] = useState('');

  useEffect(() => {
    if (firstName && lastName) {
      setUsername(generateUsername(lastName));
      setPin(generatePin());
    }
  }, [firstName, lastName]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="px-8 pb-8  bg-[#1D104F] rounded-lg shadow-lg ">
        <img src={logo} alt="AcadSpace Logo" className="object-cover w-32 h-16 " />
        <h2 className="mb-16 text-3xl font-bold text-center text-white font-goldman">Login</h2>
        <div className='px-8 pb-16 space-y-8'>
          <div className='flex items-center justify-center px-8'>
            <div className="relative w-full cursor-pointer">
              <div className='relative z-10 shadow-custom-login-2 bg-[#FAFF01] py-4 px-8 font-goldman text-center rounded-lg'>
                Login Through Mobile number
              </div>
              <div className='absolute top-1 w-full h-full rounded-lg bg-[#FAFF01]'></div>
            </div>
          </div>
          <div className='flex items-center justify-center px-8'>
            <div className="relative w-full cursor-pointer">
              <div className='relative z-10 shadow-custom-login-2 bg-[#FAFF01] py-4 px-8 font-goldman text-center rounded-lg'>
                Login Using Username
              </div>
              <div className='absolute top-1 w-full h-full rounded-lg bg-[#FAFF01]'></div>
            </div>
          </div>
          <div className='w-full text-xs text-center text-white font-space-grotesk'>
            Don’t have an account? <span className='text-[#6297FF] cursor-pointer ml-2'> Go to signup.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
