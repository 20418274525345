import React, { useEffect, useState } from 'react';
import course_hero from '../../assets/course_hero.png';
import course_sample2 from '../../assets/sourse_sample2.png';
import star from '../../assets/star_purple.png';
import person from '../../assets/person.png';
import lesson from '../../assets/lesson.png';
import course_student from '../../assets/course_student.png';
import course_trophy from '../../assets/course_trophy.png';
import course_sample3 from '../../assets/course_sample3.png';
import course_sample4 from '../../assets/course_sample4.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../components/common/AppConfig';

const Courses = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [category, setCategory] = useState('');
    const [topic, setTopic] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [coursesData, setCoursesData] = useState({ top: [], trend: [], studentchoice: [] });
    const [itemsToShow, setItemsToShow] = useState(2);

    useEffect(() => {
        // Fetch courses data from the API
        axios.post(`${BASE_URL}/web/topCourses`)
            .then(response => {
                setCoursesData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching courses data:', error);
                setLoading(false);
            });

        const updateItemsToShow = () => {
            if (window.innerWidth < 768) {
                setItemsToShow(2);
            } else {
                setItemsToShow(4);
            }
        };

        window.addEventListener('resize', updateItemsToShow);
        updateItemsToShow();

        return () => window.removeEventListener('resize', updateItemsToShow);

    }, []);

    const handleSearch = () => {
        navigate('/login');
        console.log('Searching for:', searchTerm, category, topic, selectedOption);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const options = ["Design", "Science", "Business", "Marketing"];

    if (loading) {
        return (
            <div className="flex items-center justify-center h-[88vh] bg-[#1D1050]">
                <img src={'https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fgiphy-unscreen.gif?alt=media&token=788057b2-0081-4d8c-88e1-5926052daa5e'} alt="Loading..." 
                className="h-28 w-28 md:h-32 md:w-32" />
            </div>
        );
    }

    // const coursesData = {
    //     "top": [
    //         {
    //             "_id": "66a9dc612061c525ecd196f1",
    //             "courseName": "Building Skilled Healthcare",
    //             "courseDescription": "Discover your path to a rewarding healthcare career with PW School of \nHealthcare,where excellence meets accessibility. Our institution is\n committed to providing top-tier education at an affordable cost, \nensuring that economic constraints and exam scores do not hinder\n your aspirations.\n\nWE HAVE PARTNERED WITH TOP UGC APPROVED NAAC A+ UNIVERSITIES\nTO PROVIDE YOU WITH THE BEST EDUCATION.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.04.44%E2%80%AFPM.png?alt=media&",
    //             "price": 42500,
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FB-VOC%20in%20OTT%20(%203%20Years%20).pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9e3962061c525ecd196f6",
    //             "courseName": "PowerBi Bootcamp Brochure",
    //             "courseDescription": "The ability to analyze data and translate it into actionable insights is a highly sought-after skill in today's job market. This Power BI course equips you with the knowledge and tools to impress potential employers during your college placements and internship applications.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.36.54%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FPowerBi%20Bootcamp%20Brochure.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9dc612061c525ecd196f2",
    //             "courseName": "FUNDAMENTAL ANALYSIS COURSE",
    //             "courseDescription": "Fundamental analysis certification\ncourse will help you to identify in\nwhich sector or segment you have to\nenter to invest by evaluating a\nsecurity / share or commodity by\nanalysis of the industry after\nidentifying the industry you have to\ndo company analysis and identify\nthe company, stock, currency or\ncommodity in which you have to\ninvest or trade. Fundamental\nanalysis will guide you to identify in\nwhich direction buy or sell you have\nto enter in the particular stock or\ncommodities. This course will\nenable you to understand and\npredict the financial market\nmovements with the tools regarding\nfundamental analysis.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.08.59%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FFundamental%20analysis%20course.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9e3962061c525ecd196f8",
    //             "courseName": "Decode c++ with DSA",
    //             "courseDescription": "The importance of learning Data Structures and Algorithms(DSA)lies in its transformative impact on problem-solvingabilitiesandcode efficiency. Mastery of DSA forms a solid foundationincomputerscience, enhancing prospects in technical interviews andenablingdirect application in real-world projects. Through DSA,critical thinkingskills are cultivated as algorithms are analyzed andsolutionstailoredto specific constraints. In essence, DSA empowers individualstounleash their potential in the dynamic landscape of technology.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.31.47%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FDecode%20c%2B%2B%20with%20DSA.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         }
    //     ],
    //     "trend": [
    //         {
    //             "_id": "66a9e3962061c525ecd196fa",
    //             "courseName": "Data Science with Generative AI",
    //             "courseDescription": "Generative AI stands at the forefront of technological innovation, revolutionising the way we interact with technology. It's not just a breakthrough; it's a game-changer. This cutting-edge technology harnesses the power of machine learning to create, imitate, and generate content autonomously, opening doors to endless possibilities.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.27.56%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FData%20Science%20with%20Generative%20AI.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9e3962061c525ecd196f3",
    //             "courseName": "MASTERING THE POWER OF ARTIFICIAL INTELLIGENCE",
    //             "courseDescription": "The PW Skill Up program offers comprehensive upskilling opportunities for students seeking in PM(Product Management). Through engaging coursework and hands-on training modules, students will gain proficiency in key concepts, tools, and best practices essential for success in the rapidly evolving fields of Product Management. With expert guidance and practical experience, the PW Skill Up program empowers students to become proficient Product Management professionals, ready to make an impact in the digital landscape",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.12.21%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FMaster%20the%20Power%20of%20Artifical%20Intellegence.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9e3962061c525ecd196f8",
    //             "courseName": "Decode c++ with DSA",
    //             "courseDescription": "The importance of learning Data Structures and Algorithms(DSA)lies in its transformative impact on problem-solvingabilitiesandcode efficiency. Mastery of DSA forms a solid foundationincomputerscience, enhancing prospects in technical interviews andenablingdirect application in real-world projects. Through DSA,critical thinkingskills are cultivated as algorithms are analyzed andsolutionstailoredto specific constraints. In essence, DSA empowers individualstounleash their potential in the dynamic landscape of technology.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.31.47%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FDecode%20c%2B%2B%20with%20DSA.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9e3962061c525ecd196f4",
    //             "courseName": "UI/UX Designing with DSA",
    //             "courseDescription": "The PW Skill Up program offers comprehensive upskilling opportunities for students seeking UI/UX Design Course. Through engaging coursework and hands-on training modules, students will gain proficiency in key concepts, tools, and best practices essential for success in the rapidly evolving fields of. With expert guidance, immersive learning and practical experience, the PW Skill Upskilling program empowers students to become proficient UI/UX Design professionals, ready to make an impact in the digital landscape.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.29.11%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FUIUX%20Designing%20with%20DSA.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         }
    //     ],
    //     "studentchoice": [
    //         {
    //             "_id": "66a9dc612061c525ecd196f1",
    //             "courseName": "Building Skilled Healthcare",
    //             "courseDescription": "Discover your path to a rewarding healthcare career with PW School of \nHealthcare,where excellence meets accessibility. Our institution is\n committed to providing top-tier education at an affordable cost, \nensuring that economic constraints and exam scores do not hinder\n your aspirations.\n\nWE HAVE PARTNERED WITH TOP UGC APPROVED NAAC A+ UNIVERSITIES\nTO PROVIDE YOU WITH THE BEST EDUCATION.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.04.44%E2%80%AFPM.png?alt=media&",
    //             "price": 42500,
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FB-VOC%20in%20OTT%20(%203%20Years%20).pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9e3962061c525ecd196f4",
    //             "courseName": "UI/UX Designing with DSA",
    //             "courseDescription": "The PW Skill Up program offers comprehensive upskilling opportunities for students seeking UI/UX Design Course. Through engaging coursework and hands-on training modules, students will gain proficiency in key concepts, tools, and best practices essential for success in the rapidly evolving fields of. With expert guidance, immersive learning and practical experience, the PW Skill Upskilling program empowers students to become proficient UI/UX Design professionals, ready to make an impact in the digital landscape.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.29.11%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FUIUX%20Designing%20with%20DSA.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9e3962061c525ecd196f9",
    //             "courseName": "Decoding Python with DSA",
    //             "courseDescription": "The importance of learning Data Structures and Algorithms(DSA)lies in its transformative impact on problem-solvingabilitiesandcode efficiency. Mastery of DSA forms a solid foundationincomputerscience, enhancing prospects in technical interviews andenablingdirect application in real-world projects. Through DSA,critical thinkingskills are cultivated as algorithms are analyzed andsolutionstailoredto specific constraints. In essence, DSA empowers individualstounleash their potential in the dynamic landscape of technology.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.34.47%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FDecoding%20Python%20with%20DSA.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9e3962061c525ecd196f5",
    //             "courseName": "Product Manageemnt with the help of AI",
    //             "courseDescription": "The PW Skill Up program offers comprehensive upskilling opportunities for students seeking in PM(Product Management). Through engaging coursework and hands-on training modules, students will gain proficiency in key concepts, tools, and best practices essential for success in the rapidly evolving fields of Product Management. With expert guidance and practical experience, the PW Skill Up program empowers students to become proficient Product Management professionals, ready to make an impact in the digital landscape.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.35.59%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FProduct%20Manageemnt%20with%20the%20help%20of%20AI.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9e3962061c525ecd196f6",
    //             "courseName": "PowerBi Bootcamp Brochure",
    //             "courseDescription": "The ability to analyze data and translate it into actionable insights is a highly sought-after skill in today's job market. This Power BI course equips you with the knowledge and tools to impress potential employers during your college placements and internship applications.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.36.54%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FPowerBi%20Bootcamp%20Brochure.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9e3962061c525ecd196f8",
    //             "courseName": "Decode c++ with DSA",
    //             "courseDescription": "The importance of learning Data Structures and Algorithms(DSA)lies in its transformative impact on problem-solvingabilitiesandcode efficiency. Mastery of DSA forms a solid foundationincomputerscience, enhancing prospects in technical interviews andenablingdirect application in real-world projects. Through DSA,critical thinkingskills are cultivated as algorithms are analyzed andsolutionstailoredto specific constraints. In essence, DSA empowers individualstounleash their potential in the dynamic landscape of technology.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.31.47%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FDecode%20c%2B%2B%20with%20DSA.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9e3962061c525ecd196f3",
    //             "courseName": "MASTERING THE POWER OF ARTIFICIAL INTELLIGENCE",
    //             "courseDescription": "The PW Skill Up program offers comprehensive upskilling opportunities for students seeking in PM(Product Management). Through engaging coursework and hands-on training modules, students will gain proficiency in key concepts, tools, and best practices essential for success in the rapidly evolving fields of Product Management. With expert guidance and practical experience, the PW Skill Up program empowers students to become proficient Product Management professionals, ready to make an impact in the digital landscape",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.12.21%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FMaster%20the%20Power%20of%20Artifical%20Intellegence.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         },
    //         {
    //             "_id": "66a9e3962061c525ecd196f7",
    //             "courseName": "Devops and Cloud Computing",
    //             "courseDescription": "The PW Skill Up program offers comprehensive upskilling opportunities for students seeking cloud computing and DevOps platforms. Through engaging coursework and hands-on training modules, students will gain proficiency in key concepts, tools, and best practices essential for success in the rapidly evolving fields of cloud computing and DevOps. With expert guidance and practical experience, the PW Skill Up program empowers students to become proficient cloud computing and DevOps professionals, ready to make an impact in the digital landscape.",
    //             "image": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FScreenshot%202024-07-31%20at%2012.31.05%E2%80%AFPM.png?alt=media&",
    //             "price": "Free",
    //             "pdflink": "https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/images%2FDevops%20and%20Cloud%20Computing.pdf?alt=media&",
    //             "counemail": "PWSkills"
    //         }
    //     ]
    // }

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] space-y-6 md:space-y-12 transform-gpu select-none max-w-full overflow-clip'>

            <div className='flex-col items-center justify-center text-center sm:mx-auto'>
                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 text-3xl sm:text-6xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]"> Courses </div>
            </div>

            <div className='text-[#6C7880] px-4 font-medium font-space-grotesk'>
                <span className='cursor-pointer' onClick={() => navigate('/')} >Home</span> {'>'}
                <span className='cursor-pointer' onClick={() => navigate('/discover')} > Discover</span> {'>'}
                <span className='text-[#247FD3] cursor-pointer' onClick={() => navigate('/discover/courses')}> Courses</span>
            </div>

            <div className='relative'>
                <div className='relative'>
                    <img src={course_hero} alt="Courses" className="relative w-full px-4 -z-10" />

                    <div className='absolute top-0 flex items-center justify-center w-full h-full'>
                        <div>
                            <div className='font-semibold text-center text-white text-md md:text-5xl font-space-grotesk'>Learn something new everyday.</div>
                            <div className='text-[10px] md:text-lg text-center text-[#E6EA48] md:mt-4'>Become professionals and ready to join the world.</div>
                        </div>
                    </div>
                </div>

                <div className='relative -mt-8 -z-10'>
                    <div className='bg-[#2D1E6B] mx-4 rounded-xl items-center flex justify-center py-3 px-4'>
                        <div className='w-full md:py-4'>

                            <div className='font-semibold text-center text-white font-space-grotesk md:text-lg'>
                                What do you want to learn?
                            </div>

                            <div className='relative md:py-5'>

                                <div className='relative blur-sm'>
                                    <div className='flex flex-col md:flex-row md:py-4 md:space-x-4 w-full items-center mt-2 font-space-grotesk space-y-2 md:space-y-0 text-[10px]'>
                                        <input
                                            type="text"
                                            placeholder="Find courses, skills, software, etc"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className='w-full p-1 rounded-sm placeholder-[#000000]/50'
                                        />
                                        <select
                                            value={category}
                                            onChange={(e) => setCategory(e.target.value)}
                                            className='w-full p-1 rounded-sm'
                                        >
                                            <option value="text-[#000000]/50">Select Category</option>
                                            <option value="category1">Category 1</option>
                                            <option value="category2">Category 2</option>
                                            {/* Add more options as needed */}
                                        </select>
                                        <select
                                            value={topic}
                                            onChange={(e) => setTopic(e.target.value)}
                                            className='w-full p-1 rounded-sm'
                                        >
                                            <option value="text-[#000000]/50">Select Topic</option>
                                            <option value="topic1">Topic 1</option>
                                            <option value="topic2">Topic 2</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </div>
                                </div>

                                <div className='absolute top-0 left-0 flex items-center justify-center w-full h-full bg-[#2D1E6B]/60'>
                                    <div className='relative z-10 px-4 text-xs font-semibold text-center text-white md:text-2xl font-goldman'>
                                        Get access to top Institutes and jobs using <span className='text-[#FAFF01]' >AcadSpace AI</span>, Prebook your slot to get exclusive content
                                    </div>
                                </div>

                            </div>

                            <div className='relative z-20 flex items-center justify-center w-full mt-3 md:mt-0'>
                                <button
                                    onClick={() => handleSearch}
                                    className='py-1 px-8 bg-[#FAFF01] text-[#2D1E6B] text-sm font-goldman rounded-lg cursor-pointer shadow-lg md:text-xl'
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='w-full font-semibold text-center text-white underline underline-offset-8 md:text-3xl font-space-grotesk'>Browse Our <span className='text-[#247FD3] underline'>Top Courses</span></div>

                <div className='flex flex-wrap items-center my-3 md:my-8 justify-center font-space-grotesk space-x-1 md:space-x-3 text-[10px] md:text-base text-white'>
                    {options.map((option) => (
                        <button
                            key={option}
                            onClick={() => handleOptionClick(option)}
                            className={`p-1 m-1 ${selectedOption === option ? 'underline decoration-[#E7EC43] underline-offset-8 font-bold' : 'font-light'}`}
                        >
                            {option}
                        </button>
                    ))}
                    <button
                        onClick={() => handleOptionClick('+')}
                        className={`p-1 m-1 text-xl rounded-lg ${selectedOption === '+' ? 'font-semibold' : ''}`}
                    >
                        +
                    </button>
                </div>

                <div className='flex px-8 space-x-6 overflow-x-scroll scrollbar-hide'>
                    {coursesData.top.map((course) => (
                        <div key={course._id} className='font-space-grotesk'>
                            <div className='flex w-[300px] md:w-[320px]'>
                                <div className='flex-col w-full p-4 bg-white rounded-xl'
                                    onClick={() => navigate(`/discover/courses/${course._id}`, { state: { course } })}
                                >

                                    <img src={course.image} alt="Courses" className="object-cover w-full aspect-square" />

                                    <div className='flex items-center justify-between w-full mt-2 md:mt-4'>
                                        <div className='flex items-center justify-center text-xs md:text-sm'>
                                            <img src={person} alt="" className="inline object-center w-3 h-3 mr-1 md:w-5 md:h-5" /> Acadspace
                                        </div>
                                        <div className='flex text-xs md:text-sm'>
                                            <div className='mr-2 space-x-1'>
                                                <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                                <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                                <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                                <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                                <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                            </div>
                                            4.5
                                        </div>
                                    </div>

                                    <div className='w-full mt-3 mb-2 text-[#080808] font-semibold font-space-grotesk min-h-10 text-xs md:text-base'
                                        onClick={() => navigate(`/discover/courses/${course._id}`, { state: { course } })}>
                                        {course.courseName}
                                    </div>

                                    <div className='flex items-center justify-between w-full font-medium text-[10px] md:text-xs'>
                                        <div className='flex items-center justify-center'>
                                            <img src={lesson} alt="Courses" className="w-3 h-3 mr-1" />
                                            Lesson : 6
                                        </div>
                                        <div className='flex items-center justify-center'>
                                            <img src={course_student} alt="Courses" className="w-3 h-3 mr-1" />
                                            Student : 198
                                        </div>
                                        <div className='flex items-center justify-center'>
                                            <img src={course_trophy} alt="Courses" className="w-3 h-3 mr-1" />
                                            Beginner
                                        </div>
                                    </div>

                                    <div className='flex items-center justify-between mt-4'>
                                        <div className='flex items-center justify-center p-1 px-3 text-[10px] md:text-base font-medium text-white bg-black rounded-xl w-fit'>
                                            Start Course <span className='ml-2 text-xl'>{'>'}</span>
                                        </div>
                                        <div className='text-xs font-bold md:text-base'>
                                            Rs.{course.price}
                                            <span className='pl-1 text-[10px] font-normal line-through'>Rs.40000</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    ))}
                </div>

            </div>

            <div>
                <div className='w-full font-semibold text-center text-white md:text-4xl font-space-grotesk'>Trending <span className='text-[#247FD3]'>Courses</span></div>

                <div className='relative mt-4 md:hidden'>
                    <img src={course_sample3} alt="Courses" className="relative w-full px-8" />
                    <div className='absolute bottom-0 flex items-end justify-end w-full h-full px-12 pb-4'>
                        <div>
                            <div className='text-[#F9F9F9]/90 text-xs'>Anu Kulkarni</div>
                            <div className='w-full mt-1 text-xs font-semibold text-white'>Masterclass in Design Thinking, Innovation & Creativity</div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='flex px-8 space-x-6 overflow-x-scroll md:flex-row md:space-y-0 scrollbar-hide'>
                {coursesData.top.map((course) => (
                    <div key={course._id} className='font-space-grotesk'>
                        <div className='flex w-[300px] md:w-[320px]'>
                            <div className='flex-col w-full p-4 bg-white rounded-xl'
                                onClick={() => navigate(`/discover/courses/${course._id}`, { state: { course } })}
                            >

                                <img src={course.image} alt="Courses" className="object-cover w-full aspect-square" />

                                <div className='flex items-center justify-between w-full mt-2 md:mt-4'>
                                    <div className='flex items-center justify-center text-xs md:text-sm'>
                                        <img src={person} alt="" className="inline object-center w-3 h-3 mr-1 md:w-5 md:h-5" /> Acadspace
                                    </div>
                                    <div className='flex text-xs md:text-sm'>
                                        <div className='mr-2 space-x-1'>
                                            <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                            <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                            <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                            <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                            <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                        </div>
                                        4.5
                                    </div>
                                </div>

                                <div className='w-full mt-3 mb-2 text-[#080808] font-semibold font-space-grotesk min-h-10 text-xs md:text-base'>
                                    {course.courseName}
                                </div>

                                <div className='flex items-center justify-between w-full font-medium text-[10px] md:text-xs'>
                                    <div className='flex items-center justify-center'>
                                        <img src={lesson} alt="Courses" className="w-3 h-3 mr-1" />
                                        Lesson : 6
                                    </div>
                                    <div className='flex items-center justify-center'>
                                        <img src={course_student} alt="Courses" className="w-3 h-3 mr-1" />
                                        Student : 198
                                    </div>
                                    <div className='flex items-center justify-center'>
                                        <img src={course_trophy} alt="Courses" className="w-3 h-3 mr-1" />
                                        Beginner
                                    </div>
                                </div>

                                <div className='flex items-center justify-between mt-4'>
                                    <div className='flex items-center justify-center p-1 px-3 text-[10px] md:text-base font-medium text-white bg-black rounded-xl w-fit'>
                                        Start Course <span className='ml-2 text-xl'>{'>'}</span>
                                    </div>
                                    <div className='text-xs font-bold md:text-base'>
                                        Rs.{course.price}
                                        <span className='pl-1 text-[10px] font-normal line-through'>Rs.40000</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                ))}
            </div>

            <div>
                <div className='w-full font-semibold text-center text-white font-space-grotesk md:text-4xl'>Students <span className='text-[#247FD3]'>Choice</span></div>
            </div>

            <div className='flex px-8 pb-8 space-x-6 overflow-x-scroll md:pb-16 scrollbar-hide'>
                {coursesData.top.map((course) => (
                    <div key={course._id} className='font-space-grotesk'>                    
                        <div className='flex w-[300px] md:w-[320px]'>
                            <div className='flex-col w-full p-4 bg-white rounded-xl'
                                onClick={() => navigate(`/discover/courses/${course._id}`, { state: { course } })}
                            >

                                <img src={course.image} alt="Courses" className="object-cover w-full aspect-square" />

                                <div className='flex items-center justify-between w-full mt-2 md:mt-4'>
                                    <div className='flex items-center justify-center text-xs md:text-sm'>
                                        <img src={person} alt="" className="inline object-center w-3 h-3 mr-1 md:w-5 md:h-5" /> Acadspace
                                    </div>
                                    <div className='flex text-xs md:text-sm'>
                                        <div className='mr-2 space-x-1'>
                                            <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                            <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                            <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                            <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                            <img src={star} alt="" className="inline object-center w-2 h-2 md:w-3 md:h-3" />
                                        </div>
                                        4.5
                                    </div>
                                </div>

                                <div className='w-full mt-3 mb-2 text-[#080808] font-semibold font-space-grotesk min-h-10 text-xs md:text-base'>
                                    {course.courseName}
                                </div>

                                <div className='flex items-center justify-between w-full font-medium text-[10px] md:text-xs'>
                                    <div className='flex items-center justify-center'>
                                        <img src={lesson} alt="Courses" className="w-3 h-3 mr-1" />
                                        Lesson : 6
                                    </div>
                                    <div className='flex items-center justify-center'>
                                        <img src={course_student} alt="Courses" className="w-3 h-3 mr-1" />
                                        Student : 198
                                    </div>
                                    <div className='flex items-center justify-center'>
                                        <img src={course_trophy} alt="Courses" className="w-3 h-3 mr-1" />
                                        Beginner
                                    </div>
                                </div>

                                <div className='flex items-center justify-between mt-4'>
                                    <div className='flex items-center justify-center p-1 px-3 text-[10px] md:text-base font-medium text-white bg-black rounded-xl w-fit'>
                                        Start Course <span className='ml-2 text-xl'>{'>'}</span>
                                    </div>
                                    <div className='text-xs font-bold md:text-base'>
                                        Rs.{course.price}
                                        <span className='pl-1 text-[10px] font-normal line-through'>Rs.40000</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default Courses;
