import React, { useState, useEffect } from 'react';
import axios from 'axios';
import star from '../../assets/star.png';
import ai from '../../assets/ai.png';
import { useNavigate } from 'react-router-dom';
import arrow from '../../assets/arrow.png';
import { BASE_URL } from '../../components/common/AppConfig';
import person from '../../assets/person.png';
import lesson from '../../assets/lesson.png';
import course_student from '../../assets/course_student.png';
import course_trophy from '../../assets/course_trophy.png';
import discover_sample from '../../assets/samples/discover_sample.mp4';
import career_library from '../../assets/login-bg-2.png';
import discover from '../../assets/discover.png';
import { useMediaQuery } from 'react-responsive';
import architect from '../../assets/careers/main-careers/architecture.png';
import biological from '../../assets/careers/main-careers/biological.png';
import businessManagement from '../../assets/careers/main-careers/Business Management.png';
import designing from '../../assets/careers/main-careers/Designing.png';
import engineering from '../../assets/careers/main-careers/Engineering.png';
import environmentalScience from '../../assets/careers/main-careers/Environmental Science.png';
import finance from '../../assets/careers/main-careers/finance.png';
import fineArts from '../../assets/careers/main-careers/Fine Arts.png';
import hospitality from '../../assets/careers/main-careers/Hospitality.png';
import law from '../../assets/careers/main-careers/Law.png';
import mediaAndCommunication from '../../assets/careers/main-careers/Media and Communication.png';
import medicalScience from '../../assets/careers/main-careers/Medical Science.png';
import performingArts from '../../assets/careers/main-careers/Performing Arts.png';
import physicalScience from '../../assets/careers/main-careers/Physical Science.png';
import socialScience from '../../assets/careers/main-careers/Social Science.png';
import Ins from '../../assets/samples/Ins.png';
import job from '../../assets/samples/Job List.png';

const Discover = () => {
    const [selectedCareer, setSelectedCareer] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [webinarData, setWebinarData] = useState([]);
    const navigate = useNavigate();

    const data = [
        { title: 'Medical Science', subtitle: '17 Webinars', image: medicalScience },
        { title: 'Law', subtitle: '17 Webinars', image: law },
        { title: 'Life Sciences', subtitle: '12 Webinars', image: biological },
        { title: 'Fine Arts', subtitle: '17 Webinars', image: fineArts },
        { title: 'Architecture', subtitle: '12 Webinars', image: architect },
        { title: 'Business Management', subtitle: '12 Webinars', image: businessManagement },
        { title: 'Performing Arts', subtitle: '16 Webinars', image: performingArts },
        { title: 'Engineering', subtitle: '12 Webinars', image: engineering },
        { title: 'Designing', subtitle: '16 Webinars', image: designing },
        { title: 'Hospitality', subtitle: '16 Webinars', image: hospitality },
        { title: 'Physical Science', subtitle: '11 Webinars', image: physicalScience },
        { title: 'Environmental Science', subtitle: '11 Webinars', image: environmentalScience },
        { title: 'Finance', subtitle: '16 Webinars', image: finance },
        { title: 'Social Science', subtitle: '17 Webinars', image: socialScience },
        { title: 'Media and Communication', subtitle: '21 Webinars', image: mediaAndCommunication },
    ];

    const [loading, setLoading] = useState(true);
    const [coursesData, setCoursesData] = useState({ top: [], trend: [], studentchoice: [] });

    const isDesktop = useMediaQuery({ minWidth: 768 });
    const displayedData = isDesktop ? data : (showAll ? data : data.slice(0, 4));

    useEffect(() => {
        const fetchWebinars = async () => {
            try {
                const response = await axios.post(`${BASE_URL}/web/upcomingWebinar`, {
                    date: "2024-03-01"
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setWebinarData(response.data.webinars);
            } catch (error) {
                console.error('Error fetching webinar data:', error);
            }
        };

        // Fetch courses data from the API
        axios.post(`${BASE_URL}/web/topCourses`)
            .then(response => {
                setCoursesData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching courses data:', error);
                setLoading(false);
            });

        fetchWebinars();
    }, []);

    useEffect(() => {
        if (isDesktop) {
            setShowAll(true);
        } else {
            setShowAll(false);
        }
    }, [isDesktop]);

    const handleGetStarted = () => {
        navigate('/login');
    };

    const handleSelect = (index) => {
        setSelectedCareer(index);
    };

    const handleShowMore = () => {
        setShowAll(!showAll);
    };

    const handleNavigate = (webinarId) => {
        navigate('/discover/webinar-details', { state: { webinarId } });
    };

    const handleCareerClick = (index, careerName) => {
        handleSelect(index);
        navigate(`/discover/career`, { state: { careerName } });
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-[88vh] bg-[#1D1050]">
                <img src={'https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fgiphy-unscreen.gif?alt=media&token=788057b2-0081-4d8c-88e1-5926052daa5e'} alt="Loading..." 
                className="h-28 w-28 md:h-32 md:w-32" />
            </div>
        );
    }

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] space-y-8 md:space-y-10 transform-gpu select-none max-w-full overflow-clip pb-16'>

            <div className='flex-col items-center justify-center pt-8 text-center md:py-0 sm:mx-auto'>
                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 md:mb-0 text-3xl sm:text-6xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]"> Discover </div>
            </div>

            <div className='flex items-center justify-center w-full px-8'>
                <video src={discover_sample} alt="AcadSpace Video" className=" w-[90%] rounded-3xl overflow-clip border-4 border-white" controls autoPlay loop muted />
            </div>

            <div className='w-full text-3xl font-semibold text-center text-white underline md:text-5xl underline-offset-8 font-space-grotesk'>Discover <span className='text-[#247FD3] underline'>Careers</span></div>

            <div className='px-4 text-sm font-normal text-center text-white md:px-16 md:text-lg text-pretty font-space-grotesk'>
                Every week, we feature some of our favorite webinars with Subject Experts from different Fields. You can explore below your choice of Topic that interests you.
            </div>

            <div className='px-4 md:px-16'>
                <div className='text-xl font-semibold text-left text-white underline underline-offset-4'>
                    Featured <span className='text-[#247FD3] underline underline-offset-4'>Careers</span>
                </div>

                <div className='mt-2 text-sm font-normal text-left text-white opacity-50 '>
                    Updated Daily
                </div>

                <div className='mt-8'>
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
                        {displayedData.map((item, index) => (
                            <div
                                key={index}
                                className={`flex px-2 py-3 text-white font-space-grotesk cursor-pointer hover:border-[1px] hover:bg-[#FFFFFF]/15 border-white`}
                                onClick={() => handleCareerClick(index, item.title)}
                            >
                                <img src={item.image} alt="" className="w-10 h-10" />
                                <div className='pl-2 space-y-1'>
                                    <div className="text-sm font-semibold leading-tight md:text-base">{item.title}</div>
                                    <div className="text-xs font-light opacity-50 md:text-sm">{item.subtitle}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='flex items-center justify-end md:hidden'>
                        <div className='px-2 text-sm text-[#FAFF01] font-space-mono font-semibold' onClick={handleShowMore}>
                            {showAll ? 'Show Less' : 'Show More'}
                        </div>
                    </div>
                </div>
            </div>

            <div className='px-4 pt-4 text-xl font-semibold text-center text-white md:text-3xl'>
                Upcoming  <span className='text-[#247FD3]'>Webinars</span>
            </div>

            <div className='px-4'>
                <div className='flex overflow-scroll scrollbar-hide'>
                    {webinarData?.map((webinar) => (
                        <div key={webinar._id}>
                            <div className='px-4 rounded-xl w-[80vw] md:w-[30vw] cursor-pointer'>
                                <img
                                    onClick={() => handleNavigate(webinar._id)}
                                    src={webinar.WebinerPic}
                                    alt={webinar.WebinerName}
                                    className="object-contain w-full h-full bg-white aspect-square rounded-xl"
                                />
                                <div className='px-4 text-[10px] font-light text-white flex items-center justify-between mt-4'>
                                    <div className='flex'>
                                        POPULAR . 4.5
                                        <div className='flex items-center justify-center'>
                                            <img src={star} alt="Star" className="inline object-center w-2 h-2 mx-1" />
                                        </div>
                                        (145)
                                    </div>
                                    <div>TOTAL ATTENDEE'S . {webinar.registered}</div>
                                </div>
                            </div>
                            <div className='px-4 mt-2 text-xl font-semibold text-center text-white font-space-grotesk'>
                                {webinar.WebinerName}
                            </div>
                        </div>
                    ))}
                </div>

                <div className='flex items-center justify-center px-4 mt-4'>
                    <div className='w-full px-1 text-right text-[#FAFF01] font-space-grotesk font-semibold' onClick={() => navigate('/discover/webinar')}>
                        Explore More
                    </div>
                    <img src={arrow} alt="" className="object-cover w-10 h-10" />
                </div>

            </div>

            <div className='w-full text-xl font-semibold text-center text-white underline md:text-3xl underline-offset-8 font-space-grotesk'  >Trending <span className='text-[#247FD3] underline'>  Courses & Certifications</span></div>

            <div>
                <div className='flex px-8 space-x-6 overflow-x-scroll scrollbar-hide'>
                    {coursesData.top.map((course) => (
                        <div key={course._id} className='cursor-pointer font-space-grotesk'>
                            <div className='flex w-[300px]'>
                                <div
                                    className='flex-col w-full p-4 bg-white rounded-xl'
                                    onClick={() => navigate(`/discover/courses/${course._id}`, { state: { course } })}
                                >
                                    <img src={course.image} alt="Courses" className="object-contain w-full aspect-square" />

                                    <div className='flex items-center justify-between w-full mt-2'>
                                        <div className='flex items-center justify-center text-xs'>
                                            <img src={person} alt="" className="inline object-center w-3 h-3" /> Acadspace
                                        </div>
                                        <div className='flex text-xs'>
                                            <div className='mr-2 space-x-1'>
                                                <img src={star} alt="" className="inline object-center w-2 h-2" />
                                                <img src={star} alt="" className="inline object-center w-2 h-2" />
                                                <img src={star} alt="" className="inline object-center w-2 h-2" />
                                                <img src={star} alt="" className="inline object-center w-2 h-2" />
                                                <img src={star} alt="" className="inline object-center w-2 h-2" />
                                            </div>
                                            4.5
                                        </div>
                                    </div>

                                    <div className='w-full mt-3 mb-2 text-[#080808] font-semibold min-h-8 font-space-grotesk text-xs'>
                                        {course.courseName}
                                    </div>

                                    <div className='flex items-center justify-between w-full font-medium'>
                                        <div className='flex items-center justify-center text-[10px] md:text-xs'>
                                            <img src={lesson} alt="Courses" className="w-3 h-3 mr-1" />
                                            Lesson : 6
                                        </div>
                                        <div className='flex items-center justify-center text-[10px] md:text-xs'>
                                            <img src={course_student} alt="Courses" className="w-3 h-3 mr-1" />
                                            Student : 198
                                        </div>
                                        <div className='flex items-center justify-center text-[10px] md:text-xs'>
                                            <img src={course_trophy} alt="Courses" className="w-3 h-3 mr-1" />
                                            Beginner
                                        </div>
                                    </div>

                                    <div className='flex items-center justify-between mt-4'>
                                        <div className='flex items-center justify-center p-1 px-3 text-[10px] md:text-sm font-medium text-white bg-black rounded-xl w-fit'>
                                            Start Course <span className='ml-2 text-xl'>{'>'}</span>
                                        </div>
                                        <div className='text-xs font-bold md:text-sm'>
                                            Rs.{course.price}
                                            <span className='pl-1 text-[10px] md:text-xs font-normal line-through'>Rs.40000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='flex items-center justify-center px-4'>
                <div className='w-full px-1 text-right text-[#FAFF01] font-space-grotesk font-semibold' onClick={() => navigate('/discover/courses')}>
                    Explore More
                </div>
                <img src={arrow} alt="" className="object-cover w-10 h-10" />
            </div>


            <div className='relative'>

                <div className='relative z-10 md:h-[720px]'>

                    <div className='flex items-center justify-center w-full h-full'>
                        <div>
                            <div className='relative z-10 px-4 py-4 text-xs font-semibold text-center text-white md:text-3xl font-goldman'>
                                Get access to top Institutes and jobs using <span className='text-[#FAFF01]' >AcadSpace AI</span>, Prebook your slot to get exclusive content
                            </div>

                            <div className='relative z-10 flex items-center justify-center w-full mt-2 md:mt-4'>
                                <div className='flex items-center justify-center w-full max-w-6xl px-4'>
                                    <div className='border-[1px] border-white rounded-xl p-2 md:p-6 md:w-[80%]'>

                                        <div className='flex flex-col border-[#FF6B00] border-[1px] items-center justify-center p-3 space-y-2 bg-white md:p-8 rounded-xl'>

                                            <div className='w-full text-left font-space-grotesk text-xs md:text-lg text-[#1D1050] font-semibold'>Hey There!</div>

                                            <div className='leading-tight text-[10px] md:text-lg text-left font-space-grotesk text-[#1D1050] ' >Thanks for Visiting AcadSpace AI, your Career Pathfinder, Please Prebook your slot below to get exclusive access for <span className='font-semibold' > “Answers tailored to your needs, curated by top 1% professional’s across the Country”</span></div>

                                            <div className='flex items-center justify-start w-full text-[8px] text-left md:text-sm font-space-grotesk'> Also get access to </div>

                                            <div className='grid w-full grid-cols-3 gap-1 text-pretty md:grid-cols-3'>
                                                <div>
                                                    <div className='flex items-center justify-center'>
                                                        <img src={career_library} alt="career_library" className='object-cover w-16 border-[1px] border-black rounded-lg overflow-clip h-12 md:h-24 md:w-36' />
                                                    </div>
                                                    <div className='text-[6px] md:text-xs mt-1'>
                                                        Explore about different Career Paths, Read in detail about top colleges, schools and universities, etc.
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='flex items-center justify-center'>
                                                        <img src={discover} alt="career_library" className='object-cover w-16 border-[1px] border-black rounded-lg overflow-clip h-12 md:h-24 md:w-36' />
                                                    </div>
                                                    <div className='text-[6px] md:text-xs mt-1'>
                                                        Discover live webinars, Courses, Scholarships, top institutes and Many more
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='flex items-center justify-center'>
                                                        <img src={ai} alt="career_library" className='object-cover w-16 border-[1px] border-black rounded-lg overflow-clip h-12 md:h-24 md:w-36' />
                                                    </div>
                                                    <div className='text-[6px] md:text-xs mt-1'>
                                                        Acadspace AI uses your data from advance psychological and Scientific Quizzes here to Determine your Career Path.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='flex items-end justify-end w-full pt-1 md:pt-4'>
                                                <div className='relative' onClick={handleGetStarted}>
                                                    <div className='bg-[#FAFF01] relative z-10 text-xs md:text-xl font-goldman px-4 py-1 rounded-md shadow-md text-[#1D1050]'>Book Now!</div>
                                                    <div className='absolute top-[1px] right-0 w-full h-full bg-[#FAFF01] rounded-md shadow-md'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='absolute top-0 left-0 hidden w-full h-full bg-opacity-30 opacity-20 md:block'>

                    <div className='flex items-center justify-center w-full text-xl font-semibold text-center text-white underline md:text-3xl underline-offset-8 font-space-grotesk'  >Featured  <span className='text-[#247FD3] underline'> Institutes </span></div>

                    <div className='mt-8 blur-sm'>
                        <div className='flex items-center justify-center px-8 space-x-6 overflow-x-scroll scrollbar-hide'>
                            {coursesData.top.map((course) => (
                                <div key={course._id} className='cursor-pointer font-space-grotesk'>
                                    <img src={Ins} alt={course.name} className="w-32 md:w-96" />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='flex items-center justify-center w-full text-xl font-semibold text-left text-white underline md:text-3xl underline-offset-8 font-space-grotesk'  >Featured  <span className='text-[#247FD3] underline'> Jobs </span></div>

                    <div className='mt-8'>
                        <div className='flex items-center justify-center px-8 space-x-6 overflow-x-scroll scrollbar-hide blur-sm'>
                            {coursesData.top.map((course) => (
                                <div key={course._id} className='cursor-pointer font-space-grotesk'>
                                    <img src={job} alt={course.name} className="w-32 md:w-96" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='absolute top-0 left-0 block w-full h-full bg-opacity-30 opacity-20 md:hidden rounded-xl overflow-clip'>
                    <img src={Ins} alt="mobile" className="object-contain w-full h-full rounded-xl overflow-clip" />
                </div>
            </div>

            {/* <div className='flex items-center justify-center w-full px-8'>
                <img src={ai} alt="AcadSpace AI" className="h-64 md:h-96" />
            </div> */}
        </div>
    );
};

export default Discover;
