import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const StartSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        {
            image: "https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fassets%2FCareer-Oppor-1-370x245%205.png?alt=media&token=5397b93e-0843-4e4d-971c-ab9e832e8a3f",
            title: "Read about different Career Options",
            description: "Explore about different Career Paths, Read in detail about top colleges, schools and universities, etc."
        },
        {
            image: "https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fassets%2FCareer-Oppor-1-370x245%204.png?alt=media&token=2b9e34f0-d300-4696-b925-359d24853b90",
            title: "Watch and Learn from Experts",
            description: "Discover live webinars, Courses, Scholarships, top institutes and Many more"
        },
        {
            image: "https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fassets%2FCareer-Oppor-1-370x245%202.png?alt=media&token=01a559ad-243a-48b3-a416-756a528a13c2",
            title: "Ask Questions to AcadSpace AI",
            description: "Acadspace AI uses your data from advance psychological and Scientific Quizzes here to Determine your Career Path."
        },
        {
            image: "https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fassets%2FCareer-Oppor-1-370x245%203.png?alt=media&token=5da79473-dda0-4e12-9c7d-e521a5f703cf",
            title: "Great, Here your change to find the right career Path",
            description: "Get access to top Institutes, Scholarships, jobs using  AcadSpace AI  Prebook your slot to get exclusive content"
        }
    ];

    const start_sliderRef = React.useRef(null);

    const start_settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    };

    const handleNext = () => {
        if (currentSlide < slides.length - 1) {
            start_sliderRef.current.slickNext();
        } else {
            navigate('/book'); // Navigate to the /book route
        }
    };

    const handleSkip = () => {
        start_sliderRef.current.slickGoTo(slides.length - 1);
    };

    const navigate = useNavigate(); // Initialize the navigate function

    return (
        <div className='relative z-10 flex items-center justify-center w-full h-full'>
            <div className='flex items-center justify-center w-full max-w-6xl px-4'>
                <div className='border-[1px] border-white rounded-xl p-2 md:p-6 lg:w-[80%] absolute h-full w-[90%]'>
                    <div className="pt-2 bg-white md:pt-8 rounded-xl">
                        <Slider ref={start_sliderRef} {...start_settings}>
                            {slides.map((slide, index) => (
                                <div key={index} className="w-full h-full p-0 space-y-1 text-center rounded-md">
                                    <div className='flex items-center justify-center h-[100px] md:h-[150px] lg:h-[250px] rounded-xl overflow-clip'>
                                        <img
                                            src={slide.image}
                                            alt={`Slide ${index + 1}`}
                                            className="h-full rounded-xl overflow-clip w-[220px] md:w-auto"
                                        />
                                    </div>
                                    <h2 className="text-[#212121] text-xs md:text-2xl font-bold font-space-grotesk">{slide.title}</h2>
                                    <p className="text-[8px] md:text-lg text-[#757575] font-medium px-8 md:px-36 text-center w-full">{slide.description}</p>
                                </div>
                            ))}
                        </Slider>
                        <div className="flex items-center justify-between w-full p-2 mt-0 md:py-4 md:px-8">
                            {currentSlide < slides.length - 1 ? (
                                <button
                                    onClick={handleSkip}
                                    className="text-xs md:text-xl text-[#757575] rounded-md hover:text-[#5d5d5d] font-bold font-space-grotesk"
                                >
                                    Skip
                                </button>
                            ) : (
                                <div></div> // An empty div to maintain spacing when Skip is hidden
                            )}
                            <button
                                onClick={handleNext}
                                className="md:px-6 md:py-2 px-2 py-1 text-xs md:text-lg text-black font-goldman bg-[#FAFF01] rounded-md hover:bg-[#fbff2d] font-normal ml-auto" // ml-auto pushes the button to the right
                            >
                                {currentSlide < slides.length - 1 ? "Next" : "Book"}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartSlider;
