import { useEffect } from "react";
import blog_profile from '../../assets/blog/blog_user.png';
import blog_linked from '../../assets/blog-linkedin.png';
import internship_acadspace_content from '../../assets/blog/internship_acadspace_content.png';
import blog_internship_3 from '../../assets/blog/blog_internship_3.png';
import internship_acadspace from '../../assets/blog/internship_acadspace.png';
// import blog_profile from '../../assets/edit-user-color-icon.svg';
import { useNavigate } from "react-router-dom";
import ssc_chsl_exam from '../../assets/blog/SSC exam Banner (1).png';
import TimeManagement12thexam from '../../assets/blog/Time-management Banner Surbi (1).jpg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import neet_exam_banner from '../../assets/blog/Neet for Healthcare Students Banner (1).jpeg';

const InternshipBlog = () => {

    useEffect(() => {
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // className: "center",
        // centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const navigate = useNavigate();

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] space-y-8 md:space-y-16 transform-gpu select-none max-w-full overflow-clip pb-16'>

            <div className='flex-col items-center justify-center py-4 text-center md:py-8 sm:mx-auto'>

                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 mb-16 text-3xl sm:text-4xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]"> Unlocking Career Success: The Crucial Role of Internships in Career Development </div>

                <div className="flex flex-col md:flex-row md:px-16 gap-x-16">

                    <div className="flex-col px-8 space-y-8 text-center md:space-y-12 md:px-0">

                        <div className="flex items-center justify-center w-full">
                            <div className="relative flex items-center justify-center w-full rounded-xl">
                                <div className="relative z-10 w-full border-2 border-white rounded-xl overflow-clip">
                                    <img src={internship_acadspace}
                                        alt="" className="w-full" />
                                    <div className="absolute "></div>
                                </div>
                                <div className="absolute w-full h-full border-2 rounded-xl top-2 -right-2 md:-top-3 md:-right-3"></div>
                                <div className="absolute bottom-0 z-20 w-full overflow-clip">
                                    <div className="w-full h-full bg-[#000]/50 border-b-2 border-l-2 space-y-1 border-r-2 rounded-b-xl backdrop-blur-md overflow-clip p-3">
                                        <div className="bg-[#F1ECFF] w-fit flex items-center jus text-[#6C40FF] text-sm rounded-xl py-1 px-2 font-space-grotesk">
                                            <div className="w-3 h-3 bg-[#6C40FF] rounded-full text-xs mr-1"></div>Internships
                                        </div>
                                        <div className="mt-1 text-xs font-semibold text-left text-white md:text-base font-space-grotesk">Unlocking Career Success: The Crucial Role of Internships in Career Development
                                        </div>
                                        <div className="text-sm text-left text-white font-space-grotesk">Oct 19 . 10 min read</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="text-xl font-semibold leading-snug text-center text-white font-space-grotesk md:text-2xl">Launch of our first large scale partnership in East India: AcadSpace & MyCoolQ Spell-A-Thon Olympiads </div>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-2xl font-semibold text-left text-white">
                                Introduction
                            </div>
                            <p className="mb-4 text-lg font-light md:leading-8">
                                AcadSpace inaugurates Personality Coaching & Career Counseling for 1600+ proud parents. We are motivated by such a positive turn-up & feedback from the crowd & looking forward to help the lovely students achieve their full potential! Moving ahead, AcadSpace partners with MyCoolQ as our Career Counseling partners of East India where they will help us cater to 100+ schools in the region in the next 3 years. In today's rapidly evolving world, making career choices can be challenging for young minds. To address this challenge head-on, MyCoolQ is proud to introduce a dedicated career counseling initiative that  will provide our students with the support and tools they need to navigate the exciting journey ahead. Professional Guidance: Our team of experienced and certified career counselors will work closely with students to help them identify their interests, strengths, and goals.
                            </p>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-2xl font-semibold text-left text-white">
                                Background
                            </div>
                            <p className="mb-4 text-lg font-light md:leading-8">
                                Internships serve as a bridge between academic learning and professional work environments.
                                They offer students and recent graduates a taste of real-world challenges and tasks, helping
                                them apply theoretical knowledge practically. This exposure is invaluable in understanding
                                workplace dynamics and expectations.
                            </p>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-2xl font-semibold text-left text-white">
                                Main Strategies for Career Development
                            </div>

                            <p className="mt-8 mb-4 text-lg font-light md:leading-8">
                                <span className="text-[#E4E84E]">1. Gaining Practical Experience**</span> <br />
                                One of the primary benefits of internships is the acquisition of practical experience. Interns
                                engage in real projects, allowing them to develop skills that are not typically covered in
                                academic settings. This experience can set candidates apart in the job market.

                            </p>

                            <p className="mb-4 text-lg font-light md:leading-8">
                                <span className="text-[#E4E84E]">2. Building Professional Networks**</span> <br />
                                Internships provide an excellent platform for networking. Interns meet professionals from their
                                chosen fields, which can lead to mentorship opportunities and valuable industry connections.
                                Networking during internships can open doors to future job opportunities and career advice.
                            </p>

                            <p className="mb-4 text-lg font-light md:leading-8">
                                <span className="text-[#E4E84E]">3. Understanding Industry Expectations**</span> <br />
                                Internships help students and graduates understand what is expected in their chosen industries.
                                They learn about industry-specific tools, methodologies, and standards, which can make the
                                transition from student to professional smoother.
                            </p>

                        </div>

                        <div className="">
                            <img src={internship_acadspace_content} alt="" className="w-full" />
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-2xl font-semibold text-left text-white">
                                Case Studies/Examples
                            </div>

                            <p className="mt-8 mb-4 text-lg font-light md:leading-8">
                                <span className="text-[#E4E84E]">Example 1: Tech Industry Internship**</span> <br />
                                Jane, a computer science graduate, secured an internship at a leading tech company. During
                                her internship, she worked on a major software development project, gaining experience in
                                coding, project management, and teamwork. This practical knowledge, coupled with the
                                professional connections she made, led to a full-time job offer from the same company upon
                                graduation.
                            </p>

                            <p className="mb-4 text-lg font-light md:leading-8">
                                <span className="text-[#E4E84E]">Example 2: Marketing Internship</span> <br />
                                John, a marketing student, interned at a well-known advertising agency. He participated in client
                                meetings, assisted in campaign planning, and managed social media accounts. This hands-on
                                experience provided him with a comprehensive understanding of the marketing industry, helping
                                him land a job as a junior marketing strategist.
                            </p>

                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-2xl font-semibold text-left text-white">
                                Challenges and Solutions
                            </div>

                            <p className="mt-8 mb-4 text-lg font-light md:leading-8">
                                <span className="text-[#E4E84E]">Challenge: Limited Internship Opportunities</span> <br />
                                One common challenge is the limited availability of internships in certain fields. To overcome
                                this, students can look for virtual internships or freelance projects that offer similar experiences.
                                Networking with alumni and attending career fairs can also uncover hidden opportunities.
                            </p>

                            <p className="mb-4 text-lg font-light md:leading-8">
                                <span className="text-[#E4E84E]">Challenge: Unpaid Internship</span> <br />
                                Unpaid internships can be a barrier for some students. While these positions still offer valuable
                                experience, it's important to balance them with part-time jobs or seek internships that offer
                                stipends or other forms of compensation.
                            </p>

                        </div>

                        <div className="">
                            <img src={blog_internship_3} alt="" className="w-full" />
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-2xl font-semibold text-left text-white">
                                Future Trends
                            </div>

                            <p className="mt-8 mb-4 text-lg font-light md:leading-8">
                                <span className="text-[#E4E84E]">Virtual Internships</span> <br />
                                The rise of remote work has led to an increase in virtual internships. These opportunities allow
                                students to gain experience and build networks without geographical constraints. Virtual
                                internships can also help companies access a wider pool of talent.

                            </p>

                            <p className="mb-4 text-lg font-light md:leading-8">
                                <span className="text-[#E4E84E]">Diverse Skill Sets</span> <br />
                                Future internships are likely to focus on developing a diverse set of skills, including digital
                                literacy, data analysis, and project management. These skills are increasingly important in a
                                rapidly evolving job market.
                            </p>

                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-2xl font-semibold text-left text-white">
                                Conclusion
                            </div>

                            <p className="mt-8 mb-4 text-lg font-light md:leading-8">
                                Internships play a critical role in career development, offering practical experience, networking
                                opportunities, and industry insights. By understanding the importance of internships and actively
                                seeking out these opportunities, students and recent graduates can enhance their career
                                prospects and navigate the job market with confidence.
                            </p>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-2xl font-semibold text-left text-white">
                                References
                            </div>

                            <p className="mt-8 mb-4 text-lg font-light md:leading-8">
                                National Association of Colleges and Employers (NACE). "The Value of Internships for Career
                                Development." NACEweb.org
                                Forbes. "Why Internships Are More Important Than Ever." Forbes.com”
                                Harvard Business Review. "How to Get the Most Out of an Internship." HBR.org”
                                The Balance Careers. "How Internships Benefit Your Career." TheBalanceCareers.com”
                                Journal of Education and Work. "The Impact of Internships on Graduate Employment in the UK."
                                Taylor & Francis Online”
                                Inside Higher Ed. "Internships and Their Impact on Graduate Outcomes." InsideHigherEd.com”
                            </p>
                        </div>


                    </div>

                    <div className="w-full max-w-md px-8 mt-8 shrink-0">
                        <div className="w-full bg-[#2D1E6B] p-4 md:p-8 rounded-xl border-white border-[1px]">
                            <div className="space-y-2">
                                <div className="flex items-end justify-start overflow-clip gap-x-4">
                                    <img src={blog_profile} alt="" className="w-16 rounded-lg md:w-32" />
                                    <img src={blog_linked} alt="" className="w-6 h-6 rounded-lg md:h-8 md:w-8" />
                                </div>
                                <div className="text-base font-semibold text-left text-white md:text-xl font-space-grotesk">Manish Shivangi</div>
                                <div className="w-full h-[1px] bg-white"></div>
                                <div className="text-sm text-left text-white font-space-grotesk">
                                    Manish Shivangi is a content writer specializing in career development and education. With a
                                    background in creating engaging blog content, Manish aims to provide valuable insights to help
                                    students and professionals achieve their career goals.
                                </div>
                            </div>
                        </div>

                        {/* <div className="mt-8 font-semibold text-left text-white md:text-lg font-space-grotesk">
                            In this article
                            <div className="pt-4 space-y-2">
                                <div className="py-1 pl-3 border-l-2 border-[#3D22CF] cursor-pointer">Exploring Generative AI in Content Creation </div>
                                <div className="py-1 pl-3 cursor-pointer">Afterword: The AI Behind This Article</div>
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>

            <div className="flex-col w-full">

                <div className="text-3xl md:text-4xl text-[#FAFF01] mb-4 w-full font-semibold font-goldman text-center">
                    Recommended Blogs
                </div>

                <div className='relative flex items-center justify-center px-8 space-x-12 overflow-scroll scrollbar-hide h-[500px]'>

                    <div className="absolute top-0 w-[470px] md:w-[1340px] h-full">

                        <Slider {...settings} className="px-4">

                            <div key={1} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 rounded-xl w-80 h-96'>
                                <img src={'https://acadspace.org/img/updates/MyCoolQ.png'} alt="blog" className="object-contain object-center rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    First Large Scale Partnership in East India
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    AcadSpace inaugurates Personality Coaching & Career Counseling for 1600+ proud parents. We are motivated by such a positive turn-up & feedback…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/partner-acadspace')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={2} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 rounded-xl w-80 h-96'>
                                <img src={internship_acadspace} alt="blog" className="object-contain object-center rounded-xl" />
                                <div className='font-bold text-left text-white '>
                                    <div className="bg-[#F1ECFF] w-fit flex items-center justify-center text-[#6C40FF] text-sm font-normal rounded-xl py-1 px-2 mt-2 font-space-grotesk">
                                        <div className="w-3 h-3 bg-[#6C40FF] rounded-full font-light text-[8px] mr-1"></div>Internships
                                    </div>
                                    <div className="flex items-center justify-between mt-2">
                                        <div className="flex font-medium text-[#97989F] text-base">
                                            <img src={blog_profile} alt="blog" className="w-4 mr-1 rounded-lg md:w-6" /> Manish Shivangi
                                        </div>
                                        <div className="flex font-medium text-[#97989F] text-sm">
                                            July 31st 2024
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        Unlocking Career Success: The Crucial Role of Internships in Career Development
                                    </div>
                                </div>
                                <div className='flex items-center justify-end w-full -mt-2 cursor-pointer' onClick={() => navigate('/blog/internship')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={3} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                                <img src={ssc_chsl_exam} alt="blog" className="object-contain object-center rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    What is the SSC CGL Exam? Everything You Need to Know
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    The SSC CGL (Staff Selection Commission Combined Higher Secondary Level) exam is a crucial opportunity for those looking to secure government jobs…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/ssc-cgl')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={4} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                                <img src={TimeManagement12thexam} alt="blog" className="object-cover object-center min-h-48 rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    How To Manage Time During Class 12th Exams?
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    Learn how to do time management for Class 12th exams with tips on creating a study plan, balancing study and rest, and reducing stress…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/12th')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={5} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                                <img src={neet_exam_banner} alt="blog" className="object-cover object-center min-h-48 rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    NEET: Medical Exam for Students
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    The NEET (National Eligibility cum Entrance Test) is an Indian nationwide entrance examination conducted by the National Testing Agency (NTA) for admission to undergraduate medical programs…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/neet')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                        </Slider>

                    </div>

                </div>


            </div>

        </div >
    );
};

export default InternshipBlog;