import React from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/course_details_logo.png';
import rate from '../../assets/course_details_rate_review.png';
import course_details_supervisor from '../../assets/course_details_supervisor.png';
import review_sample from '../../assets/review-smaple.png';
import course_student from '../../assets/course_details_student.png';
import course_trophy from '../../assets/course_details_trophy.png';
import course_list from '../../assets/course_list.png';
import course_lectures from '../../assets/course_lectures.png';
import course_live from '../../assets/course_live.png';
import course_language from '../../assets/course_language.png';
import course_details_sample1 from '../../assets/course_details_sample1.png';

const CourseDetails = () => {
    const location = useLocation();
    const { course } = location.state || {};

    console.log(course);

    if (!course) {
        return <div>Course not found</div>;
    }

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] space-y-8 md:space-y-16 transform-gpu select-none max-w-full overflow-clip'>
            <div className='flex-col items-center justify-center text-center sm:mx-auto'>
                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 md:mb-16 text-3xl sm:text-6xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]">
                    Discover
                </div>
            </div>

            <div className='px-4'>
                <div className='flex items-center justify-start p-3 font-semibold text-white font-space-grotesk rounded-xl bg-white/10'>
                    <div className=''>Courses</div>
                    <span className='mx-3 text-2xl font-bold'>{'>'}</span>
                    <div className='font-space-grotesk'>{course.courseName}</div>
                </div>
            </div>

            <div className='px-4'>
                <div className='p-6 text-white rounded-lg shadow-lg'>
                    <div className='flex flex-col items-center justify-between md:justify-start md:items-start md:space-x-8 md:flex-row'>

                        <div className='w-full md:w-2/3'>
                            <img src={course.image} alt={course.courseName} className='w-full rounded-lg max-h-svh ' />

                            <h2 className='py-4 text-2xl font-bold'>{course.courseName}</h2>

                            <div className='flex justify-between font-bold text-white border-b-[1px] py-2 border-white'>
                                <div>
                                    <img src={logo} alt={course.courseName} className='rounded-lg w-36 ' />
                                </div>
                                <div className='flex items-center justify-center text-sm md:text-base'>
                                    <img src={rate} alt={course.courseName} className='mr-1 rounded-lg h-7' /><span>2.3K</span>
                                    <img src={course_details_supervisor} alt={course.courseName} className='ml-2 mr-1 rounded-lg h-7' /><span>1.4K</span>
                                </div>
                            </div>

                            <div className='py-2 mt-2 font-semibold text-white font-space-grotesk'>About Course</div>
                            <p className='text-xs font-normal leading-relaxed text-white border-b-[1px] border-white pb-3'>{course.courseDescription}</p>

                            <div className='py-3 font-semibold'>
                                Review
                            </div>

                            <div className='flex items-center justify-center space-x-4'>
                                <img src={review_sample} alt={course.courseName} className='w-12 rounded-lg' />
                                <div className='space-y-1 text-xs'>
                                    <div className='text-sm font-semibold'>Ravi Raj</div>
                                    <div>I loved the course, it had been a long time since I had experimented with watercolors and now I will do it more often thanks to Kitani Studio</div>
                                </div>
                            </div>
                        </div>

                        <div className='w-full md:w-1/3'>
                            <div className='bg-[#2D1E6B] mt-8 md:mt-0 p-8'>
                                <div className='flex items-center justify-between text-sm'>
                                    <div className='font-semibold text-white'>{course.courseName}</div>
                                    <div className='bg-[#A04AE3] p-2'>20% off</div>
                                </div>
                                <div className='flex items-center justify-start w-full mt-2 space-x-4'>
                                    <div className='flex items-center justify-center text-[10px]'>
                                        <img src={course_student} alt="Courses" className="w-3 h-3 mr-1" />
                                        Student : 198
                                    </div>
                                    <div className='flex items-center justify-center text-[10px]'>
                                        <img src={course_trophy} alt="Courses" className="w-3 h-3 mr-1" />
                                        Beginner
                                    </div>
                                </div>
                                <div className='mt-2 space-x-2 font-semibold font-space-grotesk'>
                                    <span className='text-[#E6EA48] font-semibold'>{course.price}</span>
                                    <span className='text-[#E6EA48] line-through font-light'>Rs.40000</span>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <div className='bg-[#E6EA48] mt-3 font-goldman text-black text-center py-3 w-fit px-10 rounded-lg'>
                                        Enroll Now !
                                    </div>
                                </div>
                                <div className='mt-4 space-y-2 text-xs'>
                                    <div className='flex items-center justify-start space-x-4'>
                                        <img src={course_list} className='w-8 rounded-lg ' />
                                        <div className='flex items-center justify-center font-light'>
                                            22 Section
                                        </div>
                                    </div>
                                    <div className='flex items-center justify-start space-x-4'>
                                        <img src={course_lectures} className='w-8 rounded-lg ' />
                                        <div className='flex items-center justify-center font-light'>
                                            152 Lectures
                                        </div>
                                    </div>
                                    <div className='flex items-center justify-start space-x-4'>
                                        <img src={course_live} className='w-8 rounded-lg ' />
                                        <div className='flex items-center justify-center font-light'>
                                            21h 33m total length
                                        </div>
                                    </div>
                                    <div className='flex items-center justify-start space-x-4'>
                                        <img src={course_language} className='w-8 rounded-lg ' />
                                        <div className='flex items-center justify-center font-light'>
                                            English
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='relative mt-4'>
                                <img src={course_details_sample1} alt="Courses" className="relative w-full" />
                                <div className='absolute bottom-0 flex items-end justify-end w-full h-full px-12 pb-4'>
                                    <div className='font-space-grotesk'>
                                        <div className='bg-[#6100FF] p-1 w-fit text-[10px] font-bold'> WEBINAR</div>
                                        <div className='text-[#F9F9F9]/90 text-xs'>Rajeev Agasthi</div>
                                        <div className='w-full mt-1 text-sm font-semibold text-white'>Masterclass in Design Thinking, Innovation & Creativity</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default CourseDetails;
