import { useEffect } from "react";
import blog_hero from '../assets/blog_hero.png';
import insta from '../assets/insta2.png';
import fb from '../assets/fb2.png';
import twitter from '../assets/twitter2.png';
import linkedin from '../assets/linkedin2.png';
import vikrant from '../assets/team/vikrant.png';
import Jinal from '../assets/team/jinal.png';
import Hitesh from '../assets/team/hitesh.png';

const AboutUs = () => {

    useEffect(() => {
    }, []);

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] space-y-0 transform-gpu select-none max-w-full overflow-clip pb-16'>

            <div className='flex-col items-center justify-center py-4 text-center md:py-0 sm:mx-auto '>
                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 text-3xl sm:text-6xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]"> About Us? </div>

                <div className="flex flex-wrap-reverse items-center justify-around w-full px-8 md:px-16 md:gap-y-8 overflow-clip">
                    <div className="block md:hidden text-base md:text-2xl text-left text-white font-goldman md:w-[60%]">We are on a Mission to impact over 340 million students seeking Career Guidance With AcadSpace Ai Engine, We envision an ecosystem which can help students get bias-free Recommendations through AI where one can follow the path he/she is destined to take.  </div>
                    <div className="hidden md:block text-2xl leading-[40px] text-white md:w-[50%] font-goldman text-left">We are on a Mission to impact over 340 million students seeking Career Guidance With AcadSpace Ai Engine, We envision an ecosystem which can help students get bias-free Recommendations through AI where one can follow the path he/she is destined to take.  </div>
                    <img src={blog_hero} alt="" className="md:w-[40%] w-[180vw] object-cover md:scale-[117%]" />
                </div>
            </div>

            <div className="bg-[#FAFF01] flex flex-col md:flex-row w-full items-center justify-around">
                <div className="flex-col items-center justify-center py-4 text-center">
                    <div className="font-space-mono text-[#2D1E6B] font-semibold text-5xl italic">
                        108903
                    </div>
                    <div className="font-space-mono text-[#2D1E6B]/65 font-semibold italic text-sm mt-1">
                        STUDENTS FOUND DREAM CAREER
                    </div>
                </div>
                <div className="flex-col items-center justify-center py-4 text-center">
                    <div className="font-space-mono text-[#2D1E6B] font-semibold text-5xl italic">
                        910
                    </div>
                    <div className="font-space-mono text-[#2D1E6B]/65 font-semibold italic text-sm mt-1">
                        INSTITUTES TRUST US
                    </div>
                </div>
                <div className="flex-col items-center justify-center py-4 text-center">
                    <div className="font-space-mono text-[#2D1E6B] font-semibold text-5xl italic">
                        120
                    </div>
                    <div className="font-space-mono text-[#2D1E6B]/65 font-semibold italic text-sm mt-1">
                        COUNSELLERS TRAINED
                    </div>
                </div>
            </div>

            <div className="flex-col w-full py-16 space-y-12">
                <div className="text-3xl md:text-4xl text-[#FAFF01] underline w-full font-semibold font-goldman text-center">
                    Our Team
                </div>
                <div className="flex items-center justify-center">
                    <div className="relative">
                        <div className="relative shadow-about-us-multi z-10 left-0 flex items-center scrollbar-hide w-[340px] md:w-auto px-6 md:px-20 py-16 overflow-scroll border-4 rounded-xl bg-custom-gradient-1 gap-x-16">

                            <div className="p-2 border-2 border-white border-dashed rounded-xl">
                                <div className="relative flex-col shrink-0 bg-white w-[270px] h-[340px] md:w-[280px] md:h-[330px] rounded-xl p-4">
                                    <div className="flex items-center justify-between space-x-2">
                                        <div className="font-bold leading-tight font-space-grotesk shrink-0">Vikrant Shome <br /> Founder (CEO)</div>
                                        <img src={vikrant} alt="" className="w-16 h-20 bg-gray-300" />
                                    </div>
                                    <div className="mt-2 text-sm font-semibold leading-relaxed font-space-grotesk">
                                        Vikrant is a dedicated Career Tech entrepreneur and an alumnus of
                                        IIM Indore and NIT Durgapur. His background also includes roles at renowned
                                        organizations like Asian Paints and Red Seer.
                                    </div>
                                    <div className="absolute flex items-end justify-end w-full pt-4 bottom-4 right-4 gap-x-3">
                                        {/* <img src={insta} alt="Instagram" className="w-7" />
                                        <img src={fb} alt="Facebook" className="w-7" />
                                        <img src={twitter} alt="Twitter" className="w-7" /> */}
                                        <a href="https://www.linkedin.com/in/vikrant-shome/" target="_blank" rel="noopener noreferrer">
                                            <img src={linkedin} alt="LinkedIn" className="w-7 h-7" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="p-2 border-2 border-white border-dashed rounded-xl">
                                <div className="relative flex-col shrink-0 bg-white w-[270px] h-[340px] md:w-[280px] md:h-[330px] rounded-xl p-4">
                                    <div className="flex items-center justify-between space-x-2">
                                        <div className="font-bold leading-tight font-space-grotesk shrink-0">Jinal Trivedi - <br />CoFounder (CSO)</div>
                                        <img src={Jinal} alt="" className="w-16 h-20 bg-gray-300" />
                                    </div>
                                    <div className="mt-2 text-sm font-semibold leading-relaxed font-space-grotesk">
                                        With over 9 years of education and Fin-tech experience, holds PG from Harvard Summer School and IIM Indore in Management and Business. She previously served as Vice President of Customer Success at Finance-peer (now Leo1), leading a team of 100.
                                    </div>
                                    <div className="absolute flex items-center justify-end w-full pt-4 right-4 bottom-4 gap-x-3">
                                        {/* <img src={insta} alt="Instagram" className="w-7" />
                                        <img src={fb} alt="Facebook" className="w-7" />
                                        <img src={twitter} alt="Twitter" className="w-7" /> */}
                                        <a href="https://www.linkedin.com/in/jinal-trivedi-2408/" target="_blank" rel="noopener noreferrer">
                                            <img src={linkedin} alt="LinkedIn" className="w-7 h-7" />
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div className="p-2 border-2 border-white border-dashed rounded-xl">
                                <div className="relative flex-col shrink-0 bg-white w-[270px] h-[340px] md:w-[280px] md:h-[330px] rounded-xl p-4">
                                    <div className="flex items-center justify-between space-x-2">
                                        <div className="font-bold leading-tight font-space-grotesk shrink-0"> Hitesh Diwani - <br /> Head Counsellor <br /> (COO)</div>
                                        <img src={Hitesh} alt="" className="w-16 h-20 bg-gray-300" />
                                    </div>
                                    <div className="mt-4 text-sm font-semibold leading-relaxed font-space-grotesk">
                                        15+ years experience in Counseling & he is Central India’s most renowned counselor with 20,000+ completed learners counseling sessions in his entire career.
                                    </div>
                                    <div className="absolute flex items-center justify-end w-full pt-4 right-4 bottom-4 gap-x-3">
                                        {/* <img src={insta} alt="Instagram" className="w-7" />
                                        <img src={fb} alt="Facebook" className="w-7" />
                                        <img src={twitter} alt="Twitter" className="w-7" /> */}
                                        <a href="https://www.linkedin.com/in/hiteshdewani/" target="_blank" rel="noopener noreferrer">
                                            <img src={linkedin} alt="LinkedIn" className="w-7 h-7" />
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="absolute flex w-full h-full px-20 py-16 border-4 rounded-xl bg-custom-gradient-1 left-3 -top-3"></div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AboutUs;
