import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import webinar from '../../assets/discover_webinar.png';
import calander from '../../assets/calander.png';
import zoom from '../../assets/zoom.png';
import { BASE_URL } from '../../components/common/AppConfig';

const WebinarDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { webinarId } = location.state || {};

    const [webinarDetails, setWebinarDetails] = useState(null);

    useEffect(() => {
        if (webinarId) {
            const fetchWebinarDetails = async () => {
                try {
                    const response = await axios.post(`${BASE_URL}/web/webinarDetails`, {
                        id: webinarId
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    setWebinarDetails(response.data.details);
                } catch (error) {
                    console.error('Error fetching webinar details:', error);
                }
            };

            fetchWebinarDetails();
        }
    }, [webinarId]);

    const formatTime = (time) => {
        const [hour, minute] = time.split(':');
        const date = new Date();
        date.setHours(hour, minute);

        return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    };

    if (!webinarId) {
        return <div>Webinar not found</div>;
    }

    if (!webinarDetails) {
        return (
            <div className="flex items-center justify-center h-[88vh] bg-[#1D1050]">
                <img src={'https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fgiphy-unscreen.gif?alt=media&token=788057b2-0081-4d8c-88e1-5926052daa5e'} alt="Loading..."
                    className="h-28 w-28 md:h-32 md:w-32" />
            </div>
        );
    }

    const isEventPast = (eventDate) => {
        if (!eventDate) return false; // Default to not past if no date is provided
        const today = new Date();
        const event = new Date(eventDate);
        return event < today;
    };

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] space-y-8 md:space-y-12 transform-gpu select-none max-w-full overflow-clip pb-16'>

            <div className='flex-col items-center justify-center pt-8 text-center sm:mx-auto'>
                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 text-3xl sm:text-6xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]"> Upcoming Webinars </div>
            </div>

            <div className='text-[#6C7880] px-4 font-medium font-space-grotesk'>
                <span className='cursor-pointer ' onClick={() => navigate('/')} >Home</span> {'>'}
                <span className='cursor-pointer' onClick={() => navigate('/discover')} > Discover</span> {'>'}
                <span className='cursor-pointer ' onClick={() => navigate('/discover/webinar')}> Webinar</span> {'>'}
                <span className='text-[#247FD3] cursor-pointer' onClick={() => navigate('/discover/webinar-details')}> Upcoming Webinars</span>
            </div>

            <div className='flex flex-col items-center justify-center space-y-12 md:space-y-0 md:flex-row md:items-start md:justify-start'>

                <div className='space-y-8 md:space-y-12'>
                    <div className='flex items-center justify-center w-full px-8'>
                        <img src={webinarDetails.WebinerPic || webinar} alt={webinarDetails.WebinerName} className="w-full h-auto max-w-sm md:w-[500px]" />
                    </div>
                </div>

                <div className='space-y-8 md:space-y-8'>

                    <div className='px-4 font-semibold text-center text-white md:px-0 font-space-grotesk md:text-3xl'>
                        {webinarDetails.WebinerName || 'Who Should Pursue a Career in Software & Technology?'}
                    </div>

                    <div className='px-8 space-y-2'>
                        <div className='flex items-center justify-start px-4'>
                            <img src={calander} alt="Calendar" className="w-16 h-16 -ml-3 " />
                            <div className='text-xs font-normal text-white md:text-sm font-space-grotesk'>
                                <div>{webinarDetails.Date ? new Date(webinarDetails.Date).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' }) : 'Friday, July 26'}</div>
                                <div>
                                    {webinarDetails.StartTime && webinarDetails.EndTime
                                        ? `${formatTime(webinarDetails.StartTime)} - ${formatTime(webinarDetails.EndTime)} GMT+5:30`
                                        : '6:30 PM - 7:30 PM GMT+5:30'}
                                </div>

                            </div>
                        </div>

                        <div className='flex items-center justify-start px-4'>
                            <img src={zoom} alt="Zoom" className="w-10 h-10" />
                            <div className='ml-3 text-xs font-light text-white md:text-sm font-space-grotesk'>
                                <div>Zoom</div>
                            </div>
                        </div>
                    </div>

                    <div className='px-8 space-y-2 md:px-12'>
                        <div className='text-[10px] md:text-xs text-white opacity-50 font-space-grotesk'>
                            {isEventPast(webinarDetails.Date) ? "Event Ended!" : "Registration!"}
                        </div>
                        <div className='bg-[#372972] py-4 px-2 space-y-3 rounded-md md:rounded-xl'>
                            <div className='text-[11px] md:text-base text-center text-white'>
                                {isEventPast(webinarDetails.Date) ? "This event has ended." : "Welcome! To join the event, please register below."}
                            </div>
                            <div className='flex items-center justify-center' onClick={() => navigate('/book')}>
                                <div className='bg-[#FAFF01] rounded-md md:text-lg text-center font-goldman shadow-custom-login-2 mx-4 w-fit py-1 px-4 md:py-1 md:px-12'>
                                    {isEventPast(webinarDetails.Date) ? "Register for Future Events" : "Register!"}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='px-8 text-xs font-light leading-loose text-justify text-white md:text-base'>
                        {webinarDetails.description || 'The webinar aims to guide students and parents to provide insights into the software and technology industry, helping attendees determine if a career in this field is the right path for them. We will discuss the necessary skills, personality traits, educational background, and career opportunities in the software and technology sector.'}
                    </div>

                    <div className='px-8 text-xs font-light leading-loose text-justify text-white md:text-base'>
                        {webinarDetails.additionalInfo || 'Join our exclusive session with the expert team from AcadSpace where senior faculties and industrialists, graduates from IIM Calcutta, & ISB will share practical insights and strategies to help you thrive, no matter where you are.'}
                    </div>

                    <div className='px-8 text-sm font-semibold text-white font-space-grotesk'>Key Take away</div>

                    <div className='px-8 text-xs text-white md:text-base font-space-grotesk'>📅 Date: {webinarDetails.Date ? new Date(webinarDetails.Date).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' }) : 'Friday, July 26'}</div>

                    <div className='px-8 text-xs text-white md:text-base font-space-grotesk'>⏰ Time:  {webinarDetails.StartTime && webinarDetails.EndTime
                        ? `${formatTime(webinarDetails.StartTime)} - ${formatTime(webinarDetails.EndTime)} GMT+5:30`
                        : '6:30 PM - 7:30 PM GMT+5:30'}</div>

                    {/* <div className='px-8 text-xs text-white md:text-base font-space-grotesk'>✨ Special Gift:🎁 Attendees will receive our exclusive "Handbook to College Success" – your ultimate guide to navigating the college years successfully.</div> */}

                    <div className='px-8 text-xs text-white md:text-base font-space-grotesk'>You will learn to:</div>

                    <div className='px-8 text-xs text-white md:text-base font-space-grotesk'>- 👩‍🎓 Analysis of undergraduate options and education pathway</div>

                    <div className='px-8 text-xs text-white md:text-base font-space-grotesk'>- ✅ Insights on careers within software & technology</div>

                    <div className='px-8 text-xs text-white md:text-base font-space-grotesk'>- 🏫 How to boost your profile and skills, and keep up with emerging trends</div>

                    <div className='px-8 text-xs text-white md:text-base font-space-grotesk'>- ✅ Tips to secure internships, jobs</div>

                    <div className='px-8 text-xs text-white md:text-base font-space-grotesk'>- 🏫 Q&A</div>

                    <div className='px-8 text-xs text-white md:text-base font-space-grotesk'>AcadSpace is dedicated to providing the structured support how to choose right college and course!</div>

                    <div className='px-8 text-xs text-white md:text-base font-space-grotesk'>We can’t wait to help you kickstart your journey to success!</div>

                </div>

            </div>
        </div>
    );
};

export default WebinarDetails;
