import './App.css';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import PartnerAcadspace from './pages/Blogs/PartnerAcadspace';
import ContactUs from './pages/ContactUs';
import Discover from './pages/Discover/Discover';
import Booking from './pages/Booking';
import Webinar from './pages/Discover/Webinar';
import WebinarDetails from './pages/Discover/WebinarDetails';
import Courses from './pages/Courses/Courses';
import CourseDetails from './pages/Courses/CourseDetails';
import { Fragment, useEffect } from 'react';
import InternshipBlog from './pages/Blogs/InternshipBlog';
import SSCCGLBlog from './pages/Blogs/SSCCGL';
import TimeManagementBlog from './pages/Blogs/TimeManagement12thexam';
import NEETBlog from './pages/Blogs/NeetBlog';
import CareerDetails from './pages/Careers/Career';
import CareerSubStream from './pages/Careers/CareerSubStream';

function App() {
  const location = useLocation();
  const showHeader = location.pathname !== '/login' && location.pathname !== '/book';
  const showFooter = location.pathname !== '/login' && location.pathname !== '/book';

  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, [location]);

  return (
    <div className='antialiased overscroll-none'>
      {showHeader && <Header />}
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/login" element={<Booking />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/discover" element={<Discover />} />
        <Route path="/discover/webinar" element={<Webinar />} />
        <Route path="/book" element={<Booking />} />
        <Route path="/discover/webinar-details" element={<WebinarDetails />} />
        {/* <Route path="/discover/webinar-details/:id" element={<WebinarDetails />} /> */}
        <Route path="/discover/courses" element={<Courses />} />
        <Route path="/discover/courses/:id" element={<CourseDetails />} />
        <Route path="/discover/career" element={<CareerDetails />} />
        <Route path="/discover/careersubstream" element={<CareerSubStream />} />

        //blogs
        <Route path="/blog/partner-acadspace" element={<PartnerAcadspace />} />
        <Route path="/blog/internship" element={<InternshipBlog />} />
        <Route path="/blog/ssc-cgl" element={<SSCCGLBlog />} />
        <Route path="/blog/12th" element={<TimeManagementBlog />} />
        <Route path="/blog/neet" element={<NEETBlog />} />
      </Routes>
      {showFooter && <Footer />}

    </div>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
