import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import blog_profile from '../../assets/blog/blog_user.png';
import blog_linked from '../../assets/blog-linkedin.png';
import internship_acadspace from '../../assets/blog/internship_acadspace.png';
import TimeManagement12thexam from '../../assets/blog/Time-management Banner Surbi (1).jpg';
import ssc_chsl_exam from '../../assets/blog/SSC exam Banner (1).png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import neet_exam_banner from '../../assets/blog/Neet for Healthcare Students Banner (1).jpeg';

const TimeManagementBlog = () => {

    useEffect(() => {
    }, []);

    const navigate = useNavigate();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        // className: "center",
        // centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] space-y-8 md:space-y-16 transform-gpu select-none max-w-full overflow-clip'>

            <div className='flex-col items-center justify-center py-4 text-center md:py-8 sm:mx-auto'>

                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 mb-16 text-3xl sm:text-4xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]"> How To Manage Time During Class 12th Exams? </div>

                <div className="flex flex-col md:flex-row md:px-16 gap-x-16">

                    <div className="flex-col px-8 space-y-8 text-center md:space-y-12 md:px-0">

                        <div className="flex items-center justify-center w-full">
                            <div className="relative flex items-center justify-center w-full rounded-xl">
                                <div className="relative z-10 w-full border-2 border-white rounded-xl overflow-clip">
                                    <img src={TimeManagement12thexam}
                                        alt="" className="w-full min-h-60 md:min-h-[500px]" />
                                    <div className="absolute "></div>
                                </div>
                                <div className="absolute w-full h-full border-2 rounded-xl top-2 -right-2 md:-top-3 md:-right-3"></div>
                                <div className="absolute bottom-0 z-20 w-full overflow-clip">
                                    <div className="w-full h-full bg-[#000]/50 border-b-2 border-l-2 space-y-1 border-r-2 rounded-b-xl backdrop-blur-md overflow-clip p-3">
                                        <div className="bg-[#F1ECFF] w-fit flex items-center justify-center text-[#6C40FF] text-sm rounded-xl py-1 px-2 font-space-grotesk">
                                            <div className="w-3 h-3 bg-[#6C40FF] rounded-full text-xs mr-1"></div>12th Exams
                                        </div>
                                        <div className="mt-1 text-xs font-semibold text-left text-white md:text-base font-space-grotesk">How To Manage Time During Class 12th Exams?
                                        </div>
                                        <div className="text-sm text-left text-white font-space-grotesk">Oct 19 . 10 min read</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="md:leading-7 text-base text-justify font-light text-[#FFFCFC] font-space-grotesk text-[10px] ">
                            <p className="mb-4">
                                The Class 12th examinations are a milestone in the student’s educational journey that distinguishes high school life from higher education or work life. The burden to excel is overwhelming; this often results in stress and anxiety. At this juncture, one of the most critical skills to acquire is effective time management. Appropriate time management practices can improve your productivity, relieve tension, and prepare you for every subject.
                            </p>
                            <p className="mb-4">
                                Therefore, this blog will look at practical strategies to efficiently manage your time during class 12th exams to maintain a balance between study, rest, and play and ultimately attain your academic goals.
                            </p>
                        </div>

                        <div>
                            <div className="text-xl font-semibold leading-snug text-center text-white font-space-grotesk md:text-2xl">Understanding the Importance of Time Management in Class 12th</div>
                        </div>

                        <div className="md:leading-7 text-base text-justify font-light text-[#FFFCFC] font-space-grotesk ">
                            <p className="mb-4">
                                Time management is crucial for Class 12th students, significantly impacting exam success and overall well-being. Here’s why effective time management matters:
                            </p>
                            <ul className="list-disc list-inside">
                                <li className="mb-2">Maximizing Productivity: Time management is important for class twelfth aspirants because it highly affects their examination grades and general welfare. This is how good time management can result in better academic performance:</li>
                                <li className="mb-2">Reducing Stress and Anxiety: So when you have a well-thought-out timetable for your studies, then examination stress decreases, improving your mental health. Being able to know what to read and when one is not likely to get overwhelmed.</li>
                                <li className="mb-2">Improving Focus and Concentration: There are fewer chances that you will lose concentration or get distracted during study hours once there is a plan of action. Thus, allocating specific periods for each topic helps maintain focus and retain information.</li>
                                <li className="mb-2">Enhancing Academic Performance: Regular study with the organization makes concepts easier to understand and remember. By managing time properly, one can cover the syllabus consistently, practice with previous papers, and clarify doubts, thus boosting test results.</li>
                                <li className="mb-2">Balancing Study and Rest: Effective time management includes breaks so that burnout does not occur while still enabling the healthiness of the body. This equilibrium keeps motivation rolling on and, at the same time, ensures the well-being of an individual.</li>
                                <li className="mb-2">Building Confidence: However, following proper scheduling also leads to improved performance during exams by instilling confidence, which in turn enables coping with challenging subjects.</li>
                                <li className="mb-2">Developing Life Skills: In addition to this, these skills gained in present times would be very useful while studying further education, starting work, or even engaging in some personal projects; therefore, they form a strong base for future life success.</li>
                            </ul>
                        </div>

                        <div>
                            <div className="text-xl font-semibold leading-snug text-center text-white font-space-grotesk md:text-2xl">Time Management Strategy for Class 12th Board Exams</div>
                        </div>

                        <div className="md:leading-7 text-base text-justify font-light text-[#FFFCFC] font-space-grotesk ">
                            <p className="mb-4">
                                For board exams, time management is important among students in class 12. Developing a strategy enables the student to have organized study sessions, maximize output, and cover the whole syllabus. Here’s how you do it;
                            </p>
                            <ul className="list-disc list-inside">
                                <li className="mb-2">Set Clear Goals: Define academic goals for each subject and break down the syllabus into manageable units with specific targets.</li>
                                <li className="mb-2">Create a Study Schedule: Develop daily and weekly schedules. Allocate specific time slots for each subject, balancing study time, incorporating revisions, practice tests, and rest days.</li>
                                <li className="mb-2">Prioritize Your Subjects: Focus on challenging subjects and balance study time between difficult and easy subjects for steady progress.</li>
                                <li className="mb-2">Incorporate Regular Breaks: Use the Pomodoro Technique: study for 25-30 minutes, take a 5-minute break, and after 2 hours, take a longer break.</li>
                                <li className="mb-2">Use Study Tools and Resources: Utilize study guides, online resources, and educational apps. Create mind maps, flashcards, and summary notes for quick revision.</li>
                                <li className="mb-2">Practice Past Papers and Sample Tests: Regularly practice past exam papers to familiarize yourself with the format and improve speed and accuracy.</li>
                                <li className="mb-2">Revise Regularly: Schedule daily and weekly revision sessions to reinforce learning; concentrated revision in the final weeks is essential because of its intensity.</li>
                                <li className="mb-2">Stay Healthy And Rested: Get enough sleep (7-8 hrs), eat a balanced diet, keep hydrated, and exercise regularly to boost your immunity against stress caused by starting school.</li>
                                <li className="mb-2">Avoid Procrastination: Avoid distractions by disabling notifications while studying somewhere you will not be disturbed. Use a task list or block time.</li>
                                <li className="mb-2">Seek Help When Needed: Ask teachers, classmates, or tutors for help; join group studies to motivate one another.</li>
                            </ul>
                        </div>

                        <div>
                            <div className="text-xl font-semibold leading-snug text-center text-white font-space-grotesk md:text-2xl">Challenges and Solutions for Time Management During Class 12th Board Exams</div>
                        </div>

                        <div className="md:leading-7 text-base text-justify font-light text-[#FFFCFC] font-space-grotesk ">
                            <p className="mb-4">
                                During class 12th board exams, efficient time management can be difficult due to some factors. Here are some common challenges and practical solutions:
                            </p>
                            <ul className="list-disc list-inside">
                                <li className="mb-2">Procrastination: This can lead to last-minute cramming and increased stress. Solution: Break study sessions into smaller tasks using techniques like the Pomodoro Technique. Set achievable goals and reward yourself for achievement.</li>
                                <li className="mb-2">Lack of a Structured Plan: Without a clear plan, it’s easy to lose track of priorities. Solution: Create a detailed study schedule, breaking down your syllabus into daily and weekly plans. Ensure to update and adjust your plans as needed.</li>
                                <li className="mb-2">Overwhelming Syllabus: The vast syllabus can feel unmanageable. Solution: Break down the syllabus into smaller sections and prioritize topics based on strengths and weaknesses. Use summary notes and flashcards.</li>
                                <li className="mb-2">Distractions: Study time is wasted by social media, among other activities. Solution: Identify common distractions and create a favourable environment for studies. Use apps that block social media from interfering with studies; inform family members when you are studying.</li>
                                <li className="mb-2">Stress And Anxiety: Pressure to achieve good grades impairs concentration in students. Solution: Practice relaxation techniques such as deep breathing or meditation. Ensure adequate sleep combined with healthy eating habits. Take regular breaks besides indulging in physical exercises.</li>
                            </ul>
                            <p className="mb-4">
                                By addressing these challenges with practical solutions, students can stay focused, productive, and well-prepared for their board exams.
                            </p>
                        </div>

                        <div>
                            <div className="text-xl font-semibold leading-snug text-center text-white font-space-grotesk md:text-2xl">Conclusion</div>
                        </div>

                        <div className="md:leading-7 text-base text-justify font-light text-[#FFFCFC] font-space-grotesk ">
                            <p className="mb-4">
                                Effective time management during the Class 12th board exams is paramount for academic success. By understanding its importance, adopting a well-structured strategy, and addressing potential challenges, students can navigate the exam period with confidence. Remember, consistent effort, smart planning, and a balanced approach to study and rest will lead to success in your board exams and prepare you for future academic endeavours. Good luck!
                            </p>
                        </div>

                    </div>

                    <div className="w-full max-w-md px-8 mt-8 shrink-0">
                        <div className="w-full bg-[#2D1E6B] p-4 md:p-8 rounded-xl border-white border-[1px]">
                            <div className="space-y-2">
                                <div className="flex items-end justify-start overflow-clip gap-x-4">
                                    <img src={blog_profile} alt="" className="w-16 rounded-lg md:w-32" />
                                    <img src={blog_linked} alt="" className="w-6 h-6 rounded-lg md:h-8 md:w-8" />
                                </div>
                                <div className="text-base font-semibold text-left text-white font-space-grotesk">Surbhi Bapna</div>
                                <div className="w-full h-[1px] bg-white"></div>
                                <div className="text-sm text-left text-white font-space-grotesk">
                                    Need Description here
                                </div>
                            </div>
                        </div>

                        {/* <div className="mt-8 font-semibold text-left text-white md:text-lg font-space-grotesk">
                            In this article
                            <div className="pt-4 space-y-2">
                                <div className="py-1 pl-3 border-l-2 border-[#3D22CF] cursor-pointer">Exploring Generative AI in Content Creation </div>
                                <div className="py-1 pl-3 cursor-pointer">Afterword: The AI Behind This Article</div>
                            </div>
                        </div> */}
                    </div>

                </div>

            </div>

            <div className="flex-col w-full">

                <div className="text-3xl md:text-4xl text-[#FAFF01] mb-4 w-full font-semibold font-goldman text-center">
                    Recommended Blogs
                </div>

                <div className='relative flex items-center justify-center px-8 space-x-12 overflow-scroll scrollbar-hide h-[500px]'>

                    <div className="absolute top-0 w-[470px] md:w-[1340px] h-full">

                        <Slider {...settings} className="px-4">

                            <div key={1} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 rounded-xl w-80 h-96'>
                                <img src={'https://acadspace.org/img/updates/MyCoolQ.png'} alt="blog" className="object-contain object-center rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    First Large Scale Partnership in East India
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    AcadSpace inaugurates Personality Coaching & Career Counseling for 1600+ proud parents. We are motivated by such a positive turn-up & feedback…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/partner-acadspace')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={2} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 rounded-xl w-80 h-96'>
                                <img src={internship_acadspace} alt="blog" className="object-contain object-center rounded-xl" />
                                <div className='font-bold text-left text-white '>
                                    <div className="bg-[#F1ECFF] w-fit flex items-center justify-center text-[#6C40FF] text-sm font-normal rounded-xl py-1 px-2 mt-2 font-space-grotesk">
                                        <div className="w-3 h-3 bg-[#6C40FF] rounded-full font-light text-[8px] mr-1"></div>Internships
                                    </div>
                                    <div className="flex items-center justify-between mt-2">
                                        <div className="flex font-medium text-[#97989F] text-base">
                                            <img src={blog_profile} alt="blog" className="w-4 mr-1 rounded-lg md:w-6" /> Manish Shivangi
                                        </div>
                                        <div className="flex font-medium text-[#97989F] text-sm">
                                            July 31st 2024
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        Unlocking Career Success: The Crucial Role of Internships in Career Development
                                    </div>
                                </div>
                                <div className='flex items-center justify-end w-full -mt-2 cursor-pointer' onClick={() => navigate('/blog/internship')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={3} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                                <img src={ssc_chsl_exam} alt="blog" className="object-contain object-center rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    What is the SSC CGL Exam? Everything You Need to Know
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    The SSC CGL (Staff Selection Commission Combined Higher Secondary Level) exam is a crucial opportunity for those looking to secure government jobs…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/ssc-cgl')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={4} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                                <img src={TimeManagement12thexam} alt="blog" className="object-cover object-center min-h-48 rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    How To Manage Time During Class 12th Exams?
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    Learn how to do time management for Class 12th exams with tips on creating a study plan, balancing study and rest, and reducing stress…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/12th')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={5} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                                <img src={neet_exam_banner} alt="blog" className="object-cover object-center min-h-48 rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    NEET: Medical Exam for Students
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    The NEET (National Eligibility cum Entrance Test) is an Indian nationwide entrance examination conducted by the National Testing Agency (NTA) for admission to undergraduate medical programs…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/neet')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                        </Slider>

                    </div>

                </div>


            </div>

        </div>
    );
}

export default TimeManagementBlog;
