import React from 'react';
import close from '../assets/close.png';

const SideDrawer = ({ isOpen, onClose }) => {
    return (
        <div
            className={`fixed inset-0 z-50 transform ${isOpen ? 'translate-x-0' : 'translate-x-full select-none'
                } bg-black/20 backdrop-blur-sm shadow-lg select-none flex items-end justify-end`}
        >
            <div className='absolute top-0 flex-col w-[300px] md:w-[300px] bg-[#FAFF01] shadow-drawer rounded-lg h-fit p-4 pb-8'>
                
                <div className='flex items-end justify-end w-full p-4'>
                    <button
                        className="w-fit"
                        onClick={onClose}
                    >
                        <img src={close} alt="AcadSpace Logo" className="h-7 md:h-10" />
                    </button>
                </div>

                {/* <div className='relative mr-16 select-none'>
                    <div className='relative rounded-xl bg-[#1D1050] md:text-xl shadow-combined p-3 md:p-4 font-space-grotesk text-white cursor-pointer z-10'>
                        Ask Query
                    </div>
                    <div className='absolute w-full h-full top-2 rounded-xl bg-[#1D1050] shadow-combined '>

                    </div>
                </div> */}
                
                <div className="p-4 text-xl font-space-grotesk text-[#1D104F]">
                    <ul className='space-y-8'>
                        <li><a href="/">Home</a></li>
                        <li><a href="/aboutus">About Us</a></li>
                        {/* <li><a href="/link2">Career Questions</a></li> */}
                        <li><a href="/discover">Discover</a></li>
                        <li><a href="/blog/partner-acadspace">Blogs</a></li>
                        <li><a href="/contactUs">Contact Us</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SideDrawer;
