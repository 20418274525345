import React, { useEffect, useState } from 'react';
import college from '../../assets/careers/college.png';
import arrow from '../../assets/arrow-left.png';
import Slider from 'react-slick';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function CareerDetails() {

    const [activeTab, setActiveTab] = useState('Career Path 1');

    const [careerData, setCareerData] = useState(null);

    const location = useLocation();
    const { careerName } = location.state || { careerName: 'Engineering' };

    const [activeTabCollege, setActiveTabCollege] = useState('India');

    const initialStreams = 4;
    const navigate = useNavigate();

    const [showAll, setShowAll] = useState(false);

    const getCareerPathData = (path) => {
        const pathData = careerData?.careerpath.find(p => p.title === path);
        return pathData ? pathData.data : [];
    };

    const renderSchools = (schools) => (
        <ul className='grid gap-4 mt-2 mb-4 md:grid-cols-3'>
            {schools?.map((school, id) => (
                <li key={id} className='flex p-4 border-2 md:px-8 border-white/20 rounded-xl'>
                    <a href={`https://${school.website}`} target="_blank" rel="noopener noreferrer" className='flex items-start justify-center w-full space-x-4 text-white no-underline md:space-x-8'>
                        <div className='flex items-start justify-start w-fit aspect-square shrink-0'>
                            <img src={school.image || 'https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2FUniversity%20Icon.png?alt=media&token=625d377d-9e8d-4ad7-a182-a51ca563a3ca'}
                                alt=""
                                className="w-16 h-16 shadow-md md:w-20 md:h-20 aspect-square rounded-xl shrink-0" />
                        </div>
                        <div className='flex items-center justify-start w-full mt-2 space-y-1 text-xs text-left md:text-base'>
                            <div>
                                <div className='font-semibold'>{school.name}</div>
                                <div>{school.location}</div>
                            </div>
                        </div>
                    </a>
                </li>
            ))}
        </ul>
    );

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        // arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        // className: "center",
        centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleNavigate = (stream) => {
        navigate('/discover/careersubstream', { state: { substreamData: stream } });
    };

    useEffect(() => {
        if (window.innerWidth >= 1024) {
            setShowAll(true);
        }
    }, []);

    useEffect(() => {
        const fetchCareerData = async () => {
            try {
                const response = await axios.post('https://testenv.acadspace.org/api/mobile/careerLibraryDeatils', {
                    career: careerName
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setCareerData(response.data);
            } catch (error) {
                console.error('Error fetching career data:', error);
            }
        };

        fetchCareerData();
    }, [careerName]);


    const displayedStreams = showAll ? careerData?.substreams : careerData?.substreams.slice(0, initialStreams);

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] overscroll-none space-y-8 md:space-y-16 transform-gpu font-space-grotesk select-none max-w-full overflow-clip pb-16'>
            <div className='flex-col items-center justify-center pt-8 space-y-8 text-center md:py-0 sm:mx-auto'>

                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 md:mb-0 text-3xl sm:text-5xl font-bold leading-snug font-goldman py-5 text-[#FAFF01]"> {careerData?.title} </div>

                <div className='text-[#7b8891] px-8 font-medium font-space-grotesk w-full text-left'>
                    <span className='cursor-pointer' onClick={() => navigate('/')} >Home</span> {'>'}
                    <span className='cursor-pointer' onClick={() => navigate('/discover')} > Discover</span> {'>'}
                    <span className='text-[#247FD3] cursor-pointer' onClick={() =>  navigate(`/discover/career`, { state: { careerName } })}> {careerData?.title}</span>
                </div>

                <div className='mt-4 w-fit'>
                    <div className='px-4 md:px-8'>
                        <div className='flex items-center justify-start p-2 px-4 font-semibold text-white md:px-8 md:p-3 font-space-grotesk rounded-xl bg-white/10'>
                            <div className=''>Discover</div>
                            <span className='mx-3 text-2xl font-bold'>{'>'}</span>
                            <div className='font-space-grotesk'>{careerData?.title}</div>
                        </div>
                    </div>
                </div>

                <div className='flex items-center justify-center px-8 space-x-4 md:px-16 md:space-x-16'>
                    <div className='shrink-0'>
                        <img src={careerData?.image} alt={careerData?.title} className='w-24 rounded-lg md:w-56' />
                    </div>
                    <div className='flex items-center justify-center w-full h-full p-4 md:p-8 space-x-4 border-2 border-white rounded-2xl bg-[#2D1E6B] md:h-56'>
                        <div className='flex-col w-full text-left text-white h-fit'>
                            <div className='text-xl font-bold underline md:text-4xl underline-offset-8'>{careerData?.title}</div>
                            <div className='text-[10px] mt-4 leading-relaxed md:leading-loose line-clamp-4 md:text-sm text-pretty'>{careerData?.desc}</div>
                            {/* <div className='text-[10px] md:text-sm text-[#E8EC41] cursor-pointer'>read more</div> */}
                        </div>
                    </div>
                </div>

                <div className='w-full px-4 text-2xl font-semibold text-left text-white md:px-8 md:text-3xl font-space-grotesk'>Success stories  <span className='text-[#247FD3]'>of Engineers</span></div>

                <div className="md:px-16">
                    <Slider {...settings} className='md:px-4'>
                        {careerData?.videoarr.map((videoId, index) => (
                            <div key={index} className="px-2 select-none md:px-4">
                                <iframe
                                    className="w-full h-full border-2 border-white min-h-60 md:min-h-96 rounded-2xl overflow-clip"
                                    src={`https://www.youtube.com/embed/${videoId}`}
                                    title={`YouTube video player ${index}`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        ))}
                    </Slider>
                </div>

                <div className='w-full px-4 text-2xl font-semibold text-left text-white md:px-8 md:text-3xl font-space-grotesk'> Career Streams<span className='text-[#247FD3]'> in <span>{careerData?.title}</span></span></div>

                <div>
                    <div className='grid grid-cols-2 gap-4 px-4 md:gap-8 md:px-16 md:grid-cols-4 lg:grid-cols-6'>
                        {displayedStreams?.map((stream, index) => (
                            <div key={stream._id}
                                className='relative p-4 border-2 border-white aspect-square rounded-2xl bg-[#2D1E6B] hover:bg-white text-white hover:text-black flex items-center justify-center'
                                onClick={() => handleNavigate(stream)}>

                                <div className='relative flex items-center justify-center w-full h-full'>
                                    <div>
                                        <div className='flex items-center justify-center w-full '>
                                            <div className='flex justify-center w-full item-center'>
                                                <img
                                                    src={stream.image || 'https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/career%2Fsoftware-engineer_8759210%201.png?alt=media&'}
                                                    alt={stream.heading}
                                                    className='object-cover h-16 rounded-lg md:h-32 aspect-square md:p-2' />
                                            </div>
                                        </div>
                                        <div className='mt-2 text-xs font-semibold md:text-lg'>{stream.heading}</div>
                                    </div>
                                </div>

                                {/* <ArrowForwardIosIcon className='absolute font-medium md:text-4xl top-4 font-space-grotesk right-2'/> */}

                            </div>
                        ))}
                    </div>

                    {displayedStreams?.length < careerData?.substreams.length && (
                        <button
                            onClick={() => setShowAll(!showAll)}
                            className=' px-6 py-2 mt-2 text-[#FAFF01] rounded-full font-semibold font-space-mono text-right w-full'
                        >
                            {showAll ? 'Show Less' : 'Show More'}
                        </button>
                    )}
                </div>

                <div className='w-full px-4 text-2xl font-semibold text-left text-white md:px-8 md:text-3xl font-space-grotesk'>Possible Path<span className='text-[#247FD3]'> to become an Engineer</span></div>

                <div className='px-4 md:px-8'>
                    <div className='flex w-full overflow-x-scroll text-xs font-semibold scrollbar-hide'>
                        {careerData?.careerpath.map((path, index) => (
                            <button
                                key={index}
                                onClick={() => setActiveTab(path.title)}
                                className={`px-4 md:px-8 py-4 text-white text-xs md:text-lg shrink-0 shadow-lg rounded-t-xl rounded-sm ${activeTab === path.title ? 'bg-[#369FFF]/10 decoration-[#E7EC43] decoration-2 underline underline-offset-[12px] font-bold' : 'bg-transparent'}`}
                            >
                                {path.title}
                            </button>
                        ))}
                    </div>

                    <div className='p-4 text-white bg-[#369FFF]/10 rounded-b-2xl'>
                        {careerData?.careerpath.map((path, index) => (
                            <div key={index} className={`${activeTab === path.title ? '' : 'hidden'}`}>
                                <ul className='mt-2 overflow-x-scroll md:flex scrollbar-hide'>
                                    {path.data.map((item, idx) => (
                                        <li key={idx} className='flex flex-row-reverse items-center justify-center md:flex-row'>

                                            <div className='flex flex-row items-center justify-between w-full p-4 md:w-[20vw] md:flex-col'>
                                                <div className='flex items-center justify-start w-full md:justify-center'>
                                                    {path.image && path.image[idx] ? (
                                                        <img src={path.image[idx] || 'https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2FCareer%20Path%201.png?alt=media&token=30cd0c80-460b-4238-ae94-0aba70092f2d'} alt='' className="w-24 h-24 bg-[#369FFF]/20 p-4 rounded-xl shadow-md" />
                                                    ) : (

                                                        <img src={'https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2FCareer%20Path%201.png?alt=media&token=30cd0c80-460b-4238-ae94-0aba70092f2d'} alt='' className="w-24 h-24 bg-[#369FFF]/20 p-4 rounded-xl shadow-md" />

                                                    )}
                                                </div>
                                                <div className='w-full mt-2 text-xs text-center md:text-sm'>{item}</div>
                                            </div>

                                            <img
                                                src={arrow}
                                                alt=''
                                                className={`h-8 ${idx === path.data.length - 1 ? 'md:hidden' : 'md:block'} md:-rotate-90`}
                                            />

                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='w-full px-4 text-2xl font-semibold text-left text-white md:px-8 md:text-3xl font-space-grotesk'>Top Colleges<span className='text-[#247FD3]'> to become an Engineer</span></div>

                <div className='px-4 md:px-8'>
                    <div className='flex w-full overflow-x-scroll text-xs font-semibold scrollbar-hide'>
                        <button
                            key={1}
                            onClick={() => setActiveTabCollege('India')}
                            className={`px-4 md:px-8 py-4 text-white w-1/2 text-xs md:text-xl shrink-0 shadow-lg rounded-t-xl rounded-sm ${activeTabCollege === 'India' ? 'bg-[#369FFF]/10 decoration-[#E7EC43] decoration-2 underline underline-offset-[12px] font-bold' : 'bg-transparent'}`}
                        >
                            India
                        </button>
                        <button
                            key={2}
                            onClick={() => setActiveTabCollege('Abroad')}
                            className={`px-4 md:px-8 py-4 text-white w-1/2 text-xs md:text-xl shrink-0 shadow-lg rounded-t-xl rounded-sm ${activeTabCollege === 'Abroad' ? 'bg-[#369FFF]/10 decoration-[#E7EC43] decoration-2 underline underline-offset-[12px] font-bold' : 'bg-transparent'}`}
                        >
                            Abroad
                        </button>
                    </div>

                    <div className='p-4 text-white bg-[#369FFF]/10 rounded-b-2xl'>
                        {activeTabCollege === 'India' &&
                            <div>
                                <div className='text-xs font-light text-justify md:text-base'>
                                    Self-expression, creativity, imagination.
                                    Appreciates art, beauty, aesthetics, language
                                    — Unconventional people who think outside
                                    the box.
                                </div>
                                <div className='w-full py-3 text-xs font-bold text-left md:text-base'>
                                    Top Colleges in India
                                </div>
                                {renderSchools(careerData?.Instilist_india.school)}
                            </div>
                        }
                        {activeTabCollege === 'Abroad' &&
                            <div>
                                <div className='text-xs font-light text-justify md:text-sm'>
                                    Self-expression, creativity, imagination.
                                    Appreciates art, beauty, aesthetics, language
                                    — Unconventional people who think outside
                                    the box.
                                </div>
                                <div className='w-full py-3 text-xs font-bold text-left md:text-sm'>
                                    Top Colleges in Abroad
                                </div>
                                {renderSchools(careerData?.Instilist_abroad.school)}
                            </div>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CareerDetails;
