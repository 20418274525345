import React, { useState } from 'react';
import logo from '../assets/AcadSpaceLogo.png';
import hamburger from '../assets/hamburger.png';
import LoginPopup from './LoginPopup';
import SideDrawer from './SideDrawer';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <header>
      <div className="w-full max-w-full select-none overflow-clip flex items-center content-center justify-between bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050]">
        <div className='flex items-center justify-between w-full px-4 mx-auto md:px-12'>
          <Link to="/">
            <img src={logo} alt="AcadSpace Logo" className="h-24 -mt-3 md:h-28" />
          </Link>
          <div className='flex items-center justify-center'>
            
            <div className='relative flex items-center justify-center -top-[2px]'>
              <Link
                to="/book"
                className='relative rounded-md md:rounded-[10px]  shadow-lg text-sm bg-[#FAFF01] md:text-xl px-6 md:px-8 py-1 md:py-2 font-goldman text-[#1D1050] cursor-pointer z-10'
              >
                Login
              </Link>
              <div className='absolute w-full h-full rounded-[10px] md:rounded-md top-[3px] bg-[#FAFF01] shadow-custom-login-1'></div>
            </div>
            
            <img
              src={hamburger}
              alt="sidemenu"
              className="ml-3 cursor-pointer h-7 md:h-10"
              onClick={handleDrawerOpen}
            />
          </div>
        </div>
        {isPopupOpen && <LoginPopup onClose={() => setIsPopupOpen(false)} />}
        <SideDrawer isOpen={isDrawerOpen} onClose={handleDrawerClose} />
      </div>
    </header>
  );
};

export default Header;
