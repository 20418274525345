import React, { useEffect, useState } from 'react';
import college from '../../assets/careers/college.png';
import arrow from '../../assets/arrow-left.png';
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function CareerSubStream() {

    const [activeTab, setActiveTab] = useState('Career Path 1');

    const [careerData, setCareerData] = useState(null);
    const location = useLocation();
    const { substreamData } = location.state || {};

    const { careerName } = location.state || { careerName: 'Engineering' };

    const [activeTabCompany, setActiveTabCompany] = useState('Private');

    const [activeTabCollege, setActiveTabCollege] = useState('India');

    const initialStreams = 4;

    const privateCompanies = [
        'Hindustan Construction Company (HCC)',
        'IRCON International Limited',
        'National Buildings Construction Corporation',
        'Larsen & Toubro',
        'DLF Limited',
        'Punj Lloyd',
        'Simplex Infrastructure Limited',
        'RITES',
        'Engineers India',
        'Essar Group',
        'Lodha Group',
        'Tata Projects LTD',
        'DRDO',
        'Tata Projects LTD'
    ];

    const collegesIndia = [
        { college: 'IIT Madras', location: 'Chennai(TN)', exam: 'JEE Mains & Advance', fees: '2 Lakhs', cutOff: 6066 },
        { college: 'IIT Delhi', location: 'Delhi', exam: 'JEE Mains & Advance', fees: '2.45 Lakhs', cutOff: 4267 },
        { college: 'IIT Bombay', location: 'Bombay(MH)', exam: 'JEE Mains & Advance', fees: '2.29 Lakhs', cutOff: 4371 },
        { college: 'IIT Kanpur', location: 'Kanpur UP', exam: 'JEE Mains & Advance', fees: '2.29 Lakhs', cutOff: 5796 },
        { college: 'IIT Roorkee', location: 'Roorkee(UK)', exam: 'JEE Mains & Advance', fees: '2 Lakhs', cutOff: 6777 },
        { college: 'IIT Kharagpur', location: 'Kharagpur(WB)', exam: 'JEE Mains & Advance', fees: '2.18 Lakhs', cutOff: 6995 },
        { college: 'IIT Guwahati', location: 'Guwahati(Assam)', exam: 'JEE Mains & Advance', fees: '2.14 Lakhs', cutOff: 8453 },
        { college: 'IIT Hyderabad', location: 'Hyderabad(AP)', exam: 'JEE Mains & Advance', fees: '2.27 Lakhs', cutOff: 8287 },
        { college: 'NIT Trichy', location: 'Tiruchirappalli(TN)', exam: 'JEE Mains', fees: '1.42 Lakhs', cutOff: 'NA' },
        { college: 'Jadavpur University', location: 'Kolkata(WB)', exam: 'WBJEE', fees: '5 Thousands', cutOff: 720 }
    ];

    const [showAll, setShowAll] = useState(false);

    const renderSchools = (schools) => (
        <ul className='grid gap-4 mt-2 mb-4 md:grid-cols-2'>
            {schools?.map((school, id) => (
                <li key={id} className='flex p-4 border-2 md:px-8 border-white/20 rounded-xl'>
                    <a href={`https://${school.website}`} target="_blank" rel="noopener noreferrer" className='flex items-start justify-center w-full space-x-4 text-white no-underline'>
                        <div className='flex items-start justify-start w-fit aspect-square shrink-0'>
                            <img src={college} alt="" className="w-16 h-16 shadow-md md:w-20 md:h-20 aspect-square rounded-xl shrink-0" />
                        </div>
                        <div className='flex items-center justify-start w-full mt-2 space-y-1 text-xs text-left md:text-sm'>
                            <div>
                                <div className='font-semibold'>{school.name}</div>
                                <div>{school.location}</div>
                            </div>
                        </div>
                    </a>
                </li>
            ))}
        </ul>
    );

    useEffect(() => {
        if (window.innerWidth >= 1024) {
            setShowAll(true);
        }
    }, []);

    useEffect(() => {
        const fetchCareerData = async () => {
            try {
                const response = await axios.post('http://testenv.acadspace.org/api/mobile/careerLibraryDeatils', {
                    career: careerName
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setCareerData(response.data);
            } catch (error) {
                console.error('Error fetching career data:', error);
            }
        };

        fetchCareerData();
    }, [careerName]);


    const displayedStreams = showAll ? careerData?.substreams : careerData?.substreams.slice(0, initialStreams);

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] overscroll-none space-y-8 md:space-y-16 transform-gpu font-space-grotesk select-none max-w-full overflow-clip pb-16'>
            <div className='flex-col items-center justify-center pt-8 space-y-8 text-center md:py-0 sm:mx-auto'>

                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 md:mb-0 text-3xl sm:text-5xl font-bold leading-snug font-goldman py-5 text-[#FAFF01]"> {careerData?.title} </div>

                <div className='mt-4 w-fit'>
                    <div className='px-4 md:px-8'>
                        <div className='flex items-center justify-start p-2 px-4 font-semibold text-white md:px-8 md:p-3 font-space-grotesk rounded-xl bg-white/10'>
                            <div className=''>Discover</div>
                            <span className='mx-3 text-2xl font-bold'>{'>'}</span>
                            <div className='font-space-grotesk'>{substreamData?.heading}</div>
                        </div>
                    </div>
                </div>

                <div className='flex items-center justify-center px-8 space-x-4 md:px-16 md:space-x-16'>
                    <div className='shrink-0'>
                        <img src={substreamData?.image || 'https://firebasestorage.googleapis.com/v0/b/mvp-acadspace.appspot.com/o/career%2Fsoftware-engineer_8759210%201.png?alt=media&'}
                            alt={substreamData?.title} className='w-24 rounded-lg md:w-56' />
                    </div>
                    <div className='flex items-center justify-center w-full h-full p-4 md:p-8 space-x-4 border-2 border-white rounded-2xl bg-[#2D1E6B] md:h-56'>
                        <div className='flex-col w-full text-left text-white h-fit'>
                            <div className='text-xl font-bold underline md:text-4xl underline-offset-8'>{substreamData?.heading}</div>
                            <div className='text-[10px] mt-4 leading-relaxed md:leading-loose line-clamp-4 md:text-sm text-pretty'>{substreamData?.des}</div>
                            {/* <div className='text-[10px] md:text-sm text-[#E8EC41] cursor-pointer'>read more</div> */}
                        </div>
                    </div>
                </div>

                <div className='w-full px-4 text-2xl font-semibold text-left text-white md:px-8 md:text-3xl font-space-grotesk'>Top Companies <span className='text-[#247FD3]'>to Work With</span></div>

                <div className='px-4 md:px-8'>
                    <div className='flex w-full overflow-x-scroll text-xs font-semibold scrollbar-hide'>
                        <button
                            key={1}
                            onClick={() => setActiveTabCompany('Private')}
                            className={`px-4 md:px-8 py-4 text-white w-1/2 text-xs md:text-xl shrink-0 shadow-lg rounded-t-xl rounded-sm ${activeTabCompany === 'Private' ? 'bg-[#369FFF]/10 decoration-[#E7EC43] decoration-2 underline underline-offset-[12px] font-bold' : 'bg-transparent'}`}
                        >
                            Private
                        </button>
                        <button
                            key={2}
                            onClick={() => setActiveTabCompany('Government')}
                            className={`px-4 md:px-8 py-4 text-white w-1/2 text-xs md:text-xl shrink-0 shadow-lg rounded-t-xl rounded-sm ${activeTabCompany === 'Government' ? 'bg-[#369FFF]/10 decoration-[#E7EC43] decoration-2 underline underline-offset-[12px] font-bold' : 'bg-transparent'}`}
                        >
                            Government
                        </button>
                    </div>

                    <div className='p-4 text-white bg-[#369FFF]/10 rounded-b-2xl'>
                        {activeTabCompany === 'Private' &&
                            <div className='grid grid-cols-2 gap-4 md:grid-cols-3'>
                                {privateCompanies.map((company, index) => (
                                    <div key={index} className='p-4 bg-[#2D1E6B] rounded-lg shadow-md text-xs text-left md:text-base'>
                                        {company}
                                    </div>
                                ))}
                            </div>
                        }
                        {activeTabCompany === 'Government' &&
                            <div className='grid grid-cols-2 gap-4 md:grid-cols-3'>
                                {privateCompanies.map((company, index) => (
                                    <div key={index} className='p-4 bg-[#2D1E6B] rounded-lg shadow-md text-xs text-left md:text-base'>
                                        {company}
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>

                <div className='w-full px-4 text-2xl font-semibold text-left text-white md:px-8 md:text-3xl font-space-grotesk'> Top 10 Colleges to join<span className='text-[#247FD3]'> for <span>{substreamData?.heading}</span></span></div>

                <div className='px-4 md:px-8'>
                    <div className='flex w-full overflow-x-scroll text-xs font-semibold scrollbar-hide'>
                        <button
                            key={1}
                            onClick={() => setActiveTabCollege('India')}
                            className={`px-4 md:px-8 py-4 text-white w-1/2 text-xs md:text-xl shrink-0 shadow-lg rounded-t-xl rounded-sm ${activeTabCollege === 'India' ? 'bg-[#369FFF]/10 decoration-[#E7EC43] decoration-2 underline underline-offset-[12px] font-bold' : 'bg-transparent'}`}
                        >
                            India
                        </button>
                        <button
                            key={2}
                            onClick={() => setActiveTabCollege('Abroad')}
                            className={`px-4 md:px-8 py-4 text-white w-1/2 text-xs md:text-xl shrink-0 shadow-lg rounded-t-xl rounded-sm ${activeTabCollege === 'Abroad' ? 'bg-[#369FFF]/10 decoration-[#E7EC43] decoration-2 underline underline-offset-[12px] font-bold' : 'bg-transparent'}`}
                        >
                            Abroad
                        </button>
                    </div>

                    <div className='p-4 text-white bg-[#369FFF]/10 rounded-b-2xl'>
                        {activeTabCollege === 'India' &&
                            <div className='grid grid-cols-5 gap-0 overflow-x-scroll text-xs text-center'>
                                <div className='p-2 font-bold border border-white'>College</div>
                                <div className='p-2 font-bold border border-white'>Location</div>
                                <div className='p-2 font-bold border border-white'>Exam</div>
                                <div className='p-2 font-bold border border-white'>Fees/Year</div>
                                <div className='p-2 font-bold border border-white'>Cut Off</div>
                                {collegesIndia.map((college, index) => (
                                    <React.Fragment key={index}>
                                        <div className='p-2 whitespace-normal border border-white'>{college.college}</div>
                                        <div className='p-2 whitespace-normal border border-white'>{college.location}</div>
                                        <div className='p-2 whitespace-normal border border-white'>{college.exam}</div>
                                        <div className='p-2 whitespace-normal border border-white'>{college.fees}</div>
                                        <div className='p-2 whitespace-normal border border-white'>{college.cutOff}</div>
                                    </React.Fragment>
                                ))}
                            </div>
                        }
                        {activeTabCollege === 'Abroad' &&
                            <div className='grid grid-cols-5 gap-0 overflow-x-scroll text-xs text-center'>
                                <div className='p-2 font-bold border border-white'>College</div>
                                <div className='p-2 font-bold border border-white'>Location</div>
                                <div className='p-2 font-bold border border-white'>Exam</div>
                                <div className='p-2 font-bold border border-white'>Fees/Year</div>
                                <div className='p-2 font-bold border border-white'>Cut Off</div>
                                {collegesIndia.map((college, index) => (
                                    <React.Fragment key={index}>
                                        <div className='p-2 whitespace-normal border border-white'>{college.college}</div>
                                        <div className='p-2 whitespace-normal border border-white'>{college.location}</div>
                                        <div className='p-2 whitespace-normal border border-white'>{college.exam}</div>
                                        <div className='p-2 whitespace-normal border border-white'>{college.fees}</div>
                                        <div className='p-2 whitespace-normal border border-white'>{college.cutOff}</div>
                                    </React.Fragment>
                                ))}
                            </div>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CareerSubStream;
