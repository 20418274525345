import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import star from '../../assets/star.png';
import hero_webinar from '../../assets/hero_webinar.png';
import arrow from '../../assets/arrow.png';
import { BASE_URL } from '../../components/common/AppConfig';
import axios from 'axios';

const Webinar = () => {

    const navigate = useNavigate();

    const generateNext7Days = () => {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const today = new Date();
        const data = [];

        for (let i = 0; i < 7; i++) {
            const nextDay = new Date(today);
            nextDay.setDate(today.getDate() + i);

            const year = nextDay.getFullYear();
            const month = String(nextDay.getMonth() + 1).padStart(2, '0'); // Adding leading zero if needed
            const day = String(nextDay.getDate()).padStart(2, '0'); // Adding leading zero if needed

            data.push({
                day: daysOfWeek[nextDay.getDay()],
                date: nextDay.getDate(),
                fullDate: `${year}-${month}-${day}` // Generating full date in yyyy-mm-dd format
            });
        }

        return data;
    };

    const data = generateNext7Days();

    const [selectedDate, setSelectedDate] = useState(null);

    const [webinarData, setWebinarData] = useState([]);
    const [pastWebinarData, setPastWebinarData] = useState([]);
    const [error, setError] = useState(null);
    const [itemsToShow, setItemsToShow] = useState(2);
    const [highlightWebinarData, setHighlightWebinarData] = useState([]);

    useEffect(() => {
        const fetchWebinarDetails = async () => {
            try {
                const response = await axios.post(`${BASE_URL}/web/upcomingWebinar`, {
                    date: "2024-03-01"
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setWebinarData(response.data.webinars);
            } catch (error) {
                setError('Error fetching webinar details');
                console.error('Error fetching webinar details:', error);
            }
        };

        const fetchPastWebinarDetails = async () => {
            try {
                const response = await axios.post(`${BASE_URL}/web/pastWebinar`, {}, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setPastWebinarData(response.data);

            } catch (error) {
                setError('Error fetching past webinar details');
                console.error('Error fetching past webinar details:', error);
            }
        };

        const fetchHighlightWebinarDetails = async () => {
            try {
                const response = await axios.post(`${BASE_URL}/web/highlightWebinar`, {
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setHighlightWebinarData(response.data.webinars);
            } catch (error) {
                setError('Error fetching webinar details');
                console.error('Error fetching webinar details:', error);
            }
        };

        fetchWebinarDetails();
        fetchPastWebinarDetails();
        fetchHighlightWebinarDetails();

        const updateItemsToShow = () => {
            if (window.innerWidth < 768) {
                setItemsToShow(2);
            } else {
                setItemsToShow(4);
            }
        };

        window.addEventListener('resize', updateItemsToShow);
        updateItemsToShow();

        return () => window.removeEventListener('resize', updateItemsToShow);

    }, []);

    const handleDateClick = async (index) => {
        setSelectedDate(index);
        const selectedFullDate = data[index].fullDate;

        try {
            const response = await axios.post(`${BASE_URL}/web/upcomingWebinar`, {
                date: selectedFullDate
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setWebinarData(response.data.webinars);
        } catch (error) {
            setError('Error fetching webinar details');
            console.error('Error fetching webinar details:', error);
        }
    };

    if (error) {
        return <div>{error}</div>;
    }

    if (!webinarData) {
        return (
            <div className="flex items-center justify-center h-[88vh] bg-[#1D1050]">
                <img src={'https://firebasestorage.googleapis.com/v0/b/acadspace-36bb8.appspot.com/o/Assets%2FWebApp%2Fnew%2Fgiphy-unscreen.gif?alt=media&token=788057b2-0081-4d8c-88e1-5926052daa5e'} alt="Loading..."
                    className="h-28 w-28 md:h-32 md:w-32" />
            </div>
        );
    }

    const handleNavigate = (webinarId) => {
        navigate('/discover/webinar-details', { state: { webinarId } });
    };

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] space-y-8 md:space-y-12 font-space-grotesk transform-gpu select-none max-w-full overflow-clip pb-16'>

            <div className='flex-col items-center justify-center text-center sm:mx-auto'>
                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 text-3xl sm:text-6xl font-bold leading-snug font-goldman py-3 text-[#FAFF01]"> Discover </div>
            </div>

            <div className='text-[#6C7880] px-4 font-medium font-space-grotesk'>
                <span className='cursor-pointer' onClick={() => navigate('/')} >Home</span> {'>'}
                <span className='cursor-pointer' onClick={() => navigate('/discover')} > Discover</span> {'>'}
                <span className='text-[#247FD3] cursor-pointer' onClick={() => navigate('/discover/webinar')}> Webinar</span>
            </div>

            <div className='relative overflow-clip'>

                <img src={hero_webinar} className='relative w-full h-[300px] md:h-[500px] object-cover  px-8' />

                <div className='absolute top-0 flex items-center justify-center w-full h-full px-8 overflow-clip'>
                    <div className='w-full py-4 backdrop-blur-md'>
                        <div className='w-full font-semibold text-center text-white -xl md:text-5xl'>Explore the world of Webinars</div>
                        <div className='text-[#E5E94C] text-center font-light md:mt-2 text-[8px] md:text-lg '>Attend Live Webinars from the top 1% of the country.</div>
                    </div>
                </div>

            </div>

            <div className='w-full text-3xl font-semibold text-center text-white font-space-grotesk'>Upcoming <span className='text-[#247FD3]'>Webinars</span></div>

            <div className='flex items-center justify-center w-full px-8'>
                <div className='bg-[#372972] rounded-lg w-fit px-2 py-1'>
                    <div className="grid grid-cols-7 gap-2 mb-2 font-semibold text-center">
                        {data.map((item, index) => (
                            <div className={`flex items-center shrink-0 justify-center py-2 px-4 ${selectedDate === index ? 'border border-[#FFFFFF] font-semibold rounded-lg bg-gradient-to-b from-[##1D104F] to-[#372972]' : ''}`}>
                                <div
                                    key={index}
                                    className={`flex-col shrink-0 font-normal space-y-2 text-white text-center font-space-grotesk cursor-pointer`}
                                    onClick={() => handleDateClick(index)}
                                >
                                    <div>{item.day}</div>
                                    <div>{item.date}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* <div className='items-center justify-center w-full text-xl font-semibold text-center text-white font-space-grotesk'>
                6:30 PM IST
            </div> */}

            <div className='flex items-center justify-start px-4'>
                <div className='flex overflow-scroll scrollbar-hide'>
                    {webinarData.map((webinarData, index) => (
                        <div key={webinarData._id} onClick={() => handleNavigate(webinarData._id)} className='flex items-center justify-center w-full cursor-pointer'>
                            <div>
                                <div className='px-4 rounded-xl w-[80vw] md:w-[23vw] flex items-center justify-center '>
                                    <div>
                                        <div className='flex items-center justify-center w-full'>
                                            <img src={webinarData.WebinerPic} alt="" className="object-contain object-center w-full rounded-lg aspect-square" />
                                        </div>
                                        <div className='px-4 text-[10px] mt-2 font-light text-white flex items-center justify-between'>
                                            <div className='flex'> POPULAR . 4.5 <div className='flex items-center justify-center'> <img src={star} alt="" className="inline object-center w-2 h-2 mx-1" /></div> (145)</div>
                                            <div>TOTAL ATTENDEE'S . {webinarData.registered}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='px-4 mt-2 text-lg font-semibold text-center text-white font-space-grotesk'>
                                    {webinarData.WebinerName}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='w-full text-3xl font-semibold text-center text-white font-space-grotesk'>Most Recent <span className='text-[#247FD3]'>Webinars</span></div>

            <div className='flex flex-col items-center justify-center px-4 md:flex-row'>
                {pastWebinarData?.pastweb?.slice(0, itemsToShow).map((webinar) => (
                    <div key={webinar._id}>
                        <div className='px-8 mt-8 rounded-xl'>
                            <img onClick={() => navigate('/discover/webinar-details', { state: { webinarId: webinar._id } })} src={webinar.WebinerPic} alt="" className="object-contain w-full h-full rounded-xl aspect-square" />
                            <div className='px-4 text-[10px] mt-2 font-light text-white flex items-center justify-between'>
                                <div className='flex'> POPULAR . 4.5 <div className='flex items-center justify-center'> <img src={star} alt="" className="inline object-center w-2 h-2 mx-1" /></div> (145)</div>
                                <div>TOTAL ATTENDEE'S . {webinarData.registered}</div>
                            </div>
                        </div>
                        <div className='px-4 mt-2 text-lg font-semibold text-center text-white font-space-grotesk'>
                            {webinar.WebinerName}
                        </div>
                    </div>
                ))}
            </div>

            <div>
                <div className='flex md:mt-6 items-center w-full justify-end text-[#FAFF01]'>
                    <div className='flex items-center mr-6 text-sm font-semibold cursor-pointer md:text-base font-space-mono' onClick={() => navigate('/discover')}>
                        Explore More
                        <img src={arrow} alt="arrow" className="w-12 h-12 ml-2" />
                    </div>
                </div>
            </div>

            <div className='w-full text-3xl font-semibold text-center text-white font-space-grotesk'>Popular of all <span className='text-[#247FD3]'>Time</span></div>

            <div className='flex items-center justify-center px-4'>
                <div className='flex overflow-scroll scrollbar-hide'>
                    {pastWebinarData?.impweb?.slice(0, itemsToShow).map((webinar) => (
                        <div className='' key={webinar._id}>
                            <div className='px-4 mt-8 rounded-xl w-[80vw] md:w-[25vw]'>
                                <img onClick={() => navigate('/discover/webinar-details', { state: { webinarId: webinar._id } })} src={webinar.WebinerPic} alt="" className="object-contain w-full h-full rounded-xl aspect-square" />
                                <div className='px-4 text-[10px] font-light text-white flex items-center justify-between mt-4'>
                                    <div className='flex'> POPULAR . 4.5 <div className='flex items-center justify-center'> <img src="/path/to/star.png" alt="" className="inline object-center w-2 h-2 mx-1" /></div> (145)</div>
                                    <div>TOTAL ATTENDEE'S . {webinarData.registered}</div>
                                </div>
                            </div>
                            <div className='px-4 mt-2 text-lg font-semibold text-center text-white font-space-grotesk'>
                                {webinar.WebinerName}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
};

export default Webinar;
