import { useEffect } from "react";
import blog_profile from '../../assets/blog/blog_user.png';
import blog_linked from '../../assets/blog-linkedin.png';
import ssc_chsl_exam from '../../assets/blog/SSC exam Banner (1).png';
import ssc_chsl_pattern from '../../assets/blog/SSC Exam Body 1 (1).jpg';
import { useNavigate } from "react-router-dom";
import internship_acadspace from '../../assets/blog/internship_acadspace.png';
import TimeManagement12thexam from '../../assets/blog/Time-management Banner Surbi (1).jpg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import neet_exam_banner from '../../assets/blog/Neet for Healthcare Students Banner (1).jpeg';

const SSCCGLBlog = () => {

    useEffect(() => {
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // className: "center",
        // centerMode: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const navigate = useNavigate();

    return (
        <div className='bg-gradient-to-b from-[#1D1050] to-[#1D1050] via-[#1D1050] space-y-8 md:space-y-16 transform-gpu max-w-full overflow-clip '>

            <div className='flex-col items-center justify-center py-4 text-center md:py-8 sm:mx-auto'>

                <div className="w-full bg-gradient-to-r from-[#FFFFFF]/5 to-[#2D1E6B]/10 py-4 mb-8 md:py-8 md:mb-16 text-xl md:text-2xl sm:text-4xl font-bold leading-snug font-goldman text-[#FAFF01]"> What is the SSC CGL Exam? Everything You Need to Know </div>

                <div className="flex flex-col md:flex-row md:px-16 gap-x-16">

                    <div className="flex-col px-8 space-y-8 text-center md:space-y-12 md:px-0">

                        <div className="flex items-center justify-center w-full">
                            <div className="relative flex items-center justify-center w-full rounded-xl">
                                <div className="relative z-10 w-full border-2 border-white rounded-xl overflow-clip">
                                    <img src={ssc_chsl_exam}
                                        alt="" className="w-full" />
                                    <div className="absolute "></div>
                                </div>
                                <div className="absolute w-full h-full border-2 rounded-xl top-2 -right-2 md:-top-3 md:-right-3"></div>
                                <div className="absolute bottom-0 z-20 w-full overflow-clip">
                                    <div className="w-full h-full bg-[#000]/50 border-b-2 border-l-2 space-y-1 border-r-2 rounded-b-xl backdrop-blur-md overflow-clip p-3">
                                        <div className="bg-[#F1ECFF] w-fit flex items-center jus text-[#6C40FF] text-sm rounded-xl py-1 px-2 font-space-grotesk">
                                            <div className="w-3 h-3 bg-[#6C40FF] rounded-full text-xs mr-1"></div>Competitive Exams
                                        </div>
                                        <div className="mt-1 text-xs font-semibold text-left text-white md:text-base font-space-grotesk">What is the SSC CGL Exam? Everything You Need to Know
                                        </div>
                                        <div className="text-sm text-left text-white font-space-grotesk">Aug 5 . 10 min read</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Introduction
                            </div>
                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                The SSC CGL (Staff Selection Commission Combined Higher Secondary Level) exam is a crucial opportunity for those looking to secure government jobs in India. It recruits candidates for various government positions such as Lower Division Clerk (LDC), Junior Secretariat Assistant (JSA), and Data Entry Operator (DEO). Learn more about the eligibility criteria, exam pattern, and preparation tips to excel in the SSC CGL exam and kickstart your government career.
                            </p>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Background
                            </div>
                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                The Combined Higher Secondary Level (CGL) exam, conducted by the Staff Selection Commission (SSC), is one of India's most sought-after competitive exams. It aims to recruit candidates for various posts in government ministries, departments, and offices. These positions include Lower Division Clerk (LDC), Junior Secretariat Assistant (JSA), Data Entry Operator (DEO), and DEO Grade A.
                            </p>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Understanding the Exam Pattern
                            </div>

                            <p className="mt-8 mb-4 text-base font-light md:text-lg md:leading-8">
                                <span className="text-[#E4E84E]">Tier I: </span> <br />
                                This is a computer-based test (CBT) consisting of objective-type questions. The sections include General Intelligence, General Awareness, Quantitative Aptitude, and English Language. It is qualifying in nature. However, a cut-off mark will be set based on candidates' performance. The Data Entry Operator (DEO) has a separate cut-off mark, while the Lower Division Clerk (LDC) and Junior Secretariat Assistant (JSA) have the same cut-off mark. Based on this criteria, candidates are qualified for separate posts to appear in Tier II of the examination. Each section consists of 25 questions, each worth 2 marks, for a total of 200 marks. For each wrong answer, 0.50 marks will be deducted. No marks will be awarded for any unattempted question. The total time for the exam is 1 hour.
                            </p>

                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                <span className="text-[#E4E84E]">Tier II: </span> <br />
                                This is divided into two halves. The first is a computer-based test (CBT) (2 Hours and 15 Minutes) that consists of objective-type questions. The sections include General Intelligence, General Awareness, Quantitative Aptitude, English Language, and Computer Proficiency. The second is a DEST (Data Entry Skill Test) (15 Minutes). This round determines the candidate's selection. The first half involves the computer-based test (CBT). It is divided into three sections - Section 1 (180 Marks) (1 hour) includes Mathematical Ability (30 Questions) and Reasoning (30 Questions). Section 2 (210 Marks) (1 hour) includes English Language & Comprehension (45 Questions) and General Awareness (25 Questions). Section 3 (60 Marks) (15 minutes) includes Computer Proficiency (20 Questions). After the allotted time passes, the section will change automatically. Candidates must finish their answers in the allotted time of the respective sections. Each question carries a value of 3 marks, and for each wrong answer, 1 mark will be deducted. No marks will be awarded for any unattempted question. Sections 1 and 2 determine the final cut-off mark, while Section 3 qualifies in nature, and a cut-off is set for it as well, but it is not included in the final cut-off. In the second half, candidates are required to take a DEST (Data Entry Skill Test). Based on their performance in Tier 1, they will be asked to complete a Typing test in the allotted 15 minutes with key depressions ranging from 2000 to 2500. Candidates must pass the test with the required accuracy of the exam.
                            </p>

                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                <span className="text-[#E4E84E]">Document Verification: </span> <br />
                                After Tier II is completed and the final selection list is published, candidates will be asked to verify their documents at their respective SSC Headquarters.
                            </p>
                        </div>

                        <div className="">
                            <img src={ssc_chsl_pattern} alt="" className="w-full" />
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Preparation Techniques
                            </div>

                            <p className="mt-8 mb-4 text-base font-light md:text-lg md:leading-8">
                                <span className="text-[#E4E84E]">Time Management: </span> <br />
                                Allocate specific time slots for each section, focusing on weaker areas without neglecting stronger ones.
                            </p>

                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                <span className="text-[#E4E84E]">Regular Practice: </span> <br />
                                Practice previous years’ papers and take mock tests to understand the exam pattern and improve speed and accuracy.
                            </p>

                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                <span className="text-[#E4E84E]">Stay Updated: </span> <br />
                                Keep yourself updated with current affairs and general knowledge, especially topics relevant to the exam syllabus.
                            </p>
                        </div>

                        <div className="text-base text-justify text-[#FFFCFC] space-y-4 font-space-grotesk text-[10px] md:text-xl">
                            <div className="text-xl font-semibold text-left text-white md:text-2xl">
                                Conclusion
                            </div>
                            <p className="mb-4 text-base font-light md:text-lg md:leading-8">
                                The SSC CGL exam is a gateway to secure a prestigious government job in India. With thorough preparation, understanding the exam pattern, and consistent practice, you can excel in the exam and achieve your career goals. Good luck!
                            </p>
                        </div>
                    </div>

                    <div className="w-full max-w-md px-8 mt-8 shrink-0">
                        <div className="w-full bg-[#2D1E6B] p-4 md:p-8 rounded-xl border-white border-[1px]">
                            <div className="space-y-2">
                                <div className="flex items-end justify-start overflow-clip gap-x-4">
                                    <img src={blog_profile} alt="" className="w-16 rounded-lg md:w-32" />
                                    <img src={blog_linked} alt="" className="w-6 h-6 rounded-lg md:h-8 md:w-8" />
                                </div>
                                <div className="text-base font-semibold text-left text-white md:text-xl font-space-grotesk"> Saubhagya Kulavi, B.A </div>
                                <div className="w-full h-[1px] bg-white"></div>
                                <div className="text-sm text-left text-white font-space-grotesk">
                                    Saubhagya Kulavi is a dedicated content writer with a Bachelor's in Arts. Passionate about delivering precise, engaging information, Saubhagya specializes in creating educational content that helps readers navigate complex topics.
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className="flex-col w-full">

                <div className="text-3xl md:text-4xl text-[#FAFF01] mb-4 w-full font-semibold font-goldman text-center">
                    Recommended Blogs
                </div>

                <div className='relative flex items-center justify-center px-8 space-x-12 overflow-scroll scrollbar-hide h-[500px]'>

                    <div className="absolute top-0 w-[470px] md:w-[1340px] h-full">

                        <Slider {...settings} className="px-4">

                            <div key={1} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 rounded-xl w-80 h-96'>
                                <img src={'https://acadspace.org/img/updates/MyCoolQ.png'} alt="blog" className="object-contain object-center rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    First Large Scale Partnership in East India
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    AcadSpace inaugurates Personality Coaching & Career Counseling for 1600+ proud parents. We are motivated by such a positive turn-up & feedback…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/partner-acadspace')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={2} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 rounded-xl w-80 h-96'>
                                <img src={internship_acadspace} alt="blog" className="object-contain object-center rounded-xl" />
                                <div className='font-bold text-left text-white '>
                                    <div className="bg-[#F1ECFF] w-fit flex items-center justify-center text-[#6C40FF] text-sm font-normal rounded-xl py-1 px-2 mt-2 font-space-grotesk">
                                        <div className="w-3 h-3 bg-[#6C40FF] rounded-full font-light text-[8px] mr-1"></div>Internships
                                    </div>
                                    <div className="flex items-center justify-between mt-2">
                                        <div className="flex font-medium text-[#97989F] text-base">
                                            <img src={blog_profile} alt="blog" className="w-4 mr-1 rounded-lg md:w-6" /> Manish Shivangi
                                        </div>
                                        <div className="flex font-medium text-[#97989F] text-sm">
                                            July 31st 2024
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        Unlocking Career Success: The Crucial Role of Internships in Career Development
                                    </div>
                                </div>
                                <div className='flex items-center justify-end w-full -mt-2 cursor-pointer' onClick={() => navigate('/blog/internship')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={3} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                                <img src={ssc_chsl_exam} alt="blog" className="object-contain object-center rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    What is the SSC CGL Exam? Everything You Need to Know
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    The SSC CGL (Staff Selection Commission Combined Higher Secondary Level) exam is a crucial opportunity for those looking to secure government jobs…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/ssc-cgl')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={4} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                                <img src={TimeManagement12thexam} alt="blog" className="object-cover object-center min-h-48 rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    How To Manage Time During Class 12th Exams?
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    Learn how to do time management for Class 12th exams with tips on creating a study plan, balancing study and rest, and reducing stress…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/12th')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                            <div key={5} className='bg-[#372972] shadow-blogs mx-16 p-3 my-16 shrink-0 cursor-pointer rounded-xl w-80 h-96'>
                                <img src={neet_exam_banner} alt="blog" className="object-cover object-center min-h-48 rounded-xl" />
                                <div className='mt-4 font-bold text-left text-white'>
                                    NEET: Medical Exam for Students
                                </div>
                                <div className='text-left text-[#ACACAC] text-xs mt-2'>
                                    The NEET (National Eligibility cum Entrance Test) is an Indian nationwide entrance examination conducted by the National Testing Agency (NTA) for admission to undergraduate medical programs…
                                </div>
                                <div className='flex items-center justify-end w-full mt-2 cursor-pointer' onClick={() => navigate('/blog/neet')}>
                                    <div className='text-sm font-semibold font-space-mono text-[#F4F80E] cursor-pointer mt-2 mr-2'>READ MORE</div>
                                </div>
                            </div>

                        </Slider>

                    </div>

                </div>


            </div>

        </div>
    )
}

export default SSCCGLBlog;
